import React, { useState, useRef, useEffect } from 'react';
import { useAppContext } from '../contexts/AppContext';
import { odv, log, weekDays, calculateDayTotalHours, calculateDayTotalHoursActual, timeToFloat, floatToTime, getRandomGoodLookingColor, findPeopleWithSameName } from '../utils/Utils';
import { getDayAdditionalHourLength, getEffectiveWorkHour } from '../utils/DataCalculator';
import RoundedDiv from '../assets/assets/rounded div/RoundedDiv';
import { SynchronizedBarCharts } from '../charts/Charts';
import { generateIntervals, generateChartData } from '../charts/StaffChart';
import PersonBarStyles from '../styles/PersonBar.module.css'
import GhostBarStyles from '../statistics//MeetingAndAHAndWH.module.css'
import DaySelector from './DaySelector';
import { truncate } from 'lodash';
import styles from './RightIsland.module.css'

export const IncrementButton = ({isDec, dispatch}) => {
  return (
    <button className={styles.incButton}
    onClick={() => dispatch({
        type: 'SELECT_NEXT_WEEK',
        payload: { backwards: isDec},
        meta: { localOnly: true }
      })}>
      <svg width="20px" height="20px" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: `${isDec ? '' : 'scaleX(-1)'}` }}>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L9.41421 12L15.7071 18.2929C16.0976 18.6834 16.0976 19.3166 15.7071 19.7071C15.3166 20.0976 14.6834 20.0976 14.2929 19.7071L7.29289 12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536 11.4804 7.29289 11.2929L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289Z" fill="#000000"/>
      </svg>
    </button>
  )
}
const RightIsland = () => {
  const { state, dispatch } = useAppContext();
  const { people, weeks, days, departments, selectedPerson, selectedWeekId, selectedWeekNr, workHours, meetings, additionalHours,
          selectedDepartment, selectedDay, breakHours
   } = state
  const relevantDays = odv(days).filter(day => day.weekId === selectedWeekId)
  const [hoveredPersonId, setHoveredPersonId] = useState(null);

  const [newChartData, setNewChartData] = useState([])
  const intervals = generateIntervals();

  useEffect(() => {
    let newChartData = [];

    Object.values(state.departments).forEach(dept => {
      if (dept.id === selectedDepartment){
        newChartData.push({title: dept.name, color: dept.color, chartData: generateChartData(state, intervals, selectedDay, dept).personalIBarngruppData})
      }
    })

    setNewChartData(newChartData);

  }, [workHours, selectedWeekId, breakHours, meetings, additionalHours, selectedDay, departments]);


  let dayWorkSum = 0

  let allRelevantDays = []
  let allRelevantAH = []
  let relevantAH = []

  relevantDays.forEach(day => {
    dayWorkSum += calculateDayTotalHours(state, day.id)
    relevantAH.push(...odv(additionalHours).filter(aH => aH.dayId === day.id))
  })

  const relevantWeeks = odv(weeks).filter(week => week.personId === selectedPerson) // Take all the weeks that belong to this person

  relevantWeeks.forEach(week => {
    allRelevantDays.push(...odv(days).filter(day => day.weekId === week.id)) // Add all the days in these weeks

  })

  const dayIds = odv(allRelevantDays).map(day => day.id)

  odv(additionalHours).forEach(aH => {
    // if (Object.keys(allRelevantDays).map(Number).includes(aH.dayId)) {
    if (dayIds.includes(aH.dayId)) {
      allRelevantAH.push(aH)
    }
  })

  let totalCalendarWorkSum = 0
  let totalCalendarAHSum = 0
  let weekAHSum = 0

  relevantAH.forEach(aH => {
    weekAHSum += timeToFloat(aH.length)
  })

  allRelevantDays.forEach(day => {
    totalCalendarWorkSum += calculateDayTotalHours(state, day.id)
  })

  allRelevantAH.forEach(aH => {
    totalCalendarAHSum += timeToFloat(aH.length)
  })

  const allRelevantMeetings = Object.values(meetings).filter(meeting => 
      meeting.peopleIds.includes(selectedPerson)
    )
    
  let weekMeetingSum = 0
  let calendarMeetingSum = 0

  allRelevantMeetings.forEach(meeting => {
    if (meeting.weekNr === selectedWeekNr) {
      weekMeetingSum += timeToFloat(meeting.length)
      calendarMeetingSum += timeToFloat(meeting.length)
    } else {
      calendarMeetingSum += timeToFloat(meeting.length)
    }
  })

  const handleMouseEnter = (personId) => {
    setHoveredPersonId(personId);
  };

  const handleMouseLeave = () => {
    setHoveredPersonId(null);
  };

  const handleDayChange = (day) => {
    dispatch({
      type: 'SELECT_DAY',
      payload: { day: day},
      meta: { localOnly: true }
    });
    // setSelectedDay(day);
  };

  const selectDepartment = (departmentId) => {
    dispatch({
      type: 'SELECT_DEPARTMENT',
      payload: { departmentId: departmentId},
      meta: { localOnly: true }
    });
  };

  const selectPerson = (personId) => {
    if (selectedPerson != personId) {
      dispatch({
        type: 'SELECT_PERSON',
        payload: { personId: personId},
        meta: { localOnly: true }
      });
    }
    
  }

  const width = 700
  const timeStart = 6
  const timeEnd = 18
  const timeLength = timeEnd - timeStart
  const pixelPerHour = width / timeLength

  // const day = odv(days).find(day => day.weekId === selectedWeekId && day.weekDay === selectedDay)
  
  const PersonBar = ({start, end, name, personId, isGhostBar = false}) => { // Start, end is for instance 10.5 and 15.45
    
    let offset = (start - timeStart) * pixelPerHour

    let calcStart = start
    let calcEnd = end

    if (calcStart < 6) {
      calcStart = 6
    }

    if (calcEnd < 6 ) {
      calcEnd = 9
    }

    const width = (calcEnd - calcStart) * pixelPerHour

    const hovered = hoveredPersonId === personId

    if (offset < 0) {
      offset = 0
    }
  
    const selected = selectedPerson === personId
    return <div
    className={isGhostBar === true ? PersonBarStyles.personGhostBar : PersonBarStyles.personBar}
    style={{
      '--offset': `${offset}px`,
      '--width': `${width}px`,
    }}
    // onMouseEnter={() => handleMouseEnter(personId)}
    // onMouseLeave={() => handleMouseLeave(personId)}
    onClick={() => {selectPerson(personId);
      if(isGhostBar) {dispatch({
        type: 'SELECT_DEPARTMENT',
        payload: { departmentId: people[personId].departmentId},
        meta: { localOnly: true }
      })}
    }} >
      <span style={{marginLeft:'4px', color: `${isGhostBar ? '#777' : '#000'}`}}>{floatToTime(start)}</span>
      <span style={{fontWeight: `${selected ? '500' : '500'}`, color: `${isGhostBar ? '#777' : '#000'}`, display: 'inline-block', textAlign:'center'}}>{name} {isGhostBar ? `(${departments[people[personId].departmentId].name})` : ''}</span>
      <span style={{marginRight:'4px', color: `${isGhostBar ? '#777' : '#000'}`}}>{floatToTime(end)}</span>
      {/* <input onChange={() => log("yo")} style={{backgroundColor:'#fff0', border:'0', fontSize:'18px'}} value={floatToTime(start)}></input>  */}
      {/* {name} */}
      </div>;
  };

  const BreakBar = ({start, end, name, personId}) => { // Start, end is for instance 10.5 and 15.45
    const [isOpen, setIsOpen] = useState(false);
    const showMenu = () => setIsOpen(true);
    const hideMenu = () => setIsOpen(false);


    let offset = (start - timeStart) * pixelPerHour
    const width = (end - start) * pixelPerHour

    if (offset < 0) {
      offset = 0
    }
    const divStyle = {
      backgroundColor: '#EECFE8',
      height:  '30px',
      width: `${width}px`,
      // display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '6px',
      marginLeft: `${offset}px`,
      marginTop: '3px',
      position:'absolute',
      color:'black',
      zIndex:'100',
    };
  
    return <div 
    className={PersonBarStyles.breakBar}
    style={{
      '--offset': `${offset}px`,
      '--width': `${width}px`,
    }}
    onClick={() => selectPerson(personId)}
    onMouseEnter={showMenu} onMouseLeave={hideMenu}>
      {/* <span style={{marginLeft:'4px'}}>{floatToTime(start)}</span> */}

        <div style={{height:'28px', display:'flex', justifyContent:'center', alignItems:'center',}}>
          <span onMouseEnter={showMenu} style={{fontSize:'13px', fontWeight:'500'}}>Rast</span>
        </div>
        {/* <span style={{marginRight:'4px'}}>{floatToTime(end)}</span> */}
        {/* {name} */}
        <div>
        {
          isOpen && <div onMouseEnter={showMenu} onMouseLeave={hideMenu}
              style={{
                backgroundImage: 'linear-gradient(to left, #eadbeb, #ebd9eb, #ebd7ea, #ecd4ea, #edd2e9, #edd0e8, #edcde8, #edcbe7, #ecc8e6, #ecc5e5, #ebc1e3, #ebbee2)',
                width:'fit-content', borderRadius:'0 12px 12px 12px', padding:'5px', zIndex:'1000', position:'absolute', top:'30px', left: '0px'}}>
            <p>{name}</p>
            <p style={{whiteSpace: 'nowrap',}}>{floatToTime(start)} - {floatToTime(end)}</p>
            </div>
        }
        </div>
        
      </div>

  };

  const MeetingBar = ({start, end, name, objectId, isGhostBar = false}) => { // Start, end is for instance 10.5 and 15.45
    const [isOpen, setIsOpen] = useState(false);
    const showMenu = () => setIsOpen(true);
    const hideMenu = () => setIsOpen(false);

    let offset = (start - timeStart) * pixelPerHour
    const width = (end - start) * pixelPerHour

    if (offset < 0) {
      offset = 0
    }
    const divStyle = {
      backgroundColor: '#F8CBB0',
      height:  '30px',
      width: `${width}px`,
      // display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '0 12px 12px 12px',
      marginLeft: `${offset}px`,
      marginTop: '3px',
      position:'absolute',
      color:'black',
      zIndex:'100',
      // backgroundImage: 'linear-gradient(to right top, #d7a1da, #d5aedf, #d4bbe2, #d5c7e3, #d8d2e3, #dfd5e4, #e6d9e6, #ecdde7, #fcd9df, #ffd8cd, #ffddb8, #eee6ac)'
      // backgroundImage: 'linear-gradient(to right, #ebd1ec, #f6cce0, #fdc9d2, #fec9c2, #f9cab3, #f7cdad, #f4d1a8, #eed5a5, #f1dba6, #f3e1a6, #f5e7a8, #f6eda9)'
      // backgroundImage: 'linear-gradient(to right, #ebd1ec, #f6cce0, #fdc9d2, #fec9c2, #f9cab3, #f7cdae, #f3d0a9, #edd3a6, #f0d7a7, #f2daa7, #f4dea8, #f6e2a9)'
      // backgroundColor: getRandomGoodLookingColor(seed),
    };
  
    return <div 
    className={PersonBarStyles.meetingBar}
    style={{
      '--offset': `${offset}px`,
      '--width': `${width}px`,
    }}
    // style={divStyle}
     onMouseEnter={showMenu} 
     onMouseLeave={hideMenu}
     onClick={() => {
      dispatch({ type: 'SET_PRESSED_DAY_BUTTON', payload: { newValue: selectedDay } });
      dispatch({
        type: 'SELECT_BUTTON',
        payload: { button: 'Möten'}
      });
      dispatch({ type: 'TOGGLE_SHOW_MEETINGAH_POPUP', payload: { setToShow: true, objectId: objectId} });
     }}>
      {/* <span style={{marginLeft:'4px'}}>{floatToTime(start)}</span> */}
      <div style={{height:'28px', display:'flex', alignItems:'center', justifyContent:'center', position:'absolute'}}>
          <span onMouseEnter={showMenu} style={{fontSize:'13px', fontWeight:'500'}}>Möte</span>
      </div>
      {
        isOpen && <div onMouseEnter={showMenu} onMouseLeave={hideMenu}
            style={{
              // backgroundColor: '#F8CBB0', 
              backgroundImage: 'linear-gradient(to right, #fbbf9b, #fbc19f, #fac4a3, #fac6a7, #f9c8ab, #f9caae, #f9ccb2, #f9ceb5, #fad1b8, #fad3bc, #fbd6bf, #fbd8c3)',
              width:'fit-content', borderRadius:'0 12px 12px 12px', padding:'5px', zIndex:'1000', position:'absolute', top:'30px', left: '0px'}}>
          <p>{name}</p>
          <p style={{whiteSpace: 'nowrap',}}>{floatToTime(start)} - {floatToTime(end)}</p>
          </div>
      }
      </div>;
  };

  const AHBar = ({start, end, category, name, objectId}) => { // Start, end is for instance 10.5 and 15.45
    const [isOpen, setIsOpen] = useState(false);
    const showMenu = () => setIsOpen(true);
    const hideMenu = () => setIsOpen(false);

    let offset = (start - timeStart) * pixelPerHour
    const width = (end - start) * pixelPerHour

    if (offset < 0) {
      offset = 0
    }
    const divStyle = {
      backgroundColor: '#F3DDA7',
      height:  '30px',
      width: `${width}px`,
      // display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '6px',
      marginLeft: `${offset}px`,
      marginTop: '3px',
      position:'absolute',
      color:'black',
      // backgroundImage: 'linear-gradient(to right top, #d7a1da, #d5aedf, #d4bbe2, #d5c7e3, #d8d2e3, #dfd5e4, #e6d9e6, #ecdde7, #fcd9df, #ffd8cd, #ffddb8, #eee6ac)'
      // backgroundImage: 'linear-gradient(to right, #ebd1ec, #f6cce0, #fdc9d2, #fec9c2, #f9cab3, #f7cdad, #f4d1a8, #eed5a5, #f1dba6, #f3e1a6, #f5e7a8, #f6eda9)'
      // backgroundImage: 'linear-gradient(to right, #ebd1ec, #f6cce0, #fdc9d2, #fec9c2, #f9cab3, #f7cdae, #f3d0a9, #edd3a6, #f0d7a7, #f2daa7, #f4dea8, #f6e2a9)'
      // backgroundColor: getRandomGoodLookingColor(seed),
    };
  
    return <div 
    className={PersonBarStyles.aHBar}
    style={{
      '--offset': `${offset}px`,
      '--width': `${width}px`,
    }}
    // style={divStyle}
    onMouseEnter={showMenu} 
    onMouseLeave={hideMenu}
    onClick={() => {
      dispatch({ type: 'SET_PRESSED_DAY_BUTTON', payload: { newValue: selectedDay } });
      dispatch({
        type: 'SELECT_BUTTON',
        payload: { button: 'Barnfri tid'}
      });
      dispatch({ type: 'TOGGLE_SHOW_MEETINGAH_POPUP', payload: { setToShow: true, objectId: objectId} });
    }}>

      {/* <span style={{marginLeft:'4px'}}>{floatToTime(start)}</span> */}
      <div style={{height:'28px', display:'flex', alignItems:'center', justifyContent:'center', position:'absolute'}}>
          <span onMouseEnter={showMenu} style={{fontSize:'13px', fontWeight:'500'}}>{category}</span>
      </div>
      <div style={{zIndex:'200'}}>
        {
          isOpen && <div onMouseEnter={showMenu} onMouseLeave={hideMenu}
              style={{
                backgroundImage: 'linear-gradient(to right, #f4d78f, #f4d894, #f3da98, #f3db9d, #f3dca1, #f3dda5, #f3dea8, #f3dfac, #f3e0b0, #f4e2b5, #f4e3b9, #f4e4bd)',
                
                width:'fit-content', borderRadius:'0 12px 12px 12px', padding:'5px', zIndex:'1000', position:'absolute', top:'30px', left: '0px',}}>
            <p>{name}</p>
            <p style={{whiteSpace: 'nowrap',}}>{floatToTime(start)} - {floatToTime(end)}</p>
            </div>
        }
      </div>
      {/* <span style={{marginRight:'4px'}}>{floatToTime(end)}</span> */}
      {/* {name} */}
      </div>;
  };

  
  
  return (
    <RoundedDiv backgroundColor='#fff' layer={'one'} padding={'0px'}>
      <div style={{ maxWidth:'1250px'}} >
        <div style={{display:'flex'}}>
          <div style={{marginLeft:'60px', marginTop:'20px'}}>
            <div style={{display:'flex' }}>
              <div style={{display:'flex', flexDirection:'column', minWidth:'200px'}}>
                <p style={{color:'rgb(53, 109, 255)', fontSize:'30px', fontWeight:'500', minWidth:'150px'}}>{selectedDay}</p>
                {/* <p style={{ fontSize:'20px', marginBottom:'10px'}}>{departments[selectedDepartment].name} <IncrementButton isDec={true} dispatch={dispatch}/> v. {selectedWeekNr} <IncrementButton isDec={false} dispatch={dispatch}/></p> */}
                <div style={{ display: 'flex', alignItems: 'center', fontSize: '20px', marginLeft: '3px', whiteSpace: 'nowrap' }}>
                {departments[selectedDepartment]?.name} <IncrementButton isDec={true} dispatch={dispatch}/><span style={{width:'35px'}}> v. {selectedWeekNr}</span><IncrementButton isDec={false} dispatch={dispatch} />
                </div>
              </div>
              <div style={{marginLeft:'00px'}}>
                <DaySelector></DaySelector>
              </div>
              <button
                style={{marginLeft:'20px', height:'40px', width:'100px'}} 
                onClick={() => dispatch({
                  type: 'TOGGLE_SHOW_STATISTICS',
                  payload: { setToShow: true},
                  meta: { localOnly: true }
                })}>
                  <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                    <BarGraphSVG></BarGraphSVG>
                    <p>Statistik</p>
                  </div>
                  </button>
            </div>
            <div style={{width:'800px', marginTop:'10px'}}>
              <div style={{color:'#888', width:`${width}px`, borderBottom:'2px solid #ccc', display:'flex', justifyContent:'space-between'}}>
                <span>6</span>
                <span>8</span>
                <span>10</span>
                <span>12</span>
                <span>14</span>
                <span>16</span>
                <span>18</span>
              </div>
              {
                odv(people).filter(person => person.departmentId === selectedDepartment).map(person => {
                  const day = odv(days).find(day => weeks[day.weekId].personId === person.id && day.weekDay === selectedDay && weeks[day.weekId].weekNr === selectedWeekNr)
                    // const workHour = odv(workHours).find(wH => weeks[days[wH.dayId].weekId]?.personId === person.id && wH.type === 'start' && days[wH.dayId].weekDay === day.weekDay && weeks[days[wH.dayId].weekId].weekNr === state.selectedWeekNr)

                  if (!day){
                    log("_---__")
                    log(person.id, selectedDay, selectedWeekNr, days)
                    // const day1 = odv(days).find(day => day.weekDay === selectedDay && weeks[day.weekId].weekNr === selectedWeekNr)
                    // const day2 = odv(days).find(day => weeks[day.weekId].personId === person.id && weeks[day.weekId].weekNr === selectedWeekNr)
                    // const day3 = odv(days).find(day => weeks[day.weekId].personId === person.id && day.weekDay === selectedDay )

                    // log ("day1 2 and 3: ", day1, day2, day3)
                  }

                  if (day) { // This is a failsafe that should not be needed, but I couldnt find the source of the bug that pOday sometimes is undefined
                    // Fix this sometime, or atleast remove failsafe to naturally try to get the error again
                    // The bug is cause some people dont have days and weeks for all weeks for some reason. no idea why, usually happens on asdf people?

                    const workHour = odv(workHours).find(wH => wH.dayId === day.id && wH.type === 'start')
                    const workHourStart = workHour?.time 
                    // const workHourEnd = odv(workHours).find(wH => weeks[days[wH.dayId].weekId]?.personId === person.id && wH.type === 'end' && days[wH.dayId].weekDay === day.weekDay && weeks[days[wH.dayId].weekId].weekNr === state.selectedWeekNr)?.time
                    const workHourEnd = odv(workHours).find(wH => wH.dayId === day.id && wH.type === 'end')?.time


                    const peopleObjectsWithSameName = findPeopleWithSameName(state, person)

                    let somethingShowed = false // Used to check if there has been any bar or ghostbar displayed, otherwise show placeholder
                    
                    const returnObject = (
                      <div style={{display:'flex'}}>
                        <div style={{width:'700px', height:'36px'}}>
                          <div style={{width:'700px', height:'36px', position:'absolute', display:'flex', alignItems:'center'}}>
                            {
                              odv(peopleObjectsWithSameName).map(pO => {
                                const pOday = odv(days).find(day => weeks[day.weekId].personId === pO.id && day.weekDay === selectedDay && weeks[day.weekId].weekNr === selectedWeekNr)

                                if (pOday) { // This is a failsafe that should not be needed, but I couldnt find the source of the bug that pOday sometimes is undefined
                                  // Fix this sometime, or atleast remove failsafe to naturally try to get the error again
                                  const pOworkHourStart = odv(workHours).find(wH => wH.dayId === pOday.id && wH.type === 'start')?.time 
                                  const pOworkHourEnd = odv(workHours).find(wH => wH.dayId === pOday.id && wH.type === 'end')?.time

                                  if (pOworkHourStart && pOworkHourEnd) {
                                    somethingShowed = true
                                    if (pO.id === person.id) {
                                      return (
                                        <PersonBar start={timeToFloat(pOworkHourStart)} end={timeToFloat(pOworkHourEnd)} name={person.name} personId={person.id}></PersonBar>
                                      )
                                    } else {
                                      return (
                                        <PersonBar start={timeToFloat(pOworkHourStart)} end={timeToFloat(pOworkHourEnd)} name={pO.name} personId={pO.id} isGhostBar={true}></PersonBar>
                                      )
                                    }
                                  }
                                } else {
                                  log("XXXXXX pOday is undefined, this should never be the case XXXXXXX")
                                }
                                

                              })
                            }
                          </div>
                        </div>
                        <div style={{display:'flex', alignItems:'center', marginLeft:'10px'}}>
                          <p style={{fontWeight:'500', color: `${selectedPerson === person.id ? '#000' : '#000'}`}}>= {calculateDayTotalHoursActual(state, day.id)}h</p>
                        </div>
                      </div>
                    )

                    if (somethingShowed) {
                      return returnObject
                    } else {
                      return (
                        <div style={{display:'flex'}}>
                          <div style={{width:'700px', height:'36px'}}>
                            <div style={{width:'700px', height:'36px', display:'flex', justifyContent:'center', alignItems:'center'}}>
                              <span style={{ cursor:'pointer', color:'#818181'}} 
                              className={GhostBarStyles.emptyPersonBarThicc} onClick={() => selectPerson(person.id)}>{person.name}</span>
                            </div>
                          </div>
                          <div style={{display:'flex', alignItems:'center', marginLeft:'10px'}}>
                            <p style={{fontWeight:'500'}}>= {calculateDayTotalHoursActual(state, day.id)}h</p>
                          </div>
                        </div>
                      )
                    }
                  } else {
                    log("ZZZZZ pOday is undefined, this should never be the case ZZZZZZ")
                  }
                  
                })
              }
              <div style={{color:'#888', width:`${width}px`, marginTop:'15px', marginBottom:'10px', display:'flex', justifyContent:'space-between'}}>
                <span>6</span>
                <span>8</span>
                <span>10</span>
                <span>12</span>
                <span>14</span>
                <span>16</span>
                <span>18</span>
              </div>
              {
                odv(people).filter(person => person.departmentId === selectedDepartment).map(person => {

                  const peopleObjectsWithSameName = findPeopleWithSameName(state, person)
                  
                  // if (workHourStart && workHourEnd) {
                    return (
                      <div style={{display:'flex', height:'36px'}}>
                        <div style={{width:'700px', position:'relative'}}>
                          {/* <div style={{width:'700px',  }}> */}
                          {
                            odv(peopleObjectsWithSameName).map(pO => {

                              const day = odv(days).find(day => weeks[day.weekId].personId === pO.id && day.weekDay === selectedDay && weeks[day.weekId].weekNr === selectedWeekNr)

                              if (!day){
                                log("yYYYYYY")
                                log(pO.id, selectedDay, selectedWeekNr, "days: ", days, "weeks: ", weeks)
                              }

                              if (day){ // This is a failsafe that should not be needed, but I couldnt find the source of the bug that pOday sometimes is undefined
                                // Fix this sometime, or atleast remove failsafe to naturally try to get the error again
                                
                                // const workHour = odv(workHours).find(wH => weeks[days[wH.dayId].weekId]?.personId === person.id && wH.type === 'start' && days[wH.dayId].weekDay === day.weekDay && weeks[days[wH.dayId].weekId].weekNr === state.selectedWeekNr)
                                const workHour = odv(workHours).find(wH => wH.dayId === day.id && wH.type === 'start')
                                const workHourStart = workHour?.time 
                                // const workHourEnd = odv(workHours).find(wH => weeks[days[wH.dayId].weekId]?.personId === person.id && wH.type === 'end' && days[wH.dayId].weekDay === day.weekDay && weeks[days[wH.dayId].weekId].weekNr === state.selectedWeekNr)?.time
                                const workHourEnd = odv(workHours).find(wH => wH.dayId === day.id && wH.type === 'end')?.time
                                
                                const breakHourStart = odv(breakHours).find(bH => bH.dayId === day.id && bH.type === 'start')?.time || "00:00"
                                const breakHourLength = odv(breakHours).find(bH => bH.dayId === day.id && bH.type === 'length')?.time || "00:00"

                                const relevantMeetings = Object.values(meetings).filter(meeting => 
                                  meeting.weekNr === weeks[day.weekId].weekNr &&
                                  meeting.weekDay === day.weekDay
                                  && meeting.peopleIds.includes(pO.id))

                                const relevantAdditionalHours = Object.values(additionalHours).filter(aH => aH.dayId === day.id)

                                let isGhost = true
                                if (pO.id === person.id) {
                                  isGhost = false
                                }
                                return (
                                  <>
                                    {
                                      (timeToFloat(breakHourLength) !== 0) && <BreakBar personId={person.id} name={person.name} start={timeToFloat(breakHourStart)} end={timeToFloat(breakHourStart) + timeToFloat(breakHourLength)} isGhostBar={isGhost}></BreakBar>
                                    }
                                    {
                                      relevantMeetings.map(meeting => (
                                        <MeetingBar objectId={meeting.id} start={timeToFloat(meeting.time)} end={timeToFloat(meeting.time) + timeToFloat(meeting.length)} name={person.name} isGhostBar={isGhost}></MeetingBar>
                                      ))
                                    }
                                    {
                                      relevantAdditionalHours.map(aH => (
                                        <AHBar objectId={aH.id} start={timeToFloat(aH.start)} end={timeToFloat(aH.start) + timeToFloat(aH.length)} category={aH.category} name={person.name} isGhostBar={isGhost}></AHBar>
                                      ))
                                    }
                                  </>
                                )
                              } else {
                                log("YYYYYYYY pOday is undefined, this should never be the case YYYYYYYY")
                              }

                              
                            })
                          }
                          {/* </div> */}
                        </div>
                        <div style={{display:'flex', alignItems:'center', marginLeft:'10px'}}>
                          <p className={GhostBarStyles.emptyPersonBarThiccNarrow} onClick={() => selectPerson(person.id)} style={{fontWeight:'500', cursor:'pointer', color: `${selectedPerson === person.id ? '#000' : '#000'}`}}>{person.name}</p>
                        </div>
                      </div>
                    )
                  // }
                })
              }
              <div style={{color:'#888', width:`${width}px`, marginTop:'6px', borderTop:'2px solid #ccc', display:'flex', justifyContent:'space-between'}}>
                <span>6</span>
                <span>8</span>
                <span>10</span>
                <span>12</span>
                <span>14</span>
                <span>16</span>
                <span>18</span>
              </div>
              <p style={{marginTop:'40px'}}>OBS: endast den valda avdelningens tider visas</p>
              

            </div>
            </div>
            
          </div>

      </div>
      <div style={{display:'flex'}}>
        <div style={{marginBottom:'20px'}}>
          <SynchronizedBarCharts width={'760px'} chartsData={newChartData}></SynchronizedBarCharts>
        </div>
        <button onClick={() => dispatch({
            type: 'TOGGLE_SHOW_ALL_CHARTS',
            payload: { setToShow: true},
            meta: { localOnly: true }
          })} style={{height:'40px', marginLeft:'20px'}}>


          <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3 3V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21H21M19 15H7M18 7H7M15 11H7" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          </button>
      </div>
      
    </RoundedDiv>

  )
}

export default RightIsland


export const BarGraphSVG = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g id="ic-statistics-2">
      <line 
        x1="2" 
        y1="20" 
        x2="22" 
        y2="20" 
        style={{ fill: 'none', stroke: '#000000', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '1.5px' }} 
      />
      <path 
        d="M5,20V8.2A.2.2,0,0,1,5.2,8H7.8a.2.2,0,0,1,.2.2V20" 
        style={{ fill: 'none', stroke: '#000000', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '1.5px' }} 
      />
      <path 
        d="M11,20V4.27c0-.15.09-.27.2-.27h2.6c.11,0,.2.12.2.27V20" 
        style={{ fill: 'none', stroke: '#000000', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '1.5px' }} 
      />
      <path 
        d="M17,20V11.15c0-.08.09-.15.2-.15h2.6c.11,0,.2.07.2.15V20" 
        style={{ fill: 'none', stroke: '#000000', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '1.5px' }} 
      />
    </g>
  </svg>
);

