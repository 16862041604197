import React, { createContext, useContext, useMemo } from 'react';
import { usePersonContext } from './PersonContext';
import { useAppContext } from './AppContext';

const WeekContext = createContext();

export const WeekProvider = ({ weekId, children }) => {
  const {state, dispatch} = useAppContext();
  const contextValue = useMemo(() => ({ state, dispatch }), [state]);

//   const selectedPerson = state.selectedPerson;
//   const person = state.departments[state.selectedDepartment].people[selectedPerson];
//   const week = person?.weeks[weekId]; //TODO remake to new flattened structure

  return (
    <WeekContext.Provider value={ contextValue }>
      {children}
    </WeekContext.Provider>
  );
};

export const useWeekContext = () => {
  return useContext(WeekContext);
};
