import React, { useState, useRef, useEffect } from 'react';
import { useAppContext, initialStatee } from '../contexts/AppContext';
import inputStyles from '../styles/StyledInput.module.css';
import buttonStyles from '../styles/StyledButton.module.css';


const RemoveDataForm = () => {
  const { state, dispatch } = useAppContext();


  return (
    <div>
      <p style={{fontSize:'24px'}}>Är du säker på att du vill ta bort all data i systemet?</p>
      <p>Detta går inte att ångra</p>
      <br></br>
      <button 
        className={buttonStyles['button-30']}
        onClick={() => {dispatch({ type: 'SET_STATE', payload: {
          state: initialStatee
        } });
                              dispatch({
                                type: 'TOGGLE_SHOW_REMOVE_DATA', payload: {
                                setToShow: false,
                                }
                              })
                              }}>
        Ta bort
      </button>
    </div>
  )
}

export default RemoveDataForm