import React, { useState, useRef, useEffect } from 'react';
import { useAppContext } from '../contexts/AppContext';
import inputStyles from '../styles/StyledInput.module.css';
import buttonStyles from '../styles/StyledButton.module.css';
import WeekPicker from '../week/WeekPicker';
import daySelectorStyles from '../styles/Dayselector.module.css';
import styles from '../statistics/PersonStatistics.module.css';
import schoolStatisticsStyles from '../statistics/SchoolStatistics.module.css';
import meetingAHStyles from '../styles/MeetingAH.module.css';
import selectStyles from '../styles/ThreeWaySelect.module.css';

import { WeekProvider } from '../contexts/WeekContext';
import { odv, log, weekDays, calculateDayTotalHours, calculateDayTotalHoursActual, timeToFloat, odvsw, floatToTime } from '../utils/Utils';
import { getDayAdditionalHourLength, getEffectiveWorkHour } from '../utils/DataCalculator';
import RoundedDiv from '../assets/assets/rounded div/RoundedDiv';
import Modal from '../modal/Modal';
import ModalLight from '../modal/ModalLight';

import { getIndividualStatistics, getWeekTotalHours } from '../utils/SummationUtils';
import { CategoryViewer } from '../modal/CategoryViewer';


const IndividualStatisticsPublicize = ({selectedPersonId}) => {
  const { state, dispatch } = useAppContext();
  const { people, weeks, days, departments, selectedWeekId, selectedWeekNr, workHours, breakHours, meetings, additionalHours,
          selectedDepartment, selectedDay, selectedStatisticsButton, numberOfWeeks, selectedSchoolStatisticsButton } = state

  const [statistics, setStatistics] = useState({})
  const [categorySummations, setCategorySummations] = useState({}) // for all weeks, structured like workhours: 0

  // const [showEditCategories, setShowEditCategories] = useState(false)

  const weekNumbers = Array.from({ length: numberOfWeeks }, (_, i) => i + 1);
  const relevantWeeks = odv(weeks).filter(week => week.personId === selectedPersonId)
  const minWidthPx = '55px'

  // const addHours = (key, value) => {
  //   let tempSummations = categorySummations
  //   if (tempSummations[key]) {
  //     tempSummations[key] += value
  //   } else {
  //     tempSummations[key] = value
  //   }

  //   setCategorySummations(tempSummations)
  // }

  const addHours = (key, value) => {
    setCategorySummations(prevSummations => {
      const newSummations = { ...prevSummations };
      if (newSummations[key]) {
        newSummations[key] += value;
      } else {
        newSummations[key] = value;
      }
      return newSummations;
    });
  };

  useEffect(() => {
    setCategorySummations({})
    let newStatistics = {}
    // strucured like {0: const {workHoursSum, meetingHoursSum, AHdict, 1: const {workHoursSum, meetingHoursSum, AHdict ...}
    // int for the week nr

    relevantWeeks.forEach(week => {
      const {workHoursSum, meetingHoursSum, AHdict} = getIndividualStatistics(state, week)
      newStatistics[week.weekNr] = {workHoursSum, meetingHoursSum, AHdict}

      addHours('workHoursSum', workHoursSum)
      addHours('meetingHoursSum', meetingHoursSum)

      if (AHdict) {
        Object.entries(AHdict).forEach(([category, value]) => {
          addHours(category, value)
        })
      }
    })

    log("category summations is: ", categorySummations)
    setStatistics(newStatistics)

  }, [selectedPersonId, workHours, breakHours, additionalHours, meetings])

  const selectDepartment = (departmentId) => {
    dispatch({
      type: 'SELECT_DEPARTMENT',
      payload: { departmentId: departmentId},
      meta: { localOnly: true }
    });
  };

  const selectPerson = (personId) => {
    dispatch({
      type: 'SELECT_PERSON',
      payload: { personId: personId},
      meta: { localOnly: true }
    });
  }

  let aHCounter = 1

  function selectWeek(weekNr) {
    const weekId = odv(weeks).find(week => week.weekNr === weekNr && week.personId === selectedPersonId).id
    dispatch({
        type: 'SELECT_WEEK',
        payload: { weekNr: weekNr, weekId: weekId}
      });
  }

  const targetHours = people[selectedPersonId]?.targetHours || []

  const getTargetHour = (targetType) => {
    if (targetHours) {
      const target = targetHours.find(tH => tH[0] === targetType)

      if (target){
        const targetNr = target[1] // how many hours the target is for
        if (targetNr) {
          if (target[2] == 1) {
            return targetNr * numberOfWeeks
          } else if (target[2] == 2) {
            return targetNr
          }
        }
      }
    }
  }

  const getPercentageFilled = (targetType) => {
    const targetHour = getTargetHour(targetType)
    let sum = 0

    if (targetType === 'workHours'){
      sum = categorySummations.workHoursSum
    } else if (targetType === 'meetings'){
      sum = categorySummations.meetingHoursSum
    } else {
      sum = categorySummations[targetType]
    }

    if (sum && targetHour) {
      return `${(Math.round(  (100*(sum / (targetHour)))   * 10) / 10).toString() }%`
    } else return '-'
  }

  return (
    <div style={{}}>
      {/* <ModalLight isOpen={showEditCategories} onClose={() => setShowEditCategories(false)}>
        <CategoryViewer setShowEditCategories={setShowEditCategories}></CategoryViewer>
      </ModalLight> */}

       <div style={{display:'flex', overflow:'auto'}}>
          <div style={{}}>
            <p style={{fontWeight:'400', fontSize:'25px', marginLeft:'0px'}}>{people[selectedPersonId]?.name}</p>
            {/* <div style={{marginLeft: '100px',}}>
              <div style={{display:'flex'}}>
                <div>
                  <p>Total arbetstid: </p>
                </div>
                <div>
                  <p>{}</p>
                </div>
              </div>
              <div style={{display:'flex'}}>
                <div>
                  <p>Total mötestid: </p>
                </div>
              </div>
              <div style={{display:'flex'}}>
                <div>
                  <p>Total barnfri tid: </p>
                </div>
              </div>
            </div> */}

            {/* `calc(max(${100}px, ${8}%))` */}
            <div style={{display:'flex',  marginLeft: '104px', marginTop:'10px'}}>
               <div style={{display:'flex', width:'80px', justifyContent:'center'}}>
                  <p style={{}}>Summa</p>
                </div>

                {
                // width: `calc(max(${107}px, ${200}%))`,
                weekNumbers.map(weekNr => (
                    <div style={{ display:'flex', justifyContent:'center', width:minWidthPx,}}>
                    <p>v.{weekNr}</p>
                    </div>
                ))
                }
                {/* // borderLeft:'2px solid #aaa', */}
                {/* <div style={{ marginLeft:'2px', display:'flex', justifyContent:'center', width:'80px'}}>
                </div> */}
                {/* <div style={{ display:'flex', justifyContent:'center', width:'80px'}}
                title='Redigera mål'>
                <p>Mål</p>
                </div>
                <div style={{ display:'flex', justifyContent:'center', width:'80px'}}>
                <p>%uppf.</p>
                </div> */}
            </div>

            <div style={{}}>

              <div style={{border:'2px solid #f1f1f1'}}>
                <div className={schoolStatisticsStyles.personRow} style={{
                          '--isEven': '#f1f1f1',
                        }}>
                  <div style={{width:'100px', borderRight:'2px solid #aaa',}}>
                    <p>Arbetstid: </p>
                  </div>
                  <div style={{display:'flex'}}>
                    <div style={{borderRight:'2px solid #aaa', display:'flex', justifyContent:'center', width:'80px'}}>
                      <p>{categorySummations.workHoursSum ? `${categorySummations.workHoursSum}h`:'-'}</p>
                    </div>
                    {
                      // width: `calc(max(${107}px, ${200}%))`,
                      relevantWeeks.map(week => {

                        const workHoursSum = statistics[week.weekNr]?.workHoursSum || 0
                        const display = workHoursSum ? `${workHoursSum}h` : '-'

                        return (
                          <div style={{ display:'flex', justifyContent:'center', width:minWidthPx,}}>
                            <p>{display}</p>
                          </div>
                        )
                      })
                    }
                    
                    {/* <div style={{ display:'flex', justifyContent:'center', width:'80px'}}
                      className={styles.categoryName}
                      onClick={() => dispatch({ type: 'TOGGLE_SHOW_PERSON_SETTINGS', payload: {setToShow: true}, meta: { localOnly: true } })}
                      title='Redigera mål'>
                      <p>{getTargetHour('workHours') ? `${getTargetHour('workHours')}h`:'-'}</p>
                    </div>
                    <div style={{ display:'flex', justifyContent:'center', width:'80px'}}>
                      <p>{getPercentageFilled('workHours')}</p>
                    </div> */}
                  </div>
                </div>
                <div className={schoolStatisticsStyles.personRow} style={{
                          '--isEven': `${'#fff'}`,
                        }}>
                  <div style={{width:'100px', borderRight:'2px solid #aaa',}}>
                    <p>Möten: </p>
                  </div>
                  <div style={{display:'flex', }}>
                    <div style={{borderRight:'2px solid #aaa', display:'flex', justifyContent:'center', width:'80px'}}>
                      <p>{categorySummations.meetingHoursSum ? `${categorySummations.meetingHoursSum}h`:'-'}</p>
                    </div>
                    {
                      // width: `calc(max(${107}px, ${200}%))`,
                      relevantWeeks.map(week => {

                        const meetingHoursSum = statistics[week.weekNr]?.meetingHoursSum || 0
                        const display = meetingHoursSum ? `${meetingHoursSum}h` : '-'

                        return (
                          <div style={{ display:'flex', justifyContent:'center', minWidth:minWidthPx,}}>
                            <p>{display}</p>
                          </div>
                        )
                      })
                    }

                   
                    {/* <div style={{display:'flex', justifyContent:'center', width:'80px'}}
                    //   className={styles.categoryName}
                    //   onClick={() => dispatch({ type: 'TOGGLE_SHOW_PERSON_SETTINGS', payload: {setToShow: true}, meta: { localOnly: true } })}
                      title='Redigera mål'>
                      <p>{getTargetHour('meetings') ? `${getTargetHour('meetings')}h`:'-'}</p>
                    </div>
                    <div style={{display:'flex', justifyContent:'center', width:'80px'}}>
                      <p>{getPercentageFilled('meetings')}</p>
                    </div> */}

                  </div>
                </div>
              </div>


              <div style={{border:'2px solid #f1f1f1', marginTop:'15px'}}>

                {/* <div style={{display:'flex'}}>
                  <div style={{width:'100px', borderRight:'0px solid #aaa',}}>
                    <br></br>
                  </div>
                </div> */}
                <div>
                  {
                    state.categories.map(category => {

                      aHCounter++

                      return (
                        <div className={schoolStatisticsStyles.personRow} style={{
                          '--isEven': `${aHCounter % 2 === 0 ? '#f1f1f1' : '#fff'}`,
                        }}>
                          <div style={{width:'100px', display:'flex', justifyContent:'left', borderRight:'2px solid #aaa'}}
                        //    className={styles.categoryName}
                        //    onClick={() => setShowEditCategories(true)}
                        //    title='Redigera kategorier'>
                        >
                            <p style={{marginRight:'10px'}}>{category}:</p>
                          </div>
                          <div style={{display:'flex'}}>
                            <div style={{borderRight:'2px solid #aaa', display:'flex', justifyContent:'center', width:'80px'}}>
                              <p>{categorySummations[category] ? `${categorySummations[category]}h`:'-'}</p>
                            </div>
                            {
                              relevantWeeks.map(week => {
                                const categoryHours = statistics[week.weekNr]?.AHdict[category] || 0
                                const display = categoryHours ? `${categoryHours}h` : '-'
                                return (
                                  <div style={{ display:'flex', justifyContent:'center', minWidth:minWidthPx,}}>
                                    <p>{display}</p>
                                  </div>
                                )
                              })
                            }

                            
                            {/* <div style={{display:'flex', justifyContent:'center', width:'80px'}}
                            className={styles.categoryName}
                            onClick={() => dispatch({ type: 'TOGGLE_SHOW_PERSON_SETTINGS', payload: {setToShow: true}, meta: { localOnly: true } })}
                            title='Redigera mål'>
                              <p>{getTargetHour(category) ? `${getTargetHour(category)}h`:'-'}</p>
                            </div>
                            <div style={{display:'flex', justifyContent:'center', width:'80px', }}>
                              <p>{getPercentageFilled(category)}</p>
                            </div> */}

                          </div>
                        </div>
                      )}
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

    </div>
  )
}

export default IndividualStatisticsPublicize