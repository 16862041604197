import React, { useState, useRef, useEffect } from 'react';
import { useAppContext } from '../contexts/AppContext';
import inputStyles from '../styles/StyledInput.module.css';
import buttonStyles from '../styles/StyledButton.module.css';


const EditPersonNameForm = ({}) => {
  const { state, dispatch } = useAppContext();
  const {people, editPersonNameId} = state
  const [name, setName] = useState(people[editPersonNameId].name)

  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      // inputRef.current.select();  // Select the input text
    }
  }, []);

  const handleKeyDown = (event, type) => {
    if (event.key === 'Enter') {
      dispatch({
        type: 'EDIT_PERSON_NAME', payload: {
        personId: editPersonNameId,
        newName: name,
        }
      });
      dispatch({
        type: 'TOGGLE_SHOW_EDIT_PERSON_NAME', payload: {
        setToShow: false,
        }
      });
    }
  };

  return (
    <div>
      <p style={{fontSize:'24px'}}>Byt namn på {people[editPersonNameId].name}</p>
      <input
        value={name}
        onChange={(e) => setName(e.target.value)}
        // onBlur={() => dispatch({
        //   type: 'EDIT_DEPARTMENT_NAME', payload: {
        //   deptId: editDepartmentId,
        //   newName: name,
        //   }
        // })}
        ref={inputRef}
        onKeyDown={(e) => handleKeyDown(e, 'start')}
        style={{
          borderRadius:'15px',
          padding:'5px',
          marginBottom:'10px',
          marginTop:'10px'
        }}
        placeholder={name}></input>
      <div style={{display:'flex', justifyContent:'right'}}>

        <button className={buttonStyles['secondaryButton']} style={{marginLeft:'0px'}}onClick={() => dispatch({
                                  type: 'TOGGLE_SHOW_EDIT_PERSON_NAME', payload: {
                                  setToShow: false,
                                  }
                                })}>
          Avbryt
        </button>
        <button 
          className={buttonStyles['button-30']}
          onClick={() => {dispatch({
                            type: 'EDIT_PERSON_NAME', payload: {
                            personId: editPersonNameId,
                            newName: name,
                            }
                          });
                          dispatch({
                            type: 'TOGGLE_SHOW_EDIT_PERSON_NAME', payload: {
                            setToShow: false,
                            }
                          });
                          }}>
          Spara
        </button>
      </div>
      
    </div>
  )
}

export default EditPersonNameForm