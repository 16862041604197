import React, { useState, useRef, useEffect } from 'react';
import { useAppContext } from '../contexts/AppContext';
import inputStyles from '../styles/StyledInput.module.css';
import buttonStyles from '../styles/StyledButton.module.css';
import DaySelectorDropdown from '../assets/assets/dropdowns/day selector dropdown/DaySelectorDropdown';
import WeekSelectorDropdown from '../assets/assets/dropdowns/week selector dropdown/WeekSelectorDropdown';
import MultiSelect from '../assets/assets/dropdowns/multiselect dropdown/Multiselect';
import { odv, log, validateAndFormatTime, timeToFloat, getUniquePeopleObjets } from '../utils/Utils';
import AHDropdown from '../assets/assets/dropdowns/AH dropdown/AHDropdown';
import PersonDropdown from '../assets/assets/dropdowns/person dropdown/PersonDropdown';
import styles from '../styles/AddMeetingAHStyles.module.css';
import ModalLight from './ModalLight';
import { CategoryViewer } from './CategoryViewer';

const AddMeetingAH = () => {
  const { state, dispatch } = useAppContext();
  const {days, weeks, departments, additionalHours, categories } = state
  const weekDaySettings = state.weekDaySettings || {
    'Måndag': true,
    'Tisdag': true,
    'Onsdag': true,
    'Torsdag': true,
    'Fredag': true,
  }
  const [newPersonName, setNewPersonName] = useState('');
  const inputRef = useRef(null);
  const [selectedPeople, setSelectedPeople] = useState({})
  const [showError, setShowError] = useState(null)
  const [selectedWeekNr, setSelectedWeekNr] = useState(state.selectedWeekNr)
  const [selectedDay, setSelectedDay] = useState(state.selectedDay)

  const [meetingLength, setMeetingLength] = useState();
  const [meetingStartTime, setMeetingStartTime] = useState();
  const [finalMeetingStartTime, setFinalMeetingStartTime] = useState();
  const [finalMeetingLength, setFinalMeetingLength] = useState();

  const [selectedCategory, setSelectedCategory] = useState(state.categories[0]);
  const [selectedPersonId, setSelectedPersonId] = useState(state.selectedPerson)

  // const [showEditCategories, setShowEditCategories] = useState(false)
  const [showRemoveCategory, setShowRemoveCategory] = useState(false)
  const [showAddCategory, setShowAddCategory] = useState(false)
  const [categoryToRemove, setCategoryToRemove] = useState('')
  const [categoryValue, setCategoryValue] = useState('')

  const daySetting = weekDaySettings[selectedDay]


  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     inputRef.current.focus();
  //   }, 10); // Delay the focus by 100 milliseconds
  
  //   return () => clearTimeout(timer); // Clean up the timer
  // }, []);

  // const handleKeyDown = (event) => {
  //   if (event.key === 'Enter') {
  //     addPerson();
  //   }
  // };

  const handleKeyDown = (event, type) => {
    if (event.key === 'Enter' || event.type === 'blur') {
      const formattedTime = validateAndFormatTime(event.target.value);
      if (formattedTime) {
        if (type === 'start') {
          setFinalMeetingStartTime(formattedTime);
          setMeetingStartTime(formattedTime);
        } else {
          setFinalMeetingLength(formattedTime);
          setMeetingLength(formattedTime);
        }
      }
    } else {
      let newTime = null
      if (type === 'start') {
        setFinalMeetingStartTime(newTime)
      } else {
        setFinalMeetingLength(newTime)
      }
    }
  };

  const addPerson = () => {
    if (newPersonName){
      dispatch({
        type: 'ADD_PERSON',
        payload: { name: newPersonName}
      });
    }
    dispatch({ type: 'TOGGLE_ADD_PERSON', payload: {
      setToShow: false
    } })
    setNewPersonName('');
  };

  const isMeeting = state.selectedButton === 'Möten'
  const isAH = state.selectedButton === 'Barnfri tid' // Unneccesary extra logic incase I add more later

  const titleWording = isMeeting ? 'nytt möte' : (isAH ? 'ny barnfri tid' : '')
  const middleWording = isMeeting ? 'möte' : (isAH ? 'barnfri tid' : '')


  const options = [
    { id: 1, name: 'Electronics & Computers' },
    { id: 2, name: 'Home, Garden & Tools' },
    { id: 3, name: 'Books' },
    { id: 4, name: 'Movies, Music & Games' },
    { id: 5, name: 'Health & Beauty' },
    { id: 6, name: 'Toys, Kids & Baby' },
    { id: 7, name: 'Clothing & Jewelry' },
  ];

  const handleChange = (selectedOptions) => {
    setSelectedPeople(selectedOptions)
    setShowError(false)
  };

  const close = () => {
    setShowError(false)
    dispatch({ type: 'TOGGLE_SHOW_ADD_MEETINGAH', payload: {
      setToShow: false
    } })
  }

  const addMeetingAH = () => {
    if (isMeeting && odv(selectedPeople).length === 0) {
      setShowError('Lägg först till minst en persom som ska gå på mötet')
    } else {
      if (isMeeting) {

        if (!(finalMeetingStartTime && finalMeetingLength)) {
          setShowError('Fyll först i start och längd på den barnfria tiden')
        } else {
          let peopleIds = []

          odv(selectedPeople).forEach(person => peopleIds.push(person.id))

          if (daySetting) {
            const weeksArray = Array.from({ length: state.numberOfWeeks }, (_, index) => index + 1);
            odv(weeksArray).forEach(weekNr => {
              dispatch({
                type: 'CREATE_MEETING',
                payload: {
                  weekNr: weekNr,
                  weekDay: selectedDay,
                  peopleIds: peopleIds,
                  time: finalMeetingStartTime, 
                  length: finalMeetingLength}
              }); // Do for all days
  
            })
          } else {
            dispatch({
              type: 'CREATE_MEETING',
              payload: {
                weekNr: selectedWeekNr,
                weekDay: selectedDay,
                peopleIds: peopleIds,
                time: finalMeetingStartTime, 
                length: finalMeetingLength}
            }) // Just do for this day
          }
          
          close()
        }
      } else if (isAH) {

        if (!(finalMeetingStartTime && finalMeetingLength)) {
          setShowError('Fyll först i start och längd på den barnfria tiden')
        } else {
          if (daySetting) {
            // const weeksArray = Array.from({ length: state.numberOfWeeks }, (_, index) => index + 1);

            const relevantDays = odv(days).filter(day => weeks[day.weekId].personId === selectedPersonId && day.weekDay === selectedDay)

            odv(relevantDays).forEach(day => {
              dispatch({
                type: 'ADD_ADDITIONAL_HOUR',
                payload: { category: selectedCategory, dayId: day.id, start:finalMeetingStartTime, lengthh:finalMeetingLength}
              }); // Do for all days
  
            })
            close()
          } else {
            const dayId = odv(days).find(day => weeks[day.weekId].personId === selectedPersonId 
              && weeks[day.weekId].weekNr === selectedWeekNr
              && day.weekDay === selectedDay).id
    
            dispatch({
              type: 'ADD_ADDITIONAL_HOUR',
              payload: { category: selectedCategory, dayId: dayId, start:finalMeetingStartTime, lengthh:finalMeetingLength}
            });
            close()
          }
          
        }
      }
    }
  }

  

  return (
    <div style={{maxWidth:'500px'}}>
      
      {/* <ModalLight isOpen={showEditCategories} onClose={() => setShowEditCategories(false)}>
        <CategoryViewer setShowEditCategories={setShowEditCategories}></CategoryViewer>
      </ModalLight> */}
      
        
      
      <div style={{display:'flex', justifyContent:'space-between', alignItems:'center',  marginBottom:'10px'}}>
        <p style={{fontSize:'24px',}}>Skapa {titleWording}</p>
        {
          isAH && <p className={styles.editText}
          onClick={() => dispatch({ type: 'TOGGLE_SHOW_EDIT_CATEGORIES', payload:{setToShow:true} })}>Redigera kategorier</p>
        }
        
      </div>
      <div style={{display:'flex', alignItems:'center'}}>
        {
          isAH && <div>
              <div style={{display:'flex', alignItems:'center', gap: '5px'}}>
                <p style={{whiteSpace:'nowrap'}}>Lägg till </p>
                <AHDropdown onChange={setSelectedCategory}></AHDropdown>
                <p>på {selectedDay.toLowerCase()}</p>
                {/* <DaySelectorDropdown onChange={setSelectedDay}></DaySelectorDropdown>
                <p>vecka </p>
                <WeekSelectorDropdown onChange={setSelectedWeekNr}></WeekSelectorDropdown> */}
              </div>
              <div style={{display:'flex', alignItems:'center', marginBottom:'10px', marginTop:'10px', gap:'5px'
                }}>
                <p style={{marginRight:'0px'}}>för </p>
                <PersonDropdown onChange={setSelectedPersonId}></PersonDropdown>
                <p> klockan </p>
                <input
                    className={inputStyles['input-30']}
                    value={meetingStartTime}
                    onChange={e => setMeetingStartTime(e.target.value)}
                    onKeyDown={e => handleKeyDown(e, 'start')}
                    onBlur={(e) => handleKeyDown(e, 'start')}
                    placeholder="hh:mm"
                    // style={{width:'90px'}}
                />
                <p>med längd </p>
                <input
                    className={inputStyles['input-30']}
                    value={meetingLength}
                    onChange={e => setMeetingLength(e.target.value)}
                    onKeyDown={e => handleKeyDown(e, 'end')}
                    onBlur={(e) => handleKeyDown(e, 'end')}

                    placeholder="hh:mm"
                    // style={{width:'90px'}}
                />

              </div>
              {
                daySetting && <div style={{display:'flex', alignItems:'center'}}>
                  <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8Z" fill="rgb(83, 149, 255)"/>
                  </svg>
                  <p>En likadan barnfri tid skapas för alla veckor</p>
                </div>
              }
            </div>

        }
        {
          isMeeting && 
          <div>
            <div style={{display:'flex', alignItems:'center'}}>
              <p>Lägg till möte på {selectedDay.toLowerCase()} för</p>
              {/* <DaySelectorDropdown onChange={setSelectedDay}></DaySelectorDropdown>
              <p>, vecka </p>
              <WeekSelectorDropdown onChange={setSelectedWeekNr}></WeekSelectorDropdown> */}
            </div>
            <div style={{marginTop:'10px', marginBottom:'10px'}}>
              <MultiSelect options={odv(getUniquePeopleObjets(state.people))} onChange={handleChange} />
            </div>
            <div style={{display:'flex', alignItems:'center', marginBottom:'10px', marginTop:'10px', gap:'5px'
                }}>
                <p> klockan </p>
                <input
                    className={inputStyles['input-30']}
                    value={meetingStartTime}
                    onChange={e => setMeetingStartTime(e.target.value)}
                    onKeyDown={e => handleKeyDown(e, 'start')}
                    onBlur={(e) => handleKeyDown(e, 'start')}
                    placeholder="hh:mm"
                    // style={{width:'90px'}}
                />
                <p>med längd </p>
                <input
                    className={inputStyles['input-30']}
                    value={meetingLength}
                    onChange={e => setMeetingLength(e.target.value)}
                    onKeyDown={e => handleKeyDown(e, 'end')}
                    onBlur={(e) => handleKeyDown(e, 'end')}
                    placeholder="hh:mm"
                    // style={{width:'90px'}}
                />

              </div>
              {
                daySetting && <div style={{display:'flex', alignItems:'center'}}>
                  <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8Z" fill="rgb(83, 149, 255)"/>
                  </svg>
                  <p>Ett likadant möte skapas för alla veckor</p>
                </div>
              }
          </div>
        }
      </div>  
      {
        showError && <div style={{maxWidth:'400px'}}>
            <p>
              {showError}
            </p>
          </div>
      }
      <div style={{display:'flex', justifyContent:'right'}}>
        <button className={buttonStyles['secondaryButton']} onClick={() => dispatch({ type: 'TOGGLE_SHOW_ADD_MEETINGAH', payload: {
          setToShow: false
        } })}>Avbryt</button>
        <button className={buttonStyles['button-30']} onClick={addMeetingAH}>Skapa {middleWording}</button>
      </div>
    
    </div>
  )
}

export default AddMeetingAH