import React, { useState, useRef, useEffect } from 'react';
import styles from './PersonDropdown.module.css';
import { useAppContext } from '../../../../contexts/AppContext';
import { weekDays, log, odv, getUniquePeopleObjets } from '../../../../utils/Utils';


const PersonDropdown = ({onChange, initialSelectedPerson = null, showAllPeople = false}) => {
  const { state, dispatch } = useAppContext();
  const {additionalHours, objectId, people, days, weeks, selectedPerson} = state
  const [selectedPersonName, setSelectedPersonName] = useState(initialSelectedPerson || people[weeks[days[additionalHours[objectId]?.dayId]?.weekId]?.personId]?.name || people[selectedPerson]?.name)
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelect = (person) => {
    setSelectedPersonName(person.name);
    onChange(person.id);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    log("persond ropdown: initialSelectedPerson", initialSelectedPerson, selectedPersonName)

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };

  }, []);

  const relevantPeople = showAllPeople ? odv(getUniquePeopleObjets(people)) : odv(state.people).filter(person => person.departmentId === state.selectedDepartment)

  return (
    <div className={styles.daySelector} ref={dropdownRef} onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
      <button className={styles.selectorButton} onClick={toggleDropdown} >
        <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>

          <p style={{overflow:'hidden'}} className={styles.para}>{selectedPersonName || 'Välj typ'}</p>
          <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g id="_24x24_On_Light_Arrow-Bottom" data-name="24x24/On Light/Arrow-Bottom" transform="translate(0 24) rotate(-90)">
              <rect id="view-box" width="24" height="24" fill="none"/>
              <path id="Shape" d="M.22,10.22A.75.75,0,0,0,1.28,11.28l5-5a.75.75,0,0,0,0-1.061l-5-5A.75.75,0,0,0,.22,1.28l4.47,4.47Z" transform="translate(14.75 17.75) rotate(180)" fill="#141124"/>
            </g>
          </svg>
        </div>
      </button>
      {isOpen && (
        <ul className={styles.dropdown}>
          {relevantPeople.map((person) => (
            <li key={person.id} onClick={() => handleSelect(person)}>
              <p style={{overflow:'hidden'}}>{person.name}</p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default PersonDropdown;