import React, { useState, useEffect } from 'react';
import { useWeekContext } from '../contexts/WeekContext';
import { useDayContext } from '../contexts/DayContext';
import inputStyles from '../styles/StyledInput.module.css';
import { validateAndFormatTime, getTextColor, timeToFloat } from '../utils/Utils';
import hoursStyles from '../styles/Hours.module.css'
import { log, odv } from '../utils/Utils';
import { useAppContext } from '../contexts/AppContext';
import styles from './WorkHoursMinimal.module.css'


const BreakHoursMinimal = ({dayId}) => {
  const { state, dispatch } = useAppContext(); 
  const {days, weeks} = state
  const [startTime, setStartTime] = useState(Object.values(state.breakHours).find(breakHour => (
    breakHour.dayId === dayId && breakHour.type === 'start'))?.time);
  const [breakLength, setBreakLength] = useState(Object.values(state.breakHours).find(breakHour => (
    breakHour.dayId === dayId && breakHour.type === 'length'))?.time);
  const [finalStartTime, setFinalStartTime] = useState(Object.values(state.breakHours).find(breakHour => (breakHour.dayId === dayId && breakHour.type === 'start'))?.time || '00:00');
  const [finalLength, setFinalLength] = useState(Object.values(state.breakHours).find(breakHour => (breakHour.dayId === dayId && breakHour.type === 'length'))?.time || '00:00');

  const weekDaySettings = state.weekDaySettings || {
      'Måndag': true,
      'Tisdag': true,
      'Onsdag': true,
      'Torsdag': true,
      'Fredag': true,
  }

  const daySetting = weekDaySettings[state.days[dayId].weekDay]

  useEffect(() => {
    const foundStartTime = Object.values(state.breakHours).find(breakHour => (breakHour.dayId === dayId && breakHour.type === 'start'))?.time || ''
    const foundBreakLength = Object.values(state.breakHours).find(breakHour => (breakHour.dayId === dayId && breakHour.type === 'length'))?.time || ''
    
    setStartTime(foundStartTime);

    if ((!foundStartTime && foundBreakLength) || (timeToFloat(foundStartTime) === 0)) {
      setStartTime('Ja')
    }
    // setBreakLength(foundBreakLength);

    // if (!foundStartTime) {setFinalStartTime('00:00')} else setFinalStartTime(foundStartTime);
    // if (!foundBreakLength) {setFinalLength('00:00')} else setFinalLength(foundBreakLength);

  }, [dayId, state.breakHours]);
  
  const handleKeyDown = (event, type) => {
    log("handling key down..")
    if (event.key === 'Enter' || event.type === 'blur') {
      log("handling key down 2..")

      switch (event.target.value.toLowerCase()) {

        case 'nej':
          
          if (daySetting) {
            log("case 1")
            const relevantDays = odv(days).filter(day => weeks[day.weekId].personId === weeks[days[dayId].weekId].personId && day.weekDay === days[dayId].weekDay)
            log("found relevant days: ", relevantDays)
            odv(relevantDays).forEach(day => {
              dispatch({ type: 'SET_BREAK_HOUR_TIME', payload: {time: null, dayId: day.id, type: 'start'} });
              dispatch({ type: 'SET_BREAK_HOUR_TIME', payload: {time: null, dayId: day.id, type: 'length'} });
  
            })
          } else {
            log("case 2")

            dispatch({ type: 'SET_BREAK_HOUR_TIME', payload: {time: null, dayId: dayId, type: 'start'} });
            dispatch({ type: 'SET_BREAK_HOUR_TIME', payload: {time: null, dayId: dayId, type: 'length'} });
          }
          return

        case 'ja':
        case 'j':
          if (daySetting) {
            log("case 3")

              const relevantDays = odv(days).filter(day => weeks[day.weekId].personId === weeks[days[dayId].weekId].personId && day.weekDay === days[dayId].weekDay)
            log("found relevant days: ", relevantDays)
            odv(relevantDays).forEach(day => {
              dispatch({ type: 'SET_BREAK_HOUR_TIME', payload: {time: '0:00', dayId: day.id, type: 'start'} });
              dispatch({ type: 'SET_BREAK_HOUR_TIME', payload: {time: '0:30', dayId: day.id, type: 'length'} });
  
            })
          } else {
            log("case 4")

            dispatch({ type: 'SET_BREAK_HOUR_TIME', payload: {time: '0:00', dayId: dayId, type: 'start'} });
            dispatch({ type: 'SET_BREAK_HOUR_TIME', payload: {time: '0:30', dayId: dayId, type: 'length'} });
          }
          return
        default:
          
      }
      const formattedTime = validateAndFormatTime(event.target.value);
      if (formattedTime) {
        if (daySetting) {
          log("case 5")

          const relevantDays = odv(days).filter(day => weeks[day.weekId].personId === weeks[days[dayId].weekId].personId && day.weekDay === days[dayId].weekDay)
          log("found relevant days: ", relevantDays)
          odv(relevantDays).forEach(day => {
            dispatch({ type: 'SET_BREAK_HOUR_TIME', payload: {time: formattedTime, dayId: day.id, type: 'start'} });
            dispatch({ type: 'SET_BREAK_HOUR_TIME', payload: {time: '0:30', dayId: day.id, type: 'length'} });
          })
        } else {
          log("case 6")

          dispatch({ type: 'SET_BREAK_HOUR_TIME', payload: {time: formattedTime, dayId: dayId, type: 'start'} });
          dispatch({ type: 'SET_BREAK_HOUR_TIME', payload: {time: '0:30', dayId: dayId, type: 'length'} });
        }
        
      } else {
        let newTime = null
        
        if (daySetting) {
          log("case 7")

          const relevantDays = odv(days).filter(day => weeks[day.weekId].personId === weeks[days[dayId].weekId].personId && day.weekDay === days[dayId].weekDay)
          log("found relevant days: ", relevantDays)
          odv(relevantDays).forEach(day => {
            dispatch({ type: 'SET_BREAK_HOUR_TIME', payload: {time: newTime, dayId: day.id, type: type} });
            dispatch({ type: 'SET_BREAK_HOUR_TIME', payload: {time: null, dayId: dayId, type: 'length'} });
          })
        } else {
          log("case 8")

          dispatch({ type: 'SET_BREAK_HOUR_TIME', payload: {time: newTime, dayId: dayId, type: type} });
          dispatch({ type: 'SET_BREAK_HOUR_TIME', payload: {time: null, dayId: dayId, type: 'length'} });
        }

      }
    }
  };

  const handleWheel = (event, type) => {
    event.preventDefault(); // Prevent the page from scrolling
    const currentTime = startTime;
    if (!currentTime) return;


    let [hours, minutes] = currentTime.split(':').map(Number);
    if (currentTime === 'Ja'){
      hours = 0
      minutes = 0
    }
    let totalMinutes = hours * 60 + minutes;

    // Increment or decrement by 15 minutes
    totalMinutes += event.deltaY < 0 ? 15 : -15;

    // Ensure time is within 00:00 to 23:59 range
    if (totalMinutes < 0) totalMinutes += 1440;
    if (totalMinutes >= 1440) totalMinutes -= 1440;

    const newHours = Math.floor(totalMinutes / 60).toString().padStart(2, '0');
    const newMinutes = (totalMinutes % 60).toString().padStart(2, '0');
    const newTime = `${newHours}:${newMinutes}`;

    if (type === 'start') {
      setStartTime(newTime);
      dispatch({ type: 'SET_BREAK_HOUR_TIME', payload: { time: newTime, dayId, type: 'start' } });
    } 

    // autofillThirty(state, dispatch, dayId);
  };

  return (
    <div style={{marginLeft:'20px',  }} > {/*style={{borderRight: '1.5px solid #000'}}*/}
      {/* <p style={{fontWeight:'500'}}>Rast</p> */}

        <input
          title='Använd formatet hh.mm, hh:mm eller h'
          className={`${styles.input} ${styles.bLInput}`}
          value={startTime}
          onChange={e => setStartTime(e.target.value)}
          onKeyDown={e => handleKeyDown(e, 'start')}
          onBlur={(e) => handleKeyDown(e, 'start')}
          onWheel={e => handleWheel(e, 'start')}
          placeholder="Start"
        />
        {/* <p style={{ color: getTextColor(finalStartTime), width:'65px', marginRight:'00px' }}>{finalStartTime}</p> */}
        {/* <input
          title='Använd formatet hh.mm, hh:mm eller h'
          className={styles.input}
          value={breakLength}
          onChange={e => setBreakLength(e.target.value)}
          onKeyDown={e => handleKeyDown(e, 'length')}
          onBlur={(e) => handleKeyDown(e, 'length')}
          placeholder="Längd"
          style={{marginLeft:'10px'}}
        /> */}
      {/* <p style={{ color: getTextColor(finalLength), paddingRight: '00px', width:'65px', marginRight:'00px' }}>{finalLength}</p> */}
    </div>
  );
};

export default BreakHoursMinimal;
