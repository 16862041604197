import React, { useState } from 'react';
import { useWeekContext } from '../contexts/WeekContext';
import styles from '../styles/StaffChart.module.css';
import buttonStyles from '../styles/StyledButton.module.css';

const WeekPicker = ({showAddRemove = true}) => {
  const { state, dispatch } = useWeekContext();
  
  const selectedDepartmentId = state.selectedDepartment;
  const selectedPersonId = state.selectedPerson;
  const department = state.departments[selectedDepartmentId]
  const weeks = Object.values(state.weeks).filter(week => week.personId === selectedPersonId)
  //selectedPersonId ? department.people[selectedPersonId].weeks : {}

  const addWeek = () => {
    dispatch({
      type: 'ADD_WEEK',
      payload: {}, // personId: newPersonName, name: newPersonName}
    });
  };

  const removeWeek = () => {
    dispatch({
      type: 'REMOVE_WEEK',
      payload: {}
    });
  };

  function selectWeek(weekId, weekNr) {
      // const buttonId = event.target.id;
      // const buttonId = event.target.value;
      
      dispatch({
          type: 'SELECT_WEEK',
          payload: { weekNr: weekNr, weekId: weekId}
        });
  }

  return (
    <div>
      {weeks && Object.values(weeks).map((week) => (
                <button className={`${styles.styledButton} ${week.id === state.selectedWeekId ? styles.selected : ''}`}
                key={week.id} 
                onClick={() => selectWeek(week.id, week.weekNr)}
                style={{ backgroundColor: state.selectedWeekNr === week.weekNr ? '#30c01a' : 'rgb(53, 109, 255)', color: '#fff' }}
                ><span className="text">Vecka {week.weekNr}</span></button>
            ))}
      {showAddRemove && 
        <>
          <button className={buttonStyles['button-30']} onClick={addWeek}>+</button>
          <button className={buttonStyles['button-30']} onClick={removeWeek}>-</button>
        </>
      }
      
    </div>
  );
};

export default WeekPicker;
