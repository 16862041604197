import React, { useState, useRef, useEffect } from 'react';
import { useAppContext } from '../contexts/AppContext';
import inputStyles from '../styles/StyledInput.module.css';
import buttonStyles from '../styles/StyledButton.module.css';

const LI = ({title, text, children}) => {
  return (
    <div style={{marginBottom:'13px'}}>
      <p style={{fontWeight:'600'}}>{title}</p>
      <p>- {children}</p>
    </div>
  )
}

const InfoForm = () => {
  const { state, dispatch } = useAppContext();


  return (
    <div style={{maxWidth:'900px'}}>
      <p style={{fontSize:'28px'}}>Följande information är bra att veta:</p>
      {/* <LI title={'Programmet är baserat på 15-minuters intervall.'}> Fyll därför i timmar i formatet 10.15 (0 / 15 / 30 / 45) alternativt decimalkvartar (0 / 25 / 50 / 75)</LI> */}
      <LI title={'Om du vill att en person ska jobba på flera avdelningar'}>så skapar du en person med samma namn i alla avdelningar du vill att personen ska jobba i och fyller i respektive timmar.
          Fyll inte i överlappande timmar, då kan uträkningar bli fel.
          Alla summeringar kommer att räkna på alla personer med samma namn i alla avdelningar. Detta kommer även att synas i exporten.
      </LI>
      <LI title={'Personalmöten kan vara utanför eller inom arbetstiden.'}> Detta tas hänsyn till i beräkningarna</LI>
      <LI title={'Tid utanför barngrupp måste vara inom arbetstiden'}>för att alla beräkningar ska bli rätt</LI>
      {/* <LI title={'Grafernas staplar så som 10:00 eller 14:30'}>avser intervallet 10:00-10:15 respektive 14:30-14:45. </LI> */}
      {/* <LI title={'Måste jag välja en starttid för rasten?'}> Nej, det går bra att bara fylla i längd, detta kommer med i beräkningarna</LI> */}
      {/* <LI title={'Jag får negativa värden'}> Då har du troligtvis satt en sluttid som är före starttiden, alternativt fyllt i en sluttid på rastens längd.
        Raster har endast start och längd (ej sluttid)
         </LI> */}
      <LI title={'Något är fortfarande otydligt'}>Då ringer du 070 483 15 92 eller skriver till kontakt@beundraab.se</LI>
      <p style={{fontSize:'28px', marginBottom:'20px'}}>Kortkommandon:</p>
      <LI title={'Tab (knappen ovanför Capslock)'}> eller Shift + Tab för att växla mellan olika inmatningsfält</LI>
      {/* <LI title={'Ctrl + A'}>för att markera all text innuti ett inmatningsfält</LI> */}
      <LI title={'A'}>för att skapa en ny avdelning</LI>
      <LI title={'P'}>för att skapa en ny person</LI>
      <LI title={'V'}>eller Shift + V för att rotera vecka</LI>
      <LI title={'D'}>eller Shift + D för att rotera dag</LI>
    </div>
  )
}

export default InfoForm