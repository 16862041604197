import DepartmentPicker from "../department/DepartmentPicker"
import { useState } from "react";
import AddDepartmentButton from "../people/AddDepartmentButton"
import { useAppContext } from '../contexts/AppContext';
import styles from '../styles/PeopleOverview.module.css';
import selectStyles from '../styles/ThreeWaySelect.module.css';
import daySelectorStyles from '../styles/Dayselector.module.css';
import PersonBarStyles from './MeetingAndAHAndWH.module.css'
import rIStyles from '../visualOverhaul/RightIsland.module.css'


import meetingAHStyles from '../styles/MeetingAH.module.css';
import addMeetingAHButtonStyles from '../styles/AddMeetingAHButton.module.css';
import { odv, log, odvs, timeToFloat, floatToTime, calculateDayTotalHours, calculateDayTotalHoursActual, weekDays, threeLetterWeekDays } from "../utils/Utils";
import AddPersonButton from "../people/AddPersonButton";
import RoundedDiv from "../assets/assets/rounded div/RoundedDiv";
import WeekSelector from "../visualOverhaul/WeekSelector";
import Week from "../week/Week";
import { WeekProvider } from "../contexts/WeekContext";



export const MeetingAndAHAndWH = ({metainfo}) => {
  const { state, dispatch } = useAppContext();
  const { people, weeks, days, departments, selectedPerson, selectedWeekId, selectedWeekNr, workHours, meetings, additionalHours,
          selectedDepartment, selectedDay, selectedButton, numberOfWeeks
   } = state
  const relevantDays = odv(days).filter(day => day.weekId === selectedWeekId)

  const [pressedWeek, pressedDepartment, selectedOption] = metainfo
  log("metainfo is: ", metainfo)
  const [localPressedWeek, setLocalPressedWeek] = useState(pressedWeek?.weekNr || 0)
  const [selectedOptionn, setSelectedOption] = useState(selectedOption);

  const [hoveredPersonId, setHoveredPersonId] = useState()
  

  const isMeeting = state.selectedButton === 'Möten'
  const isAH = state.selectedButton === 'Barnfri tid'
  const titleWording = isMeeting ? 'möten' : (isAH ? 'barnfri tid' : '')


  const width = 700
  const timeStart = 6
  const timeEnd = 18
  const timeLength = timeEnd - timeStart

  const pixelPerHour = width / timeLength // old pph

  const IncrementButton = ({isDec}) => {
    return (
      <button className={rIStyles.incButton}
      onClick={() => {
        if (isDec){
          if (localPressedWeek === 1){
            setLocalPressedWeek(numberOfWeeks)
          } else {
            setLocalPressedWeek(localPressedWeek - 1)
          }          
        } else {
          if (localPressedWeek === numberOfWeeks){
            setLocalPressedWeek(1)
          } else {
            setLocalPressedWeek(localPressedWeek + 1)
          } 
        }
        }}>
        <svg width="22px" height="22px" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: `${isDec ? '' : 'scaleX(-1)'}` }}>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L9.41421 12L15.7071 18.2929C16.0976 18.6834 16.0976 19.3166 15.7071 19.7071C15.3166 20.0976 14.6834 20.0976 14.2929 19.7071L7.29289 12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536 11.4804 7.29289 11.2929L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289Z" fill="#000000"/>
        </svg>
      </button>
    )
  }

  const PersonBar = ({ start, end, name, personId, dayId }) => {
    const totalHeightVh = 40; // The total height you want to use in viewport height (for example, 100vh)
    const vhPerHour = totalHeightVh / timeLength; // Calculate vh per hour
  
    // Calculate vertical positioning and height using vh instead of px
    let offsetVh = (start - timeStart) * vhPerHour;
    
    let calcStart = start
    let calcEnd = end

    if (calcStart < 6) {
      calcStart = 6
    }

    if (calcEnd < 6 ) {
      calcEnd = 9
    }
    const heightVh = (calcEnd - calcStart) * vhPerHour;
  
    // Ensure offset is within bounds
    if (offsetVh < 0) {
      offsetVh = 0;
    }
  
    const selected = selectedPerson === personId;

    
  
    return (
      <div
        className={`${PersonBarStyles.personBar} ${hoveredPersonId === personId ? PersonBarStyles.hoveredBar : ''} ${hoveredPersonId && hoveredPersonId !== personId ? PersonBarStyles.unHoveredBar : ''}`}
        style={{
          '--offset': `${offsetVh}vh`,
          '--height': `${heightVh}vh`,
        }}
        onMouseEnter={() => setHoveredPersonId(personId)}
        onMouseLeave={() => setHoveredPersonId(null)}
        onClick={() => dispatch({ type: 'TOGGLE_SHOW_WORKHOUR_EDITOR', payload: { setToShow: true, dayId: dayId } })}
      >
        <span style={{ marginTop: '15px', color: `${selected ? '#000' : '#000'}` }}
        className={PersonBarStyles.rotatedText}>
          {floatToTime(start)}
        </span>
        <span style={{ fontWeight: `${selected ? '500' : '500'}`, color: `${selected ? '#000' : '#000'}` }}
        className={PersonBarStyles.rotatedText}>
          {name}
        </span>
        <span style={{ marginBottom:'15px', color: `${selected ? '#000' : '#000'}` }}
        className={PersonBarStyles.rotatedText}>
          {floatToTime(end)}
        </span>
      </div>
    );
  };

  return (
    <div style={{display:'flex', flexDirection:'column',  paddingLeft:'20px', paddingRight:'20px'}}>
     {/* <p>{type}</p>  */}
      <div style={{display:'flex', justifyContent:'center', margin:'10px', alignItems:'center'}}>
        <div style={{display:'flex', alignItems:'center', marginRight:'10px'}}>
          <p style={{fontWeight:'400', fontSize:'1.5em'}}>{pressedDepartment?.name} <IncrementButton isDec={true} dispatch={dispatch} /> v.{localPressedWeek} <IncrementButton isDec={false} dispatch={dispatch} /></p>
        </div>
        <div style={{display:'flex', alignItems:'center', flexDirection: 'row', height:'25px'}}>
          <div className={`${selectStyles.selectOption}
                           ${selectedOptionn === 1 ? selectStyles.selected : ''}
                           ${selectStyles.leftItem}`}
                onClick={() => setSelectedOption(1)}>
            <p>Timmar</p>
          </div>
          <div className={`${selectStyles.selectOption}
                           ${selectedOptionn === 2 ? selectStyles.selected : ''}`}
                onClick={() => setSelectedOption(2)}>
            <p>Barnfri tid</p>
          </div>
          <div className={`${selectStyles.selectOption}
                           ${selectedOptionn === 3 ? selectStyles.selected : ''}
                           ${selectStyles.rightItem}`}
                onClick={() => setSelectedOption(3)}>
            <p>Möten</p>
          </div>
        </div>
      </div>
      <div style={{borderTop:'1.5px solid black'}}>
        {/* <div style={{display:'flex', justifyContent:'space-between'}}>
          {
            Object.keys(weekDays).map(weekDay => {
              const a = 1
              return (
                <div style={{display:'flex', flexDirection:'column', width:'20%', alignItems:'center'}}>
                  {weekDay}
                </div>
                )
              })
          }
        </div> */}
        <div style={{display:'flex', justifyContent:'space-between', overflowY:'auto', marginTop:'0px', width:'100%'}}>
          {
            Object.values(weekDays).map(weekDay => {
              let foundObjects = {}
              switch (selectedOptionn) {
                
                case 1: //Timmar
                  odv(days).forEach(day => {
                    if (day.weekDay === weekDay 
                      && weeks[day.weekId].weekNr === localPressedWeek
                      && people[weeks[day.weekId].personId].departmentId === pressedDepartment.id) {
                      foundObjects[day.id] = day
                      }
                  })
                  break
                case 2: //Barnfri tid
                  odv(additionalHours).forEach(ah => {
                    const day = days[ah.dayId]
                    if (day.weekDay === weekDay 
                        && weeks[day.weekId].weekNr === localPressedWeek
                        && people[weeks[day.weekId].personId].departmentId === pressedDepartment.id) {
                      foundObjects[ah.id] = ah
                    }
                  })
                  break
                case 3: //Möten
                  odv(meetings).forEach(meeting => {
                    if (meeting.weekNr === localPressedWeek && meeting.weekDay === weekDay) {
                      meeting.peopleIds.forEach(personId => {
                        if (people[personId].departmentId === pressedDepartment.id) {
                          if (!foundObjects[meeting.id]) {
                            foundObjects[meeting.id] = meeting

                          }
                        }
                      })}
                    })
                  break
              }



              // borderLeft:'1px solid #eee', borderRight:'1px solid #eee',
              // width:'20%',
              return ( 
                <div style={{display:'flex', flexDirection:'column',  alignItems:'center', height:'60vh', minWidth:'130px', paddingLeft:'4px', paddingRight:'4px', borderLeft:`${weekDay === 'Måndag' ? '1.5px solid #e1e1e1' : ''}`, borderRight:'1.5px solid #e1e1e1'}} //150%
                className={meetingAHStyles.dayColumn}
                >
                  <div 
                  // onClick={() => {dispatch({
                  //     type: 'SELECT_DAY',
                  //     payload: { day: weekDay}
                  //   })}}
                    style={{padding:'5px'}}
                    // className={meetingAHStyles.dayContainer}>
                      >
                      {threeLetterWeekDays[weekDay]}
                  </div>
                  {
                    (selectedOptionn === 1) 
                    
                        ? 
                        // This is for the work hours, so object is an individual day
                    <div style={{display:'flex', flexDirection:'row', width:'100%', height:'100%', justifyContent:'left'}}>
                      {
                        odvs(foundObjects).map(object => {
    
                          const workHour = odv(workHours).find(wH => wH.dayId === object.id && wH.type === 'start')
                          const workHourStart = workHour?.time 
                          // const workHourEnd = odv(workHours).find(wH => weeks[days[wH.dayId].weekId]?.personId === person.id && wH.type === 'end' && days[wH.dayId].weekDay === day.weekDay && weeks[days[wH.dayId].weekId].weekNr === state.selectedWeekNr)?.time
                          const workHourEnd = odv(workHours).find(wH => wH.dayId === object.id && wH.type === 'end')?.time
                          
                          const person = people[weeks[object.weekId].personId]

                          let containerWidth = 32

                          const barWidth = containerWidth - 5
                          if (workHourStart && workHourEnd) {
                            // `${100 / foundObjects.length}%`
                            // `50%`
                            return (
                              <div style={{ width: `${containerWidth}px`, display:'flex', flexDirection:'column', alignItems:'center'}}>
                                <div style={{width: `${barWidth}px`, height:'45vh', display:'flex', justifyContent:'center'}}>
                                  <div style={{display:'flex', flexDirection:'column', position:'absolute', minWidth:`25px`}}>
                                    <PersonBar start={timeToFloat(workHourStart)} end={timeToFloat(workHourEnd)} name={person.name} personId={person.id} dayId={object.id}></PersonBar>
                                  </div>
                                </div>
                                <div style={{display:'flex', justifyContent:'center', width:'100%'}}>
                                  <p style={{fontWeight:'500', color: `${selectedPerson === person.id ? '#000' : '#000'}`}}
                                  className={PersonBarStyles.rotatedText}>= {calculateDayTotalHoursActual(state, object.id)}h</p>
                                </div>
                              </div>
                            )
                          } else {
                            return (
                              <div style={{display:'flex', flexDirection:'column', width:`${containerWidth}px`,}}>
                                <div style={{width:`${containerWidth}px`, height:'45vh', display:'flex', justifyContent:'center'}}>
                                  <div style={{width: `${barWidth}px`, height:'45vh', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                    <span style={{ cursor:'pointer', color:'#818181'}}
                                    onClick={() => dispatch({ type: 'TOGGLE_SHOW_WORKHOUR_EDITOR', payload: { setToShow: true, dayId: object.id } })}
                                    className={`${PersonBarStyles.rotatedText} ${PersonBarStyles.emptyPersonBar}`}>{person.name}</span>
                                    {/* onClick={() => selectPerson(person.id)} */}
                                  </div>
                                </div>
                                <div style={{display:'flex', justifyContent:'center',}}>
                                  <p style={{fontWeight:'500'}}
                                  // className={PersonBarStyles.rotatedText}>= {calculateDayTotalHoursActual(state, object.id)}h</p>
                                  className={PersonBarStyles.rotatedText}>{`${calculateDayTotalHoursActual(state, object.id) === 0 ? '-': (`${calculateDayTotalHoursActual(state, object.id)}h`)}`}</p>
                                </div>
                                <div style={{display:'flex', justifyContent:'center', marginTop:'30px'}}>

                                  <button 
                                    className={addMeetingAHButtonStyles.addButtonSmall}
                                    title={`Lägg till ${selectedOptionn === 3 ? 'möte' : 'barnfri tid'}`} 
                                    onClick={() => {
                                                      dispatch({ type: 'TOGGLE_SHOW_WORKHOUR_EDITOR', payload: { setToShow: true, dayId: object.id } })
                                                    }}
                                    // style={{border:'0px', marginTop:'10px', marginBottom:'20px', backgroundColor:'#abeb96', borderRadius:'20px', fontSize:'20px', paddingLeft:'10px', paddingRight:'10px', paddingTop:'2px', paddingBottom:'2px'}}>
                                  >
                                    +
                                  </button>
                                </div>
                              </div>
                            )
                          }
                      })}
                    </div> 
                    
                      :

                      odvs(foundObjects).map(object => {
                        switch (selectedOptionn) {
                          case 2: //Barnfri tid
                            log("case 2 and objects are: ", selectedOptionn, foundObjects)
                            return(
                              <div className={meetingAHStyles.container}
                              onClick={() => {
                                dispatch({ type: 'SET_PRESSED_DAY_BUTTON', payload: { newValue: weekDay } });
                                dispatch({ type: 'TOGGLE_SHOW_MEETINGAH_POPUP', payload: { setToShow: true, objectId: object.id} });
                                dispatch({
                                  type: 'SELECT_BUTTON',
                                  payload: { button: 'Barnfri tid'}
                                })}}>
                                  <p style={{fontWeight:'500'}}>{object.category}</p>
                                  <p style={{overflow:'hidden'}}>{people[weeks[days[object.dayId].weekId].personId].name}</p>
                                  <p >{object.start} - {floatToTime(timeToFloat(object.start) + timeToFloat(object.length))}</p>
                              </div>
                            )
                          case 3: //Möten
                            log("case 3 and objects are: ", selectedOptionn, foundObjects)
                            return (
                              <div onClick={() => {
                                dispatch({ type: 'SET_PRESSED_DAY_BUTTON', payload: { newValue: weekDay } });
                                dispatch({ type: 'TOGGLE_SHOW_MEETINGAH_POPUP', payload: { setToShow: true, objectId: object.id } });
                                dispatch({
                                  type: 'SELECT_BUTTON',
                                  payload: { button: 'Möten'}
                                })}}
                                className={meetingAHStyles.container}>
                                  {/* <p>{object.weekDay}</p> */}
                                  {/* <p style={{fontWeight:'500'}}>Möte {threeLetterWeekDays[object.weekDay]}</p> */}
                                  <p style={{fontWeight:'500'}}>{object.time} - {floatToTime(timeToFloat(object.time) + timeToFloat(object.length))}</p>
                                  {
                                    object.peopleIds.map(personId => (
                                      <div>
                                        <p>- {people[personId].name}</p>
                                      </div>
                                    ))
                                  }
                              </div>
                            )
                        }
                        
                      
                    })
                  }
                  
                  {
                    (selectedOptionn !== 1) && <div>
                      <button 
                        className={addMeetingAHButtonStyles.addButton}
                        title={`Lägg till ${selectedOptionn === 3 ? 'möte' : 'barnfri tid'}`} 
                        onClick={() => {
                                        dispatch({ type: 'SET_PRESSED_DAY_BUTTON', payload: { newValue: weekDay } });
                                        dispatch({ type: 'TOGGLE_SHOW_ADD_MEETINGAH', payload: { setToShow: true } });
                                        dispatch({
                                          type: 'SELECT_BUTTON',
                                          payload: { button: selectedOptionn === 3 ? 'Möten' : 'Barnfri tid'} // dont change these strings
                                        });
                                        }}
                        // style={{border:'0px', marginTop:'10px', marginBottom:'20px', backgroundColor:'#abeb96', borderRadius:'20px', fontSize:'20px', paddingLeft:'10px', paddingRight:'10px', paddingTop:'2px', paddingBottom:'2px'}}>
                      >
                        +
                      </button>
                    </div>
                  }
                </div>
                )
              })
          }
        </div>

      </div>
    </div>
  )
}
