import React, { useState, useRef, useEffect } from 'react';
import { useAppContext } from '../contexts/AppContext';
import inputStyles from '../styles/StyledInput.module.css';
import buttonStyles from '../styles/StyledButton.module.css';

const AddDepartmentForm = () => {
  const { state, dispatch } = useAppContext();
  const [newDepartmentName, setNewDepartmentName] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      inputRef.current.focus();
    }, 10); // Delay the focus by 100 milliseconds
  
    return () => clearTimeout(timer); // Clean up the timer
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      addPerson();
    }
  };

  const addPerson = () => {
    if (newDepartmentName) {
      dispatch({
        type: 'ADD_DEPARTMENT',
        payload: {name: newDepartmentName}
      });
    }
    dispatch({ type: 'TOGGLE_ADD_DEPARTMENT', payload: {
      setToShow: false
    } })
    setNewDepartmentName('');
  };

  return (
    <div>
      <p style={{fontSize:'24px'}}>Lägg till ny avdelning</p>
      <p>Avdelningen följer det rullande schemat</p>
      {/* <br></br> */}
      <input
        ref={inputRef}
        className={inputStyles['input-30']}
        value={newDepartmentName}
        onChange={(e) => setNewDepartmentName(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="Namn på avdelning"
        style={{width:'150px', backgroundColor:'#e8e8e8'}}
      />
      <button className={buttonStyles['button-30']} onClick={addPerson}>Skapa avdelning</button>
    </div>
  )
}

export default AddDepartmentForm