import React, { useState, useRef, useEffect } from 'react';
import { useAppContext } from '../contexts/AppContext';
import inputStyles from '../styles/StyledInput.module.css';
import buttonStyles from '../styles/StyledButton.module.css';
import WeekPicker from '../week/WeekPicker';
import { WeekProvider } from '../contexts/WeekContext';
import { odv, log, weekDays, calculateDayTotalHours, calculateDayTotalHoursActual, timeToFloat } from '../utils/Utils';
import { getDayAdditionalHourLength, getEffectiveWorkHour } from '../utils/DataCalculator';
import RoundedDiv from '../assets/assets/rounded div/RoundedDiv';

const PersonInfo = () => {
  const { state, dispatch } = useAppContext();
  const { people, weeks, days, departments, selectedPerson, selectedWeekId, selectedWeekNr, workHours, meetings, additionalHours,
          selectedDepartment, selectedDay } = state
  const relevantDays = odv(days).filter(day => day.weekId === selectedWeekId)
  const [hoveredPersonId, setHoveredPersonId] = useState(null);


  let weekWorkSum = 0

  let allRelevantDays = []
  let allRelevantAH = []
  let relevantAH = []

  relevantDays.forEach(day => {
    weekWorkSum += calculateDayTotalHours(state, day.id)
    relevantAH.push(...odv(additionalHours).filter(aH => aH.dayId === day.id))
  })

  const relevantWeeks = odv(weeks).filter(week => week.personId === selectedPerson) // Take all the weeks that belong to this person

  relevantWeeks.forEach(week => {
    allRelevantDays.push(...odv(days).filter(day => day.weekId === week.id)) // Add all the days in these weeks

  })

  const dayIds = odv(allRelevantDays).map(day => day.id)

  odv(additionalHours).forEach(aH => {
    // if (Object.keys(allRelevantDays).map(Number).includes(aH.dayId)) {
    if (dayIds.includes(aH.dayId)) {
      allRelevantAH.push(aH)
    }
  })

  let totalCalendarWorkSum = 0
  let totalCalendarAHSum = 0
  let weekAHSum = 0

  relevantAH.forEach(aH => {
    weekAHSum += timeToFloat(aH.length)
  })

  allRelevantDays.forEach(day => {
    totalCalendarWorkSum += calculateDayTotalHours(state, day.id)
  })

  allRelevantAH.forEach(aH => {
    totalCalendarAHSum += timeToFloat(aH.length)
  })

  const allRelevantMeetings = Object.values(meetings).filter(meeting => 
      meeting.peopleIds.includes(selectedPerson)
    )
    
  let weekMeetingSum = 0
  let calendarMeetingSum = 0

  allRelevantMeetings.forEach(meeting => {
    if (meeting.weekNr === selectedWeekNr) {
      weekMeetingSum += timeToFloat(meeting.length)
      calendarMeetingSum += timeToFloat(meeting.length)
    } else {
      calendarMeetingSum += timeToFloat(meeting.length)
    }
  })

  const handleMouseEnter = (personId) => {
    setHoveredPersonId(personId);
  };

  const handleMouseLeave = () => {
    setHoveredPersonId(null);
  };

  const selectDepartment = (departmentId) => {
    dispatch({
      type: 'SELECT_DEPARTMENT',
      payload: { departmentId: departmentId},
      meta: { localOnly: true }
    });
  };

  const selectPerson = (personId) => {
    dispatch({
      type: 'SELECT_PERSON',
      payload: { personId: personId},
      meta: { localOnly: true }
    });
  }

  return (
    <div style={{ maxWidth:'1250px'}}>
      <div style={{display:'flex'}}>
        <div style={{marginLeft:'10px', marginRight:'10px', paddingRight:'30px', borderRight:'2px solid #c1c1c1'}}>
          <p style={{fontWeight:'400', fontSize:'30px'}}>{departments[selectedDepartment].name}</p>
          <div style={{marginTop:'10px'}}>
            {
              odv(people).filter(person => person.departmentId === selectedDepartment).map(person => {
                const peopleObjectsWithSameName = odv(people).filter(pers => pers.name === person.name)
                const selected = state.selectedPerson === person.id
                const color = selected ? '#fff' : ''
                const isHovered = hoveredPersonId === person.id
                
                return (
                  <div 
                    onMouseEnter={() => handleMouseEnter(person.id)}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => selectPerson(person.id)} 
                    style={{marginBottom:'10px', cursor: 'pointer'}}
                    >
                    <RoundedDiv padding='10px' layer={'three'} backgroundColor={selected ? (isHovered ? '#1c1c1c' : '#414141') : (isHovered ? '#f2f2f2' : '#fff')} style={{marginBottom:'0px', padding:'10px'}}>
                      <div style={{display:'flex'}}> {/* */}
                        <div style={{display: 'flex', alignItems: 'center', textAlign:'center', width:'200px', height:'50px', overflow:'auto'}}>
                          <p style={{color:color, fontSize:'19px', fontWeight:'500', margin:'0', padding:'0'}}>{person.name}</p>
                        </div>
                        
                      </div>
                    </RoundedDiv>
                  </div>
                  )
                }
              )
            }
          </div>
        </div>
        <div style={{marginLeft:'20px',}}>
          <p ><span style={{color:'rgb(53, 109, 255)', fontSize:'30px', fontWeight:'500'}}>{people[selectedPerson].name}</span>
            <span style={{fontSize:'30px', fontWeight:'500'}}>&nbsp;&nbsp;v.{state.selectedWeekNr}</span>
          </p>
          <WeekProvider>
            <div style={{height:'50px', display:'flex', justifyContent:'center'}}>
                <WeekPicker showAddRemove={false}></WeekPicker>
            </div>
          </WeekProvider>
          {/* <div style={{backgroundColor:'#efefef', borderRadius:'20px', padding:'10px'}}> */}
            <div style={{display:'flex', marginBottom:'20px'}}>
              <div style={{width:'100px'}}></div>
              <div style={{width:'200px', textAlign:'center'}}>
                <p style={{fontWeight:'500'}}>Total arbetstid</p>
              </div>
              <div style={{width:'200px', textAlign:'center'}}>
                <p style={{fontWeight:'500'}}>Barnfri tid</p>
              </div>
              <div style={{width:'200px', textAlign:'center'}}>
                <p style={{fontWeight:'500'}}>Möten</p>
              </div>
              <div style={{width:'300px', textAlign:'center'}}>
                <p style={{fontWeight:'500'}}>Avdelning(ar)</p>
              </div>
            </div>
            {
              relevantDays.map(day => {
                const peopleObjectsWithSameName = odv(people).filter(pers => pers.name === people[selectedPerson].name)
                {
                  // let length = getEffectiveWorkHour(state, day.id) //this just takes into account one person 
                  let length = calculateDayTotalHours(state, day.id) //this goes over all people with same name
                  length = length === 0 ? '' : `${length}h` 

                  let dayDepartments = []

                  peopleObjectsWithSameName.forEach(pO => {
                    const workHour = odv(workHours).find(wH => weeks[days[wH.dayId].weekId]?.personId === pO.id && wH.type === 'start' && days[wH.dayId].weekDay === day.weekDay && weeks[days[wH.dayId].weekId].weekNr === state.selectedWeekNr)
                    const workHourStart = workHour?.time 
                    const workHourEnd = odv(workHours).find(wH => weeks[days[wH.dayId].weekId]?.personId === pO.id && wH.type === 'end' && days[wH.dayId].weekDay === day.weekDay && weeks[days[wH.dayId].weekId].weekNr === state.selectedWeekNr)?.time
                    // const day = days[workHour?.dayId]

                    if (workHour && workHourStart && workHourEnd) {
                      dayDepartments.push(departments[people[weeks[days[workHour.dayId].weekId].personId].departmentId]) // yop
                    }
                  })


                  dayDepartments.forEach(department => {
                    const foundDay = odv(days).find(d => weeks[d.weekId].weekNr === selectedWeekNr 
                      && d.weekDay === day.weekDay 
                      && weeks[d.weekId].personId === selectedPerson
                      && departments[people[weeks[d.weekId].personId].departmentId].id === department.id)
                  })

                  const relevantMeetings = Object.values(meetings).filter(meeting => 
                    meeting.weekNr === selectedWeekNr &&
                    meeting.weekDay === day.weekDay
                    && meeting.peopleIds.includes(selectedPerson)
                    ) 
                  
                  let totalMeetingLength = 0

                  relevantMeetings.forEach(meeting => {
                    totalMeetingLength += timeToFloat(meeting.length)
                  })

                  return (
                    <div style={{marginBottom:'20px'}}>
                      <div style={{display:'flex'}}>
                      <div style={{width:'100px'}}>
                        <span >{day.weekDay}</span>
                      </div>
                      <div style={{width:'200px', textAlign:'center'}}>
                        <span>{length}</span>
                      </div>
                      <div style={{width:'200px', textAlign:'center'}}>
                        <span>{getDayAdditionalHourLength(state, day.id)}</span>
                      </div>
                      <div style={{width:'200px', textAlign:'center'}}>
                        {
                          totalMeetingLength ? <>{totalMeetingLength}h</> : <></>
                        }
                      </div>
                        {/* <span>{dayDepartments[0]}</span> */}
                      <div style={{width:'300px', textAlign:'center'}}>
                        {dayDepartments.map(department => (
                            <>
                              <span style={{marginRight:'10px', backgroundColor:'#eee', borderRadius:'20px', paddingTop:'6px', paddingBottom:'6px', paddingLeft:'10px', paddingRight:'10px'}}>{department.name}</span>
                            </>
                          //   <>
                          //     <span style={{marginRight:'10px', backgroundColor:'#eee', borderRadius:'20px', padding:'6px'}}>{department.name} {calculateDayTotalHoursActual(state, 
                          //       odv(days).find(d => weeks[d.weekId].weekNr === selectedWeekNr 
                          //                     && d.weekDay === day.weekDay 
                          // && weeks[d.weekId].personId === selectedPerson

                          //                     && departments[people[weeks[d.weekId].personId].departmentId].id === department.id)?.id
                          //     )}h</span>
                          //   </>
                        ))}
                      </div>

                      </div>
                    </div>
                  )
                }
              } 
              )
            }
              <div>
                <div style={{width:'700px', borderTop:'2px solid black', paddingTop:'15px', display:'flex'}}>
                  <div style={{width:'100px'}}>
                    <span style={{fontWeight:'500'}}>Summa v.</span>
                  </div>
                  <div style={{width:'200px', textAlign:'center'}}>
                    {
                      <span style={{fontWeight:'500'}}>{weekWorkSum}h&nbsp;&nbsp;&nbsp;{(100*(weekWorkSum/(40))).toFixed(2)}%</span>
                    }
                  </div>
                  <div style={{width:'200px', textAlign:'center'}}>
                    <span>{weekAHSum}h</span>
                  </div>
                  <div style={{width:'200px', textAlign:'center'}}>
                    <span>{weekMeetingSum}h</span>
                  </div>
                </div>

                <div style={{width:'700px', paddingTop:'15px', display:'flex'}}>
                  <div style={{width:'100px'}}>
                    <span style={{fontWeight:'500'}}>Summa kal.</span>
                  </div>
                  <div style={{width:'200px', textAlign:'center'}}>
                    <span style={{fontWeight:'500'}}>{totalCalendarWorkSum}h&nbsp;&nbsp;&nbsp;{(100*(totalCalendarWorkSum/(40*state.numberOfWeeks))).toFixed(2)}%</span>
                  </div>
                  <div style={{width:'200px', textAlign:'center'}}>
                    <span>{totalCalendarAHSum}h</span>
                  </div>
                  <div style={{width:'200px', textAlign:'center'}}>
                    <span>{calendarMeetingSum}h</span>
                  </div>
                </div>
              </div>

          {/* </div> */}
          <br></br>
          </div>
          
        </div>
    </div>
  )
}

export default PersonInfo