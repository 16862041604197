import React, { useState, useCallback } from 'react';
import { log } from '../utils/Utils';

const ModalLight = ({ isOpen, children, onClose }) => {
  const [isMouseDownInside, setIsMouseDownInside] = useState(false);

  const handleMouseDown = useCallback((e) => {
    // Check if the click started inside the modal content
    setIsMouseDownInside(e.target.closest('.modal-content') !== null);
  }, []);

  const handleMouseUp = useCallback((e) => {
    // Check if the click ended outside the modal content
    if (!isMouseDownInside && e.target.id === "backdrop") {
      onClose();
    }
    setIsMouseDownInside(false);

    log("isMouseDownInside is", isMouseDownInside);
  }, [isMouseDownInside, onClose]);

  if (!isOpen) return null;

  return (
    <div
      id="backdrop"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
      }}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      <div
        className="modal-content"
        style={{
          padding: '20px',
          backgroundColor: '#fff',
          borderRadius: '15px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          zIndex: 1001,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default ModalLight;
