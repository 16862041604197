import React, { useState, useRef, useEffect } from 'react';
import { useAppContext, initialStatee } from '../contexts/AppContext';
import inputStyles from '../styles/StyledInput.module.css';
import buttonStyles from '../styles/StyledButton.module.css';
import selectStyles from '../styles/StyledSelect.module.css';
import { log, odv } from '../utils/Utils';
import { CompressAndEncodeState, DecompressAndDecodeState, CompressState, DecompressState } from '../utils/Compressor';
import axios from 'axios';
// import { encode, decode } from '@msgpack/msgpack';
// import pako from 'pako';
import CryptoJS from 'crypto-js';
import { shortenContext } from '../utils/Compressor';
// import * as msgpack from 'msgpack-lite';
// import lzma from 'lzma/js';
// var my_lzma = require("lzma");
// var my_lzma = require("lzma").LZMA();
// import { compress, decompress } from 'compress-json'

import * as msgpack from 'msgpack-lite';
import pako from 'pako';
import { encode as base64Encode, decode as base64Decode } from 'base64-arraybuffer';
// import { compressAndEncodeState } from '../modal/SaveForm';
import LinkDisplay from '../assets/assets/link display/LinkDisplay';

export const sendStateToServer = async (state, userId, token) => {
  try {
    const result = await axios.post('https://eg2ch5owxsfpz66bxdoayeoswe0jqelt.lambda-url.eu-north-1.on.aws/', {
      body:'body',
      action: 'upload',
      userId: userId,
      token: token,
      data: CompressAndEncodeState(state)
    });
    if (result.data['success'] ===  true) {
      // setSuccess("success")
      // dispatch({ type: 'SET_LOGGEDIN', payload: {
      //   loggedIn: true
      // } })
    } else {
      // setSuccess("fail")

    }
  } catch (err) {
    log('Error calling Lambda function: ' + err.message);
    // setResponse(null);
  }
}

const PublicizeForm = () => {
  const { state, dispatch } = useAppContext();
  const [userId, setUserId] = useState(state.userId);
  const [finaluserId, setfinalUserId] = useState('-');
  const [finaltoken, setfinalToken] = useState('-');
  const userIdRef = useRef(null);
  const tokenRef = useRef(null);

  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')

  const [token, setToken] = useState('')
  const [success, setSuccess] = useState(false);


  const publicize = async (title, description) => {
    const data = CompressAndEncodeState(state)
    try {
      const result = await axios.post('https://l36v26m343jznahufyhbddwwya0afpee.lambda-url.eu-north-1.on.aws/', {
        body:'body',
        action: 'publicize',
        data: data,
        title: title,
        description: description,
      });
      // setResponse(result.data);
      // setError(null);

      if (result.data['success'] === true) {
        setToken(result.data['token']) 
        setSuccess(true) 
        // dispatch({ type: 'ADD_PUBLICIZED_LINK', payload: {
        //   publicizedLink: `http://localhost:3000/?schema=${result.data['token']}`
        // } })
      }

      
      //   setSuccess("success")
        
      // } else {
      //   setSuccess("fail")

    } catch (err) {
      log("error when publicizing: ", err.message)
      setError('Error calling Lambda function: ' + err.message);
      setResponse(null);
    }
  };

  return (
    <div>
      {
        state.loggedIn ? (
          (state.autosync === 'på') ? (
            <div>
            </div>
          ) : (
            <div>
            </div>
          )
        ) : (
          <div>
            <p style={{fontSize:'24px'}}>Publicera ditt schema</p>
            <br></br>
            <p>Genom att publicera schemat får du en länk där schemat ligger och är synligt för alla med länken.</p>
            <br/>
            <p>- Schemat går inte att ändra när det är publicerat</p>
            {/* <p>- Om du är inloggad kan du uppdatera det publicerade schemat. Annars får du en ny länk vid varje ny publicering.</p> */}
            <p>- Vem som helst kan redigera en egen kopia av det publicerade schemat.</p>
            {/* <br/> */}
            {/* <br/> */}
            <p>- Gör detta om du vill visa schemat till dina medarbetare eller för att spara schemat tillsvidare om du inte har en inlogg.</p>
            <br/>

            <div style={{marginBottom:'10px', marginTop:'10px', display:'flex', alignItems:'center'}}>
              <textarea
                ref={userIdRef}
                className={inputStyles.titleTextArea}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Titel (frivilligt)"
                style={{width:'300px', backgroundColor:'#e8e8e8'}}
              />
              {/* <p>{finaluserId}</p> */}
              {/* <button className={buttonStyles['button-30']} onClick={() => dispatch({ type: 'SET_USERID', payload: {
                userId: userIdRef.current.value
              } })}>Ok</button> */}

            </div>
            <div style={{marginBottom:'00px', marginTop:'10px', display:'flex', alignItems:'flex-end', justifyContent:'space-between'}}>
              <textarea
                ref={tokenRef}
                className={inputStyles.descriptionTextArea}
                value={description}
                onChange={(e) => setDescription(e.target.value) } //setToken(e.target.value)
                placeholder="Beskrivning (frivilligt)"
                style={{width:'300px', backgroundColor:'#e8e8e8'}}
              />
              <div style={{ display:'flex', justifyContent:'right'}}>
                <button style={{right:'0px', marginTop:'0px'}}className={buttonStyles['button-30']}  onClick={() => publicize(title, description)}>Publicera</button>
              </div>
              

            </div>

            {
              success === 'fail' && <>
                <p>Inloggning misslyckades</p>
              </>
            }
            {
              success === true && <>
              <div style={{display:'flex', alignItems:'center', marginTop:'10px'}}>
                <svg width="40" height="40" viewBox="0 0 24 24" id="_24x24_On_Light_Party" data-name="24x24/On Light/Party" xmlns="http://www.w3.org/2000/svg">
                  <rect id="view-box" width="24" height="24" fill="none"/>
                  <path id="Shape" d="M1.14,14.362A3.876,3.876,0,0,1,.592,9.546L6.338.353A.75.75,0,0,1,7.5.22L15.282,8a.749.749,0,0,1-.132,1.166L5.956,14.91a3.895,3.895,0,0,1-4.816-.548Zm.724-4.021a2.393,2.393,0,0,0,3.3,3.3l.892-.557L2.421,9.45Zm5.494,1.924,2.387-1.492L4.728,5.758,3.237,8.144Zm3.692-2.308,2.505-1.566L7.11,1.947,5.544,4.452Z" transform="translate(3.233 5.265)" fill="#141124"/>
                  <path id="Shape-2" data-name="Shape" d="M1.5,4.15V.75A.75.75,0,0,0,0,.75v3.4a.75.75,0,0,0,1.5,0Z" transform="translate(14.971 1.45)" fill="#03a5d1"/>
                  <path id="Shape-3" data-name="Shape" d="M1.16,3.114,3.82,1.378A.75.75,0,0,0,3,.122L.34,1.857a.75.75,0,1,0,.82,1.256Z" transform="translate(17.312 6.75)" fill="#00bba2"/>
                  <path id="Shape-4" data-name="Shape" d="M1.28,2.107l.827-.827A.75.75,0,0,0,1.047.22L.22,1.047A.75.75,0,0,0,1.28,2.107Z" transform="translate(17.971 3.798)" fill="#ff6783"/>
                  <path id="Shape-5" data-name="Shape" d="M.75,1.5H.967a.75.75,0,0,0,0-1.5H.75a.75.75,0,0,0,0,1.5Z" transform="translate(18.971 10.625)" fill="#ffa458"/>
                  <path id="Shape-6" data-name="Shape" d="M.75,1.5H.967a.75.75,0,0,0,0-1.5H.75a.75.75,0,0,0,0,1.5Z" transform="translate(14.971 7.625)" fill="#c75fff"/>
                  <path id="Shape-7" data-name="Shape" d="M1.881,1.478,1.439.453a.75.75,0,1,0-1.377.594L.5,2.073a.75.75,0,1,0,1.377-.594Z" transform="translate(11.528 2.599)" fill="#ffa458"/>
                </svg>
                <p style={{fontSize:'30px'}}>Publicering lyckades!</p>
              </div>
                <LinkDisplay link={`https://beundraforskola.se/?schema=${token}`}></LinkDisplay>
              </>
            }
            {/* {
              state.publicizedLinks ? <div style={{marginTop:'20px'}}>
                <p>Dina publicerade scheman: </p>
                {
                  odv(state.publicizedLinks).map(link => (
                    
                    <div>
                      {link}
                    </div>
                  ))
                } </div> : null
            } */}
            

          </div>

          
        )
      }


    </div>
  )
}

export default PublicizeForm