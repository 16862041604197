import React, { useEffect, useState } from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, Label
} from 'recharts';
import { useDepartmentContext } from '../contexts/DepartmentContext';
import styles from '../styles/StaffChart.module.css'
import { floatToTime, log, timeToFloat, weekDays, getRandomGoodLookingColor, floatToTimeWithZeroBefore } from '../utils/Utils';
import { calculateOverlap, calculateNewTimes } from '../utils/DataCalculator';
import { SynchronizedBarCharts } from './Charts';

const chart = (chartData, title) => {
  return (
    <div className={styles.chartContainer}>
          <ResponsiveContainer width={700} height={350} style={{backgroundColor:'#fff', borderRadius:'15px', paddingRight:'20px'}}>
            <BarChart
            data={chartData}
            margin={{ top: 5, right: 30, left: 0, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="time" />
              <YAxis domain={[0, getMaxStaff(chartData)]} allowDecimals={false} />
              <Tooltip />
              <Bar dataKey="personal" fill="#82ca9d" />
              <text x="50%" y="20" textAnchor="middle" dominantBaseline="central">
                <tspan fontSize="20">{title}</tspan> 
              </text>
            </BarChart>
          </ResponsiveContainer>
        </div>
  )
}

export const getMaxStaff = (data) => {
  return Math.max(...data.map(d => d.personal)) + 1;
};

export const generateIntervals = () => {
  const intervals = [];
  for (let hour = 6; hour <= 17; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      let hourStr = String(hour).padStart(2, '0'); // Ensures 2 digits for the hour
      let minuteStr = String(minute).padStart(2, '0'); // Ensures 2 digits for the minute
      intervals.push(`${hourStr}:${minuteStr}`);
    }
  }
  return intervals;
};

  

export const generateChartData = (state, intervals, weekDay, department) => {
  const {workHours, additionalHours, selectedWeekNr, days, weeks, people, selectedDay, breakHours, meetings} = state
  const data = intervals.map(time => ({ time, personal: 0 }));
  // const putData = intervals.map(time => ({ time, personal: 0 }));
  const personalIBarngruppData = intervals.map(time => ({ time, personal: 0 }));

  const relevantPeople = Object.values(people).filter(person => person.departmentId === department.id)

  let relevantWeekss = []
  relevantPeople.forEach(person => {
    relevantWeekss = relevantWeekss.concat(Object.values(weeks).filter(week => week.personId === person.id && week.weekNr === selectedWeekNr))
  })

  let relevantDays = []
  relevantWeekss.forEach(week => {
    relevantDays = relevantDays.concat(Object.values(days).filter(day => day.weekId === week.id && day.weekDay === weekDay))
  })

  relevantDays.forEach(day => {
    const startWorkHour = timeToFloat(Object.values(workHours).find(workHour => workHour.dayId === day.id && workHour.type === 'start')?.time)
    const endWorkHour = timeToFloat(Object.values(workHours).find(workHour => workHour.dayId === day.id && workHour.type === 'end')?.time)

    const startBreakHour = timeToFloat(Object.values(breakHours).find(breakHour => breakHour.dayId === day.id && breakHour.type === 'start')?.time)
    const lengthBreakHour = timeToFloat(Object.values(breakHours).find(breakHour => breakHour.dayId === day.id && breakHour.type === 'length')?.time)

    // const startPutHour = timeToFloat(Object.values(putHours).find(putHour => putHour.dayId === day.id && putHour.type === 'start')?.time)
    // const lengthPutHour = timeToFloat(Object.values(putHours).find(putHour => putHour.dayId === day.id && putHour.type === 'length')?.time)

    const personId = people[weeks[day.weekId].personId].id

    const relevantMeetings = Object.values(meetings).filter(meeting => 
      meeting.weekNr === weeks[day.weekId].weekNr &&
      meeting.weekDay === day.weekDay
       && meeting.peopleIds.includes(personId))

    const relevantAdditionalHours = Object.values(additionalHours).filter(aH => aH.dayId === day.id)

    const startAndEndTimes = calculateNewTimes(startWorkHour, endWorkHour, startBreakHour, lengthBreakHour, 0, 0) //00 is put hour relic

    // Go over the start and end times for the Arbetare i barngrupp graph
    startAndEndTimes.map(({start, end}) => {
      const staffFullfilledData = intervals.map(time => ({ time, personal: 0 }));


      // if (end >= 18){
      //   end = 17.75
      //   log("---------- fix end >= 18 -----------")
      // }

      const startTimeIndex = intervals.indexOf(floatToTimeWithZeroBefore(start)); // This makes sure that the format is "09:00" instead of "9:00" which gives an index of -1
      let endTimeIndex = 0
      intervals.indexOf(floatToTimeWithZeroBefore(end));

      if (end >= 18){
        // end = 17.75
        endTimeIndex = intervals.indexOf(floatToTimeWithZeroBefore(17.75));
        endTimeIndex++
      } else {
        endTimeIndex = intervals.indexOf(floatToTimeWithZeroBefore(end));
      }


      for (let i = startTimeIndex; i < endTimeIndex; i++) {
        if (staffFullfilledData[i]) staffFullfilledData[i].personal += 1;
      }

      if (relevantMeetings) {
        relevantMeetings.forEach(meeting => {
          const startTimeIndexx = intervals.indexOf(meeting.time);
          const endTimeIndexx = startTimeIndexx + Math.round((timeToFloat(meeting.length) * 60) / 15);

          for (let i = startTimeIndexx; i < endTimeIndexx; i++) {
            if (staffFullfilledData[i]) staffFullfilledData[i].personal -= 1;
          }

        })
      } 
      if (relevantMeetings) {
        relevantMeetings.forEach(meeting => {
          const startTimeIndexx = intervals.indexOf(meeting.time);
          const endTimeIndexx = startTimeIndexx + Math.round((timeToFloat(meeting.length) * 60) / 15);

          for (let i = startTimeIndexx; i < endTimeIndexx; i++) {
            if (personalIBarngruppData[i] > 1) personalIBarngruppData[i].personal -= 1;
          }

        })
      } 

      if (relevantAdditionalHours) {
        relevantAdditionalHours.forEach(ah => {
          const startTimeIndexx = intervals.indexOf(ah.start);
          const endTimeIndexx = startTimeIndexx + Math.round((timeToFloat(ah.length) * 60) / 15);

          for (let i = startTimeIndexx; i < endTimeIndexx; i++) {
            if (staffFullfilledData[i]) staffFullfilledData[i].personal -= 1;
          }

        })
      } 

      staffFullfilledData.map((value, index) => {
        if (staffFullfilledData[index].personal > 0) {
          personalIBarngruppData[index].personal += 1
        }
      })

    })

    // Go over the put hours for the put graph
    // if (startPutHour && lengthPutHour) {
    //   const startTimeIndex = intervals.indexOf(startPutHour.time);
    //   const length = timeToFloat(lengthPutHour.time)
    //   if (length) {
    //     const endTimeIndex = startTimeIndex + Math.round((length * 60) / 15); // Converting length to minutes and then to 15-minute intervals
    //       for (let i = startTimeIndex; i < endTimeIndex; i++) {
    //         if (putData[i]) putData[i].personal += 1;
    //       }
    //   }
    // }
  })

  const parseTimeLength = (timeLength) => {
    const [hours, minutes] = timeLength.split(':').map(Number);
    return hours + minutes / 60;
  };

  let relevantWeeks = [];
  
  Object.values(state.people).map(person => {
    Object.values(state.weeks).map(week => {
        if (week.personId === person.id && week.weekNr === state.selectedWeekNr) {
            relevantWeeks.push(week)
        }
    })
  })

  relevantWeeks.forEach(relevantWeek => {
    Object.values(workHours).forEach(({ time, dayId, type }) => {

        if (days[dayId].weekId === relevantWeek.id && days[dayId].weekDay === selectedDay && type === 'start') {
          const startTimeIndex = intervals.indexOf(time);
          const endTime = Object.values(workHours).find(({ dayId, type }) => dayId === dayId && type === 'end')?.time;
          if (endTime) {
            const endTimeIndex = intervals.indexOf(endTime);
            for (let i = startTimeIndex; i < endTimeIndex; i++) {
              if (data[i]) data[i].personal += 1;
            }
          }
        }
      });

    Object.values(breakHours).forEach(({ time, dayId, type }) => {
      if (days[dayId].weekId === relevantWeek.id && days[dayId].weekDay === selectedDay && type === 'start') {
        const startTimeIndex = intervals.indexOf(time);
        const lengthStr = Object.values(breakHours).find(({ dayId, type}) => dayId === dayId && type === 'length')?.time;
        const length = lengthStr ? parseTimeLength(lengthStr) : 0;
        if (length) {
          const endTimeIndex = startTimeIndex + Math.round((length * 60) / 15); // Converting length to minutes and then to 15-minute intervals
          for (let i = startTimeIndex; i < endTimeIndex; i++) {
            if (data[i]) data[i].personal -= 1;
          }
        }
      }
    });
    
    // Object.values(putHours).forEach(({ time, dayId, type }) => {
    //   if (days[dayId].weekId === relevantWeek.id && days[dayId].weekDay === selectedDay && type === 'start') {
    //     const startTimeIndex = intervals.indexOf(time);
    //     const lengthStr = Object.values(putHours).find(({ dayId: dId, type: t }) => dId === dayId && t === 'length')?.time;
    //     const length = lengthStr ? parseTimeLength(lengthStr) : 0;
    //     if (length) {
    //       const endTimeIndex = startTimeIndex + Math.round((length * 60) / 15); // Converting length to minutes and then to 15-minute intervals
    //       for (let i = startTimeIndex; i < endTimeIndex; i++) {
    //         if (data[i]) data[i].personal -= 1;
    //       }
    //     }
    //   }
    // });
  })
  return {data, hej: "hej", personalIBarngruppData};
};

export function generateDataWrapper ( state, department ) {
  const { days, workHours, selectedWeekId, breakHours, putHours, meetings, additionalHours } = state;
  const intervals = generateIntervals();
  const selectedDay = 'Måndag'
  return generateChartData(state, intervals, selectedDay, department).personalIBarngruppData
  // setPersonalIBarngruppData(generateChartData(state, intervals, selectedDay, department).personalIBarngruppData);
  // return personalIBarngruppData
}

export const StaffChart = ( {department} ) => {
  const { state, dispatch } = useDepartmentContext();
  const { days, workHours, selectedDay, selectedWeekId, breakHours, putHours, meetings, additionalHours, departments } = state;
  const intervals = generateIntervals();
  // const [workData, setWorkData] = useState(generateChartData(state, intervals, selectedDay).data);
  // const [adjustedData, setAdjustedData] = useState(generateChartData(state, intervals, selectedDay).data);
  // const [putData, setPutData] = useState(generateChartData(state, intervals, selectedDay).putData);
  // const [personalIBarngruppData, setPersonalIBarngruppData] = useState(generateChartData(state, intervals, selectedDay, department).personalIBarngruppData);

  const [newChartData, setNewChartData] = useState([])

  useEffect(() => {
    // setWorkData(generateChartData(state, intervals, selectedDay).data);
    // setAdjustedData(generateChartData(state, intervals, selectedDay).data);
    // setPutData(generateChartData(state, intervals, selectedDay).putData);
    // setPersonalIBarngruppData(generateChartData(state, intervals, selectedDay, department).personalIBarngruppData);

    let newChartData = [];

    Object.values(state.departments).map(dept => (
      newChartData.push({title: dept.name, color: dept.color, chartData: generateChartData(state, intervals, selectedDay, dept).personalIBarngruppData})
    
    ))
    setNewChartData(newChartData);

  }, [workHours, selectedWeekId, breakHours, putHours, meetings, additionalHours, selectedDay, departments]);

  const handleDayChange = (day) => {
    dispatch({
      type: 'SELECT_DAY',
      payload: { day: day},
      meta: { localOnly: true }
    });
    // setSelectedDay(day);
  };

  

  // Object.values(state.departments).map(dept => (
  //   newChartData.push({title: dept.name, color: getRandomGoodLookingColor(), chartData: generateChartData(state, intervals, selectedDay, dept).personalIBarngruppData})
  
  // ))

  // log("new new char dahar", newChartData)

  return (
    <div className={styles.container}>
      <p style={{fontSize:'24px'}}>Personal i barngrupp</p>
      <div className={styles.dayPicker}>
        {/* <label htmlFor="day-picker">Select Day: </label> */}
        {['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag'].map(day => (
          <button
            className={styles.styledButton}
            key={day}
            onClick={() => handleDayChange(day)}
            style={{ backgroundColor: selectedDay === day ? '#30c01a' : 'rgb(53, 109, 255)', color: 'fff'}}
          >
            {day}
          </button>
        ))}
      </div>
      <div style={{maxWidth:'1600px'}} className={styles.chartsContainer}>
        <SynchronizedBarCharts chartsData={newChartData} />
        {/* {chart(workData, "Personal på jobbet")}
        {chart(adjustedData, "Personal i barngrupp")}
        {chart(putData, "PUT")} */}
        {/* {chart(personalIBarngruppData, "Personal i barngrupp")} */}
      </div>
    </div>
  );

};

export default StaffChart;
