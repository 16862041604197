import { timeToFloat, log, odv, calculateDayTotalHours, findPeopleWithSameName, calculateTimeOutside } from '../utils/Utils';

export const calculateWorkAndPutHours = (days, workHours, breakHours, putHours, meetings, peopleIds) => {
    let totalWorkHours = 0;
    let totalPutHours = 0;
    let totalMeetingHours = 0;

    days.forEach(day => {
        const dayId = day.id;
        const workHourStart = timeToFloat(Object.values(workHours).find(workHour => workHour.dayId === dayId && workHour.type === 'start')?.time);
        const workHourEnd = timeToFloat(Object.values(workHours).find(workHour => workHour.dayId === dayId && workHour.type === 'end')?.time);
        const breakStart = timeToFloat(Object.values(breakHours).find(breakHour => breakHour.dayId === dayId && breakHour.type === 'start')?.time || '00:00');
        const breakLength = timeToFloat(Object.values(breakHours).find(breakHour => breakHour.dayId === dayId && breakHour.type === 'length')?.time || '00:00');
        const putHourStart = timeToFloat(Object.values(putHours).find(putHour => putHour.dayId === dayId && putHour.type === 'start')?.time || '00:00');
        const putHourLength = timeToFloat(Object.values(putHours).find(putHour => putHour.dayId === dayId && putHour.type === 'length')?.time || '00:00');

        let dayWorkHours = workHourEnd - workHourStart;
        let effectiveBreakLength = 0;
        let effectivePutHours = 0;

        // Calculate effective break length
        if (breakStart && breakLength && breakStart >= workHourStart && breakStart <= workHourEnd) {
            effectiveBreakLength = Math.min(breakLength, workHourEnd - breakStart);
        }

        // Calculate effective put hours
        if (putHourStart && putHourLength && (putHourStart < workHourStart || putHourStart >= workHourEnd)) {
            effectivePutHours += putHourLength;
        }

        totalWorkHours += dayWorkHours - effectiveBreakLength;
        totalPutHours += effectivePutHours;

        // Calculate meeting hours
        Object.values(meetings).forEach(meeting => {
            if (meeting.dayId === dayId && peopleIds.includes(meeting.peopleIds)) {
                const meetingStart = timeToFloat(meeting.time);
                const meetingLength = timeToFloat(meeting.length);
                const meetingEnd = meetingStart + meetingLength;

                if (meetingStart >= workHourStart && meetingEnd <= workHourEnd) {
                    // Meeting within work hours, count it if not overlapping with breaks or put hours
                    if (!(meetingStart >= breakStart && meetingStart < breakStart + breakLength) &&
                        !(meetingStart >= putHourStart && meetingStart < putHourStart + putHourLength)) {
                        totalMeetingHours += meetingLength;
                    }
                } else if (meetingEnd <= workHourStart || meetingStart >= workHourEnd) {
                    // Meeting outside work hours
                    totalMeetingHours += meetingLength;
                }
            }
        });
    });

    return { totalWorkHours: totalWorkHours + totalMeetingHours, totalPutHours };
};


export const getWeekTotalHours = (state, week) => { // This calculates for all people with the same name 
    const relevantDays = odv(state.days).filter(day => day.weekId === week.id)

    let sum = 0

    relevantDays.forEach(day => {sum += calculateDayTotalHours(state, day.id)})

    log ("found sum: ", sum)
    return sum
}

export const getIndividualStatistics = (state, week) => { // This calculates for all people with the same name 
    const { meetings, days, weeks, people, workHours, breakHours, additionalHours } = state


    const peopleObjectsWithSameName = findPeopleWithSameName(state, state.people[week.personId])
    const relevantDays = odv(days).filter(day => weeks[day.weekId].weekNr === week.weekNr && odv(peopleObjectsWithSameName).includes(people[weeks[day.weekId].personId]))


    let workHoursSum = 0
    let meetingHoursSum = 0
    let AHdict = {} // strucuted like {'PUT': 0, 'LLT': 0}


    relevantDays.forEach(day => {

        const week = weeks[day.weekId]
        let workHourStart = odv(workHours).find(wh => wh.dayId === day.id && wh.type === 'start')?.time
        let workHourEnd = odv(workHours).find(wh => wh.dayId === day.id && wh.type === 'end')?.time

        if (!(workHourStart && workHourEnd)) {
            // If there is no work hours placed that day, still do calculation for potential meetings
            // If no work hours also dont calculate AH sum 
            const relevantMeetings = odv(meetings).filter(meeting => 
                meeting.weekDay === day.weekDay 
                && meeting.weekNr === week.weekNr
                && meeting.peopleIds.includes(week.personId)
            )
        
            relevantMeetings.forEach(meeting => {
                // This does the crucial assumption that the same person can not be in two different different meetings at the same time
                meetingHoursSum += timeToFloat(meeting.length)
                workHoursSum += calculateTimeOutside(0, 0, timeToFloat(meeting.time), timeToFloat(meeting.length))
            })

            
        } else {

            workHourStart = timeToFloat(workHourStart)
            workHourEnd = timeToFloat(workHourEnd)


            // const breakHourStart = odv(breakHours).find(bh => bh.dayId === day.id && bh.type === 'start')?.time
            const breakHourLength = odv(breakHours).find(bh => bh.dayId === day.id && bh.type === 'length')?.time

            // let totalHours = 0

            const workHourLength = workHourEnd-workHourStart

            workHoursSum += workHourLength

            // This does the assumption that breakhours are always within work hours which they should be
            
            if (breakHourLength) {workHoursSum -= timeToFloat(breakHourLength)}
            
            const relevantMeetings = odv(meetings).filter(meeting => 
                meeting.weekDay === day.weekDay 
                && meeting.weekNr === week.weekNr
                && meeting.peopleIds.includes(week.personId)
            )

            const relevantAH = odv(additionalHours).filter(aH => 
                aH.dayId === day.id
            )
            
            relevantMeetings.forEach(meeting => {
                // This does the crucial assumption that the same person can not be in two different different meetings at the same time
                meetingHoursSum += timeToFloat(meeting.length)

                // I think: take meeting time outside of work hours and add it since its not being calculcated from the work hours
                workHoursSum += calculateTimeOutside(workHourStart, workHourEnd, timeToFloat(meeting.time), timeToFloat(meeting.length))

            })

            relevantAH.forEach(aH => {
                // This also does the asusmption that a person does not have multiple AH at the same time
                if (AHdict[aH.category]) {
                    AHdict[aH.category] += timeToFloat(aH.length)
                } else {
                    AHdict[aH.category] = timeToFloat(aH.length)
                }
            })
        
        }
    })

    // workHoursSum += calculateDayTotalHours(state, day.id);
    //     meetingHoursSum += calculateDayTotalHours(state, day.id);
    //     aHHoursSum += calculateDayTotalHours(state, day.id);


    return {workHoursSum, meetingHoursSum, AHdict}
}

