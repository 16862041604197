// App.js
import React, { useState, useReducer, useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate  } from 'react-router-dom';
// import DepartmentPicker from './department/DepartmentPicker';
// import Department from './department/Department';
import { DepartmentProvider } from './contexts/DepartmentContext';
import { useAppContext, AppProvider } from './contexts/AppContext';
// import StaffChart from './charts/StaffChart';
import styles from './styles/App.module.css';
// import WeekSummation from './summations/WeekSummation';
// import CalendarSummation from './summations/CalendarSummation';
// import { WeekProvider } from './contexts/WeekContext';
// import PeoplePicker from './people/PeoplePicker';
// import ExcelExport from './excel/ExportComponent';
// import ExcelExport from './excel/ExportComponent2';
// import RoundedDiv from './assets/assets/rounded div/RoundedDiv';
// import ChartViewer from './charts/ChartViewer';
// import DepartmentOverview from './overview/DepartmentOverview';
// import PersonOverview from './people/PeopleOverview';
// import PersonViewer from './people/PersonViewer';
// import Modal from './modal/Modal';
import AddPersonForm from './modal/AddPersonForm';
import AddDepartmentForm from './modal/AddDepartmentForm';
// import ExportForm from './modal/ExportForm';
import ExportExcel from './modal/ExportExcel';
import HelpForm from './modal/HelpForm';
// import buttonStyles from './styles/StyledButton.module.css';
import RemoveMeetingForm from './modal/RemoveMeetingForm';
import RemoveAdditionalHourForm from './modal/RemoveAdditionalHourForm';
import InfoForm from './modal/InfoForm';
import SaveForm from './modal/SaveForm';
import RemoveDataForm from './modal/RemoveDataForm';
import LoginForm from './modal/LoginForm';
import ModalLight from './modal/ModalLight';
import PersonInfo from './modal/PersonInfo';
import DepartmentInfo from './modal/DepartmentInfo';
import { DepartmentIsland } from './visualOverhaul/DepartmentIsland';
import { LeftIsland } from './visualOverhaul/LeftIsland';
import RightIsland from './visualOverhaul/RightIsland';
import PersonSettings from './modal/PersonSettings';
import AddMeetingAH from './modal/AddMeetingAH';
import MeetingAndAHPopup from './visualOverhaul/MeetingAndAHPopup';
import Statistics from './statistics/Statistics';
import RemovePersonForm from './modal/RemovePersonForm';
import WorkHourEditor from './statistics/WorkHourEditor';
import BurgerMenu from './assets/assets/burger menu/BurgerMenu';
import axios from 'axios';
import { DecompressAndDecodeState } from './utils/Compressor';
import { log, closeTopModal } from './utils/Utils';
import { PublicizeApp } from './publicize/PublicizeApp';
import PublicizeForm from './publicize/PublicizeForm';
import { AllCharts } from './statistics/AllCharts';
import { generateChartDataFromState } from './statistics/AllCharts';
import RemoveDepartmentForm from './modal/RemoveDepartmentForm';
import EditDepartmentNameForm from './modal/EditDepartmentNameForm';
import EditPersonNameForm from './modal/EditPersonNameForm';
import { MeetingAndAHAndWH } from './statistics/MeetingAndAHAndWH';
import { CategoryViewer } from './modal/CategoryViewer';
import buttonStyles from './styles/StyledButton.module.css';
import inputStyles from './styles/StyledInput.module.css';
import { FilterIsland } from './VisualOverhaul2/FilterIsland';
import { MainIsland } from './VisualOverhaul2/MainIsland';
import { ScheduleSettings } from './VisualOverhaul2/ScheduleSettings';

const PasswordGate = ({ onAuthenticate }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const response = await axios.post(
        'https://uv6dpfv33gjhadpte4xp4q4jyi0peygk.lambda-url.eu-north-1.on.aws/',
        { password: password }
      );

      const responseData = response.data;
      
      if (responseData.authenticated) {
        onAuthenticate(true);
        localStorage.setItem('isAuthenticated', 'true');
      } else {
        setError('Fel lösenord');
      }
    } catch (error) {
      console.error('Auth error:', error);
      setError('Kunde inte ansluta till servern. Försök igen.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#f5f5f5'
    }}>
      <div style={{
        padding: '2rem',
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        width: '300px'
      }}>
        <h2 style={{ textAlign: 'center', marginBottom: '1.5rem' }}>Beundra Förskola</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Ange lösenord"
            className={inputStyles['input-30']}
            style={{ width: '100%', marginBottom: '1rem' }}
          />
          {error && <p style={{ color: 'red', marginBottom: '1rem' }}>{error}</p>}
          <button 
            type="submit" 
            className={buttonStyles['button-30']}
            style={{ width: '100%' }}
            disabled={isLoading}
          >
            {isLoading ? 'Verifierar...' : 'Logga in'}
          </button>
        </form>
      </div>
    </div>
  );
};

const App = () => {
  const { state, dispatch } = useAppContext();
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    // Check if user is already authenticated
    return localStorage.getItem('isAuthenticated') === 'true';
  });

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('schema');

  const chartData = useMemo(() => {
    return generateChartDataFromState(state);
  }, [state.workHours, state.selectedWeekId, state.breakHours, state.meetings, state.additionalHours, state.selectedDay, state.departments]);

  // This might as well have been in the state but dont wanna spend time on it or overpupulate the state
  const [meetingAndAHAndWHMetaInfo, setMeetingAndAHAndWHMetaInfo ] = useState([])

  const [categoryValue, setCategoryValue] = useState('')

  const removeCategoryActual = () => {
    const newCategories = state.categories.filter(category => category !== state.categoryToRemove)
    dispatch({
      type: 'SET_CATEGORIES',
      payload: {
        categories: newCategories,
      }
    })
    // setShowRemoveCategory(false)
    dispatch({ type: 'TOGGLE_SHOW_REMOVE_CATEGORY' })
  }

   const addCategory = () => {
    const newCategories = [...state.categories, categoryValue]
    dispatch({
      type: 'SET_CATEGORIES',
      payload: {
        categories: newCategories,
      }
    })
    // setShowAddCategory(false)
    dispatch({ type: 'TOGGLE_SHOW_ADD_CATEGORY' })
    setCategoryValue('')
  }

  const inputRef = useRef(null);
  useEffect(() => {
    if (state.showAddCategory && inputRef.current) {
      inputRef.current.focus();
    }
  }, [state.showAddCategory]);

  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter') {
      addCategory()
    }
  }; 

  // useEffect(() => {
  //   if (token) {
  //     console.log("TOKEN IS: ", token);

  //     // Load the schedule based on the token
  //     // fetchSchedule(token);
  //     console.log("fetching schedule... ")
  //     // const fetchData = async () => {getAndSetData(dispatch, token)}
  //     // fetchData()
  //     log("getted and setted state: ", state)


  //     // Once the schedule is loaded, you can remove the token from the URL
  //     queryParams.delete('token');
  //     navigate(`${location.pathname}`, { replace: true }); // This will remove the query parameter from the URL
  //     console.log("succesesfully setted state i think, ", state)
  //   }
  // }, [token]);


  useEffect(() => {
    // function handleKeyPress(event) {
    //   // Check if 'A' is pressed and the target is not an input or textarea
    //   if (event.key === 'a' && event.target.tagName !== 'INPUT' && event.target.tagName !== 'TEXTAREA') {
    //     dispatch({ type: 'TOGGLE_ADD_DEPARTMENT', payload: {
    //       setToShow: true
    //     } })
    //   } else if (event.key === 'p' && event.target.tagName !== 'INPUT' && event.target.tagName !== 'TEXTAREA') {
    //     dispatch({ type: 'TOGGLE_ADD_PERSON', payload: {
    //       setToShow: true
    //     } })
    //   } else if (event.key === 'v' && event.target.tagName !== 'INPUT' && event.target.tagName !== 'TEXTAREA') {
    //     dispatch({ type: 'SELECT_NEXT_WEEK', payload: {backwards: event.shiftKey}})
    //   } else if (event.key === 'd' && event.target.tagName !== 'INPUT' && event.target.tagName !== 'TEXTAREA') {
    //     dispatch({ type: 'SELECT_NEXT_DAY', payload: {backwards: event.shiftKey}})
    //   } else if (event.key === 'Escape') {
    //     dispatch({ type: 'TOGGLE_ADD_DEPARTMENT', payload: {
    //       setToShow: false
    //     } })
    //     dispatch({ type: 'TOGGLE_ADD_PERSON', payload: {
    //       setToShow: false
    //     } })
    //   }
    // }

    function handleKeyPress(event) {
    
      const targetNotInputOrTextarea = event.target.tagName !== 'INPUT' && event.target.tagName !== 'TEXTAREA';
      
      const key = event.key;
      if (key === 'Escape') {
        log("closign top modal..")
        closeTopModal(state, dispatch)
      }

      if (targetNotInputOrTextarea) {
        const key = event.key.toLowerCase();

        // if (key === 'Escape') {
        //   log("yoyobitches")
        //   dispatch({ type: 'TOGGLE_ADD_DEPARTMENT', payload: { setToShow: false } });
        //     dispatch({ type: 'TOGGLE_ADD_PERSON', payload: { setToShow: false } });
        // }
        switch (key) {
          case 'a':
            dispatch({ type: 'TOGGLE_ADD_DEPARTMENT', payload: { setToShow: true } });
            break;
          case 'p':
            dispatch({ type: 'TOGGLE_ADD_PERSON', payload: { setToShow: true } });
            break;
          case 'v':
            dispatch({ type: 'SELECT_NEXT_WEEK', payload: { backwards: event.shiftKey } });
            break;
          case 'd':
            dispatch({ type: 'SELECT_NEXT_DAY', payload: { backwards: event.shiftKey } });
            break;
          case 'Escape':
            dispatch({ type: 'TOGGLE_ADD_DEPARTMENT', payload: { setToShow: false } });
            dispatch({ type: 'TOGGLE_ADD_PERSON', payload: { setToShow: false } });
            break;
          default:
            break;
        }
      } else {
      }
    }
    

    // Add event listener
    window.addEventListener('keydown', handleKeyPress);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [state]);

  return (
    <div>
      {!isAuthenticated ? (
        <PasswordGate onAuthenticate={setIsAuthenticated} />
      ) : (
        token ? (
          <div>
            <PublicizeApp token={token}></PublicizeApp>
          </div>
        ) : (
          <div className={styles.app}>
            <BurgerMenu></BurgerMenu>
            <DepartmentProvider>
              <ModalLight isOpen={state.showAllCharts} onClose={() => dispatch({ type: 'TOGGLE_SHOW_ALL_CHARTS' })}>
                <AllCharts chartData={chartData} />
              </ModalLight>
              <ModalLight isOpen={state.showPublicize} onClose={() => dispatch({ type: 'TOGGLE_SHOW_PUBLICIZE' })}>
                <PublicizeForm />
              </ModalLight>
              <ModalLight isOpen={state.showStatistics} onClose={() => dispatch({ type: 'TOGGLE_SHOW_STATISTICS' })}>
                <Statistics meetingAndAHAndWHMetaInfo={meetingAndAHAndWHMetaInfo} setMeetingAndAHAndWHMetaInfo={setMeetingAndAHAndWHMetaInfo}/> 
              </ModalLight>
              

               <ModalLight isOpen={state.showMeetingAndAHAndWH} onClose={() => dispatch({ type: 'TOGGLE_SHOW_MEETINGANDAHANDWH' })}>
                    <MeetingAndAHAndWH 
                      metainfo={meetingAndAHAndWHMetaInfo}>
                    </MeetingAndAHAndWH>
              </ModalLight>
              <ModalLight isOpen={state.showAddMeetingAH} onClose={() => dispatch({ type: 'TOGGLE_SHOW_ADD_MEETINGAH' })}>
                <AddMeetingAH></AddMeetingAH>
              </ModalLight>
              <ModalLight isOpen={state.showExport} onClose={() => dispatch({ type: 'TOGGLE_SHOW_EXPORT' })}>
                {/* <ExportForm /> */}
                <ExportExcel></ExportExcel>
              </ModalLight>
              <ModalLight isOpen={state.showHelp} onClose={() => dispatch({ type: 'TOGGLE_SHOW_HELP' })}>
                <HelpForm />
              </ModalLight>
              <ModalLight isOpen={state.showInfo} onClose={() => dispatch({ type: 'TOGGLE_SHOW_INFO' })}>
                <InfoForm />
              </ModalLight>
              <ModalLight isOpen={state.showSave} onClose={() => dispatch({ type: 'TOGGLE_SHOW_SAVE' })}>
                <SaveForm />
              </ModalLight>
              <ModalLight isOpen={state.showLogin} onClose={() => dispatch({ type: 'TOGGLE_SHOW_LOGIN' })}>
                <LoginForm></LoginForm>
              </ModalLight>
              <ModalLight isOpen={state.showRemoveData} onClose={() => dispatch({ type: 'TOGGLE_SHOW_REMOVE_DATA' })}>
                <RemoveDataForm />
              </ModalLight>
              <ModalLight isOpen={state.showRemoveDepartment} onClose={() => dispatch({ type: 'TOGGLE_SHOW_REMOVE_DEPARTMENT' })}>
                <RemoveDepartmentForm />
              </ModalLight>
              <ModalLight isOpen={state.showEditDepartmentName} onClose={() => dispatch({ type: 'TOGGLE_SHOW_EDIT_DEPARTMENT_NAME' })}>
                <EditDepartmentNameForm />
              </ModalLight>
              
              
              <ModalLight isOpen={state.showPersonInfo} onClose={() => dispatch({ type: 'TOGGLE_SHOW_PERSON_INFO' })}>
                <PersonInfo></PersonInfo>
              </ModalLight>
              <ModalLight isOpen={state.showPersonSettings} onClose={() => dispatch({ type: 'TOGGLE_SHOW_PERSON_SETTINGS' })}>
                <PersonSettings></PersonSettings>
              </ModalLight>
              <ModalLight isOpen={state.showDepartmentInfo} onClose={() => dispatch({ type: 'TOGGLE_SHOW_DEPARTMENT_INFO' })}>
                <DepartmentInfo></DepartmentInfo>
              </ModalLight>
              
              <ModalLight isOpen={state.showMeetingAHPopup} onClose={() => dispatch({ type: 'TOGGLE_SHOW_MEETINGAH_POPUP' })}>
                <MeetingAndAHPopup></MeetingAndAHPopup>
              </ModalLight>
              <ModalLight isOpen={state.showRemoveMeeting} onClose={() => dispatch({ type: 'TOGGLE_SHOW_REMOVE_MEETING' })}>
                <RemoveMeetingForm />
              </ModalLight>
              <ModalLight isOpen={state.showRemoveAdditionalHour} onClose={() => dispatch({ type: 'TOGGLE_SHOW_REMOVE_ADDITIONAL_HOUR' })}>
                <RemoveAdditionalHourForm />
              </ModalLight>
              <ModalLight isOpen={state.showRemovePerson} onClose={() => dispatch({ type: 'TOGGLE_SHOW_REMOVE_PERSON' })}>
                <RemovePersonForm />
              </ModalLight>
              <ModalLight isOpen={state.showWorkHourEditor} onClose={() => dispatch({ type: 'TOGGLE_SHOW_WORKHOUR_EDITOR' })}>
                <WorkHourEditor />
              </ModalLight>

              <ModalLight isOpen={state.showEditPersonName} onClose={() => dispatch({ type: 'TOGGLE_SHOW_EDIT_PERSON_NAME' })}>
                <EditPersonNameForm />
              </ModalLight>

             

              <ModalLight isOpen={state.showEditCategories} onClose={() => dispatch({ type: 'TOGGLE_SHOW_EDIT_CATEGORIES' })}>
                <CategoryViewer></CategoryViewer>
              </ModalLight>

              <ModalLight isOpen={state.showRemoveCategory} onClose={() => dispatch({ type: 'TOGGLE_SHOW_REMOVE_CATEGORY' })}>
                <p style={{fontSize:'24px'}}>Är du säker att du vill ta bort kategorin {state.categoryToRemove}?</p>
                <div style={{display:'flex', justifyContent:'right', marginTop:'10px'}}>
                  <button className={buttonStyles['secondaryButton']} onClick={() => dispatch({ type: 'TOGGLE_SHOW_REMOVE_CATEGORY' })}>Avbryt</button>
                  <button className={buttonStyles['button-30']} onClick={removeCategoryActual}>Ta bort</button>
                </div>
              </ModalLight>
              <ModalLight isOpen={state.showAddCategory} onClose={() => dispatch({ type: 'TOGGLE_SHOW_ADD_CATEGORY' })}>
                <p style={{fontSize:'24px'}}>Skapa ny kategori</p>
                <div style={{display:'flex', alignItems:'center', marginTop:'10px'}}>
                  <p style={{'whiteSpace': 'nowrap', marginRight:'10px'}}>Namn</p>
                  <input
                    ref={inputRef}
                    className={inputStyles['input-30']}
                    style={{width:'100px'}}
                    value={categoryValue}
                    onChange={(e) => setCategoryValue(e.target.value)}
                    onKeyDown={(e) => handleInputKeyDown(e)}
                    placeholder="Kategori"
                  />
                </div>

                <div style={{display:'flex', justifyContent:'right', marginTop:'10px'}}>
                  <button className={buttonStyles['secondaryButton']} onClick={() => dispatch({ type: 'TOGGLE_SHOW_ADD_CATEGORY' })}>Avbryt</button>
                  <button className={buttonStyles['button-30']} onClick={addCategory}>Skapa</button>
                </div>
              </ModalLight>


              <ModalLight isOpen={state.showScheduleSettings} onClose={() => dispatch({ type: 'TOGGLE_SHOW_SCHEDULE_SETTINGS' })}>
                <ScheduleSettings />
              </ModalLight>

              <ModalLight isOpen={state.showAddPerson} onClose={() => dispatch({ type: 'TOGGLE_ADD_PERSON' })}>
                <AddPersonForm />
              </ModalLight>
              <ModalLight isOpen={state.showAddDepartment} onClose={() => dispatch({ type: 'TOGGLE_ADD_DEPARTMENT' })}>
                <AddDepartmentForm />
              </ModalLight>



              {/* <div className={styles.mainContent}> */}
              <div style={{display:'flex',  width:'100%', marginBottom:'20px',}}>
                {/* <div className={styles.leftContent}>
                  <RoundedDiv layer={'one'}>
                    <DepartmentPicker/>
                    <PeoplePicker />
                  </RoundedDiv>
                </div> */}
                <div style={{ }}>
                  <p style={{fontSize:'30px', fontWeight:'400', textAlign:'center', color:'#222' }}>Beundra Förskola</p>
                  {/* <p style={{fontSize:'20px', textAlign:'center'}}>{state.people[state.selectedPerson]?.name}</p> */}
                </div>
                <div style={{position:'fixed', left:'50%', transform:'translateX(-50%)', display:'flex', justifyContent:'center', zIndex:'100'}}>
                  {/* <DepartmentIsland></DepartmentIsland> */}
                  <FilterIsland></FilterIsland>
                </div>
                {/* <div className={styles.rightContent}>
                  <RoundedDiv layer={'one'} className={styles.topRightRoundedDiv} >
                    <button className={buttonStyles['button-30']} 
                      style={{marginRight:'0px'}}
                      onClick={() => dispatch({ type: 'TOGGLE_SHOW_INFO', payload: {
                          setToShow: true
                        } })}>Hur gör man?</button>
                    <button className={buttonStyles['button-30']}
                    style={{marginRight:'0px'}}
                    onClick={() => dispatch({ type: 'TOGGLE_SHOW_HELP', payload: {
                        setToShow: true
                      } })}>Hjälp</button>
                    <button className={buttonStyles['button-30']} 
                    style={{marginRight:'0px'}}
                    onClick={() => dispatch({ type: 'TOGGLE_SHOW_EXPORT', payload: {
                        setToShow: true
                      } })}>Export</button>
                    <button className={buttonStyles['button-30']} 
                    style={{marginRight:'0px'}}
                    onClick={() => dispatch({ type: 'TOGGLE_SHOW_SAVE', payload: {
                        setToShow: true
                      } })}>Spara/Ladda upp</button>
                      <button className={buttonStyles['button-30']} 
                    style={{marginRight:'0px'}}
                    onClick={() => dispatch({ type: 'TOGGLE_SHOW_LOGIN', payload: {
                        setToShow: true
                      } })}>{state.loggedIn === true ? <span>Inloggad</span> : <span>Logga in</span>}</button>
                    
                  </RoundedDiv>
                </div> */}
              </div>
                {/* {state.selectedDepartment && <Department />} */}
              <div style={{display:'flex', height:'80vh', width:'95vw', justifyContent:'center', alignItems:'center'}}>
              {/* height:'80vh', width:'95vw', */}
                <MainIsland></MainIsland>
                {/* <div style={{marginRight:'20px'}}>
                  <LeftIsland></LeftIsland>
                </div>
                <div style={{zIndex:'0', marginTop:'50px'}}>
                  <RightIsland></RightIsland>
                </div> */}
              </div>
              {/* <div style={{zIndex:'-1'}} className={styles.centeredContent}>
                <div>
                  <div style={{display:'flex'}}>
                    <button>Personal i barngrupp</button>
                    <button>Överblick avdelningar</button>
                  </div>
                </div>
                <RoundedDiv style={{zIndex:'-1', marginBottom:'100px'}} layer={'one'} backgroundColor='#fff' className={styles.graphRoundedDiv}>
                  <StaffChart style={{zIndex:'-1'}} department={state.departments[state.selectedDepartment]}/>
                </RoundedDiv>
              </div> */}
              {/* <div style={{height:'100px'}}>

              </div> */}
              {/* <div style={{display: 'flex', justifyContent:'center', margin:'20px'}}>
                <DepartmentOverview></DepartmentOverview>
              </div> */}
              {/* <ExcelExport /> */}
            </DepartmentProvider>
          </div>
        )
      )}
    </div>
  );

};

export default App;
