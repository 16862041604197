import React, { useState, useRef, useEffect } from 'react';
import { useAppContext } from '../contexts/AppContext';
import inputStyles from '../styles/StyledInput.module.css';
import buttonStyles from '../styles/StyledButton.module.css';
import WeekPicker from '../week/WeekPicker';
import daySelectorStyles from '../styles/Dayselector.module.css';
import styles from './SchoolStatistics.module.css';
import iSStyles from './PersonStatistics.module.css';
import meetingAHStyles from '../styles/MeetingAH.module.css';
import selectStyles from '../styles/ThreeWaySelect.module.css';

import { WeekProvider } from '../contexts/WeekContext';
import { odv, log, weekDays, calculateDayTotalHours, calculateDayTotalHoursActual, timeToFloat, odvsw, floatToTime, findPeopleWithSameName } from '../utils/Utils';
import { getDayAdditionalHourLength, getEffectiveWorkHour } from '../utils/DataCalculator';
import RoundedDiv from '../assets/assets/rounded div/RoundedDiv';
import Modal from '../modal/Modal';
import ModalLight from '../modal/ModalLight';
import { MeetingAndAHAndWH } from './MeetingAndAHAndWH';


// document.addEventListener('DOMContentLoaded', function() {
//   const container = document.querySelector('#container'); // parent container for your divs

//   container.addEventListener('mouseover', function(e) {
//     if (e.target.classList.contains('category-div')) {
//       const category = e.target.getAttribute('data-category');
//       document.querySelectorAll(`[data-category="${category}"]`).forEach(div => {
//         div.classList.add('hovered');
//       });
//     }
//   });

//   container.addEventListener('mouseout', function(e) {
//     if (e.target.classList.contains('category-div')) {
//       const category = e.target.getAttribute('data-category');
//       document.querySelectorAll(`[data-category="${category}"]`).forEach(div => {
//         div.classList.remove('hovered');
//       });
//     }
//   });
// });

const SchoolStatistics = ({meetingAndAHAndWHMetaInfo, setMeetingAndAHAndWHMetaInfo}) => {
  const { state, dispatch } = useAppContext();
  const { people, weeks, days, departments, selectedPerson, selectedWeekId, selectedWeekNr, workHours, meetings, additionalHours,
          selectedDepartment, selectedDay, selectedStatisticsButton, numberOfWeeks, selectedSchoolStatisticsButton } = state
  const relevantDays = odv(days).filter(day => day.weekId === selectedWeekId)

  const [showMeetingList, setShowMeetingList] = useState(false)
  const [showAHList, setShowAHList] = useState(false)

  const [selectedMeetingPerson, setSelectedMeetingPerson] = useState()
  const [selectedMeetingWeek, setSelectedMeetingWeek] = useState()

  const [meetingIdList, setMeetingIdList] = useState([])
  const meetingList = odv(meetings).filter(meeting => meeting.peopleIds.includes(selectedMeetingPerson?.id) && meeting.weekNr === selectedMeetingWeek?.weekNr)
  const [meetingListMetaInfo, setMeetingListMetaInfo] = useState([])

  
  const [selectedTimeButton, setSelectedTimeButton] = useState('Timmar')

  // const [meetingAndAHAndWHMetaInfo, setMeetingAndAHAndWHMetaInfo ] = useState([])
  // const [showMeetingAndAHAndWH, setShowMeetingAndAHAndWH] = useState(false)

  const [hoveredWAD, setHoveredWAD] = useState([])

  const checkHoveredWAD = (deptId, weekNr) => {
    return (hoveredWAD[0] === deptId && hoveredWAD[1] === weekNr)
  }

  useEffect(() => {
    // setMeetingList(odv(meetings).filter(meeting => meetingIdList.includes(meeting.id)))
    log("rerendering meetings: ", meetingList)
  }, [])

  const weekNumbers = Array.from({ length: numberOfWeeks }, (_, i) => i + 1);
  
  let peopleSummations = {}

  const enableMeetingAndAHAndWH = (week, department, selectedOption) => {
    setMeetingAndAHAndWHMetaInfo([week, department, selectedOption])
    // setShowMeetingAndAHAndWH(true)
    dispatch({ type: 'TOGGLE_SHOW_MEETINGANDAHANDWH', payload: {setToShow:true} })
    log("enabled meetin gan dah")
  }

  function selectSchoolStatisticsButton(button) {
    dispatch({
        type: 'SELECT_SCHOOLSTATISTICS_BUTTON',
        payload: { button: button}
      });
  }

  const minWidthPx = '65px'


  const getTargetHour = (personId) => {
    const targetHours = people[personId].targetHours || []
    let targetType = ''
    
    switch (selectedSchoolStatisticsButton) {
      case 'Timmar':
        targetType = 'workHours'
        break
      case 'Möten':
        targetType = 'meetings'
        break
      case 'Barnfri tid':
        let categoriesSum = 0
        state.categories.forEach(category => {
          const target = targetHours.find(tH => tH[0] === category)

          if (target){
            const targetNr = target[1] // how many hours the target is for
            if (targetNr) {
              if (target[2] == 1) {
                categoriesSum += parseInt(targetNr) * numberOfWeeks
              } else if (target[2] == 2) {
                categoriesSum += parseInt(targetNr)
              }
            }
          }
        })
        
        return categoriesSum
    }
      
    if (targetHours) {
      const target = targetHours.find(tH => tH[0] === targetType)

      if (target){
        const targetNr = target[1] // how many hours the target is for
        if (targetNr) {
          if (target[2] == 1) {
            return targetNr * numberOfWeeks
          } else if (target[2] == 2) {
            return targetNr
          }
        }
      }
    }
  } 
  
  const getPercentageFilled = (personId) => {
    const targetHour = getTargetHour(personId)
    
    const sum = peopleSummations[personId]

    if (sum && targetHour) {
      return `${(Math.round(  (100*(sum / (targetHour)))   * 10) / 10).toString() }%`
    } else return '-'
  }
  
  return (
    <div style={{ maxWidth:'100%', height:'80vh'}}>
       {/* <ModalLight isOpen={showMeetingAndAHAndWH} onClose={() => {setShowMeetingAndAHAndWH(false); log("outside")}}>

            <MeetingAndAHAndWH 
              metainfo={meetingAndAHAndWHMetaInfo}>
            </MeetingAndAHAndWH>
      </ModalLight> */}
      
      
  
      <div style={{display:'flex', flexDirection:'column', maxHeight:'95%'}}>
        <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center',}}>
          <div style={{display:'flex', flexDirection: 'row', marginTop:'0px', marginBottom:'10px'}}>
            <p>Visa &nbsp;</p>
            <div className={`${selectStyles.selectOption}
                            ${selectedSchoolStatisticsButton === 'Timmar' ? selectStyles.selected : ''}
                            ${selectStyles.leftItem}`}
                  onClick={() => selectSchoolStatisticsButton('Timmar')}
                  style={{
                    // boxShadow: '0 1px 2px rgba(0, 0, 0, 0.24)',
                  }}>
              <p>Timmar</p>
            </div>
            <div className={`${selectStyles.selectOption}
                            ${selectedSchoolStatisticsButton === 'Barnfri tid' ? selectStyles.selected : ''}`}
                  onClick={() => selectSchoolStatisticsButton('Barnfri tid')}
                  style={{
                    // boxShadow: '0 1px 2px rgba(0, 0, 0, 0.24)',
                  }}>
              <p>Barnfri tid</p>
            </div>
            <div className={`${selectStyles.selectOption}
                            ${selectedSchoolStatisticsButton === 'Möten' ? selectStyles.selected : ''}
                            ${selectStyles.rightItem}`}
                  onClick={() => selectSchoolStatisticsButton('Möten')}
                  style={{
                    // boxShadow: '0 1px 2px rgba(0, 0, 0, 0.24)',
                  }}>
              <p>Möten</p>
            </div>
          </div>
        </div>
        <div style={{display:'flex'}}>
          <div style={{display:'flex', justifyContent:'space-between', marginLeft: `calc(max(${107}px, ${8}%))`}}>
            {
              // width: `calc(max(${107}px, ${200}%))`,
              
              weekNumbers.map(weekNr => (
                <div style={{ display:'flex', justifyContent:'center', width:'8%', minWidth:minWidthPx,}}
                className={`${styles.weekSumDiv} ${hoveredWAD[1] === weekNr ? styles.isHoveredWeekAndDept : ''}`}
                >
                  <p>v.{weekNr}</p>
                </div>
              ))
            }
          </div>
          <div style={{width:'10%', minWidth:'80px', display:'flex', justifyContent:'center', marginLeft:'2px'}}>
            <p>Summa</p>
          </div>
          <div style={{width:'10%', minWidth:'80px', display:'flex', justifyContent:'center'}}>
            <p>Mål</p>
          </div>
          <div style={{width:'10%', minWidth:'100px', display:'flex', justifyContent:'center', marginLeft:'2px'}}>
            <p>% uppfyllt</p>
          </div>
        </div>
        <div style={{overflowY:'auto', height:'90%', paddingBottom:'30px', paddingRight:'10px'}}>
          {
            odv(departments).map(department => {
              
              let counter = 1

              const departmentPeople = odv(people).filter(person => person.departmentId === department.id)

              return (
                <div style={{display:'flex', flexDirection:'column', marginBottom:'10px', }}>
                  <p style={{marginLeft:'7px', fontWeight:'500', fontSize:'1.25em', color:'rgb(53, 109, 255)'}}>{department.name}</p>
                  <div style={{border:'2px solid #f1f1f1'}}>
                  {
                    departmentPeople.length > 0 ? 
                      departmentPeople.map(person => {

                        counter++;

                        return (
                          <div
                            className={styles.personRow}
                            style={{
                              '--isEven': `${counter % 2 === 0 ? '#f1f1f1' : '#fff'}`,
                            }}>
                            <div style={{width:'8%', minWidth:'100px', borderRight:'2px solid #aaa', paddingLeft:'5px'}}
                            className={iSStyles.categoryName}
                            onClick={() => dispatch({ type: 'TOGGLE_SHOW_PERSON_SETTINGS', payload: {setToShow: true, personId: person.id}, meta: { localOnly: true } })}>
                              <p>{person.name}</p>
                            </div>
                            <div style={{ display:'flex', justifyContent:'space-between'}} class="container">
                              {
                                odvsw(weeks).filter(week => week.personId === person.id).map(week => {
                                  switch (selectedSchoolStatisticsButton) {

                                    case 'Timmar':
                                      let weekWorkSum = 0
                                      
                                      const relevantDays = odv(days).filter(day => day.weekId === week.id)

                                      relevantDays.forEach(day => {
                                        weekWorkSum += calculateDayTotalHoursActual(state, day.id)
                                      })

                                      if (peopleSummations[person.id]) {
                                        peopleSummations[person.id] += weekWorkSum
                                      } else {
                                        peopleSummations[person.id] = weekWorkSum
                                      }

                                      const isHovered = hoveredWAD[0] === department.id && hoveredWAD[1] === week.weekNr

                                      return (
                                        <div style={{width:'8%', minWidth:minWidthPx, display:'flex', justifyContent:'center', cursor:'pointer', alignItems:'center'
                                          }}
                                          // '--isHoveredWeek': `${hoveredWAD[0] == department.id && hoveredWAD[1] == week.weekNr ? '#e22' : '#000'}`
                                        onClick={() => enableMeetingAndAHAndWH(week, department, 1)}
                                        className={`${styles.weekSumDiv} ${isHovered ? styles.isHoveredWeekAndDept : ''}`}
                                        onMouseEnter={() => setHoveredWAD([department.id, week.weekNr])}
                                        onMouseLeave={() => setHoveredWAD([])}
                                        // class="category-div"
                                        // data-category={`${department.id},${week.weekNr}`}
                                        >
                                          <p>{weekWorkSum > 0 ? `${weekWorkSum}h` : '-'}</p>
                                        </div>
                                      )

                                    case 'Barnfri tid':
                                      {
                                        let weekAHSum = 0

                                        let relevantAH = odv(additionalHours).filter(aH => days[aH.dayId].weekId === week.id)

                                        relevantAH.forEach(aH => {
                                          weekAHSum += timeToFloat(aH.length)
                                        })

                                        // //Below calculates for all people with same name
                                        // let workAHSum = 0
                                        
                                        // const relevantPeopleIDList = findPeopleWithSameName(state, person).map(person => person.id)

                                        // const relevantDays = odv(days).filter(day => relevantPeopleIDList.includes(weeks[day.weekId].personId) && weeks[day.weekId].weekNr === week.weekNr)

                                        // relevantDays.forEach(relevantDay => {
                                        //   const relevantAdditionalHours = odv(additionalHours).filter(aH => aH.dayId === relevantDay.id)

                                        //   relevantAdditionalHours.forEach(aH => {
                                        //     workAHSum += timeToFloat(aH.length)
                                        //   })
                                        // })

                                        if (peopleSummations[person.id]) {
                                          peopleSummations[person.id] += weekAHSum
                                        } else {
                                          peopleSummations[person.id] = weekAHSum
                                        }

                                        const isHovered = hoveredWAD[0] === department.id && hoveredWAD[1] === week.weekNr


                                        return (
                                          <div style={{width:'8%', minWidth:minWidthPx, display:'flex', justifyContent:'center', cursor:'pointer', alignItems:'center'}}
                                              onClick={() => enableMeetingAndAHAndWH(week, department, 2)}
                                              className={`${styles.weekSumDiv} ${isHovered ? styles.isHoveredWeekAndDept : ''}`}
                                              onMouseEnter={() => setHoveredWAD([department.id, week.weekNr])}
                                              onMouseLeave={() => setHoveredWAD([])}>
                                                {/* 3 is for meeting */}
                                            {weekAHSum === 0 ? '-' : `${weekAHSum}h`}
                                          </div>
                                        )
                                      }
                                      break

                                    case 'Möten':
                                      {let weekMeetingSum = 0
                                      
                                      const relevantMeetings = odv(meetings).filter(meeting => meeting.peopleIds.includes(person.id) && meeting.weekNr === week.weekNr)

                                      relevantMeetings.forEach(meeting => {
                                        weekMeetingSum += timeToFloat(meeting.length)
                                      })

                                      if (peopleSummations[person.id]) {
                                        peopleSummations[person.id] += weekMeetingSum
                                      } else {
                                        peopleSummations[person.id] = weekMeetingSum
                                      }

                                      const isHovered = hoveredWAD[0] === department.id && hoveredWAD[1] === week.weekNr

                                      return (
                                        <div style={{width:'8%', minWidth:minWidthPx, display:'flex', justifyContent:'center', cursor:'pointer', alignItems:'center'}}
                                            onClick={() => enableMeetingAndAHAndWH(week, department, 3)}
                                            className={`${styles.weekSumDiv} ${isHovered ? styles.isHoveredWeekAndDept : ''}`}
                                            onMouseEnter={() => setHoveredWAD([department.id, week.weekNr])}
                                            onMouseLeave={() => setHoveredWAD([])}>
                                              {/* 3 is for meeting */}
                                          {weekMeetingSum === 0 ? '-' : `${weekMeetingSum}h`}
                                        </div>
                                      )}

                                  }
                                })
                              }
                            </div>
                            <div style={{borderLeft:'2px solid #aaa',width:'8%', minWidth:'80px', display:'flex', justifyContent:'center', }}>
                              <p>{peopleSummations[person.id]}h</p>
                            </div>
                            <div style={{borderLeft:'2px solid #aaa',width:'8%', minWidth:'80px', display:'flex', justifyContent:'center', }}
                              className={iSStyles.categoryName}
                              onClick={() => dispatch({ type: 'TOGGLE_SHOW_PERSON_SETTINGS', payload: {setToShow: true, personId: person.id}, meta: { localOnly: true } })}
                              title='Redigera mål'>
                              {/* <p>{(Math.round(  (100*(peopleSummations[person.id] / (numberOfWeeks * 40)))   * 10) / 10).toString() }%</p> */}
                              <p>{getTargetHour(person.id) ? `${getTargetHour(person.id)}h`:'-'}</p>
                            </div>
                            {
                              // (selectedSchoolStatisticsButton === 'Timmar' || selectedSchoolStatisticsButton === 'Möten') && <div style={{borderLeft:'2px solid #aaa',width:'8%', minWidth:'120px', display:'flex', justifyContent:'center', }}>
                              <div style={{borderLeft:'2px solid #aaa',width:'8%', minWidth:'100px', display:'flex', justifyContent:'center', }}>
                                  <p>{getPercentageFilled(person.id)}</p> 
                                  {/* person.targetHours */}
                                </div>
                            }
                            
                          </div>
                        )
                      })
                      : <div style={{display:'flex', justifyContent:'center'}}>
                          <p style={{color:'#b1b1b1'}}>Inga personer att visa</p>
                        </div>
                  }
                  </div>
                </div>
              )
              
            })
          }
        </div>
      </div>
      
    </div>
  )
}

export default SchoolStatistics