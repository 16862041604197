import React, { useState, useReducer, useEffect, useMemo } from 'react';
import { useAppContext } from "../contexts/AppContext";
import { odv, log, weekDays, calculateDayTotalHours, calculateDayTotalHoursActual, timeToFloat } from '../utils/Utils';
import { SynchronizedBarCharts } from '../charts/Charts';
import { generateIntervals, generateChartData } from '../charts/StaffChart';
import DaySelector from '../visualOverhaul/DaySelector';
import WeekSelector from '../visualOverhaul/WeekSelector';
import { IncrementButton } from '../visualOverhaul/RightIsland';

export const generateChartDataFromState = (state) => {
  const { people, weeks, days, departments, selectedPerson, selectedWeekId, selectedWeekNr, workHours, breakHours, meetings, additionalHours,
    selectedDepartment, selectedDay, selectedStatisticsButton } = state
  const intervals = generateIntervals();
  let newChartData = [];

  Object.values(departments).forEach(dept => {
    newChartData.push({title: dept.name, color: dept.color, chartData: generateChartData(state, intervals, selectedDay, dept).personalIBarngruppData})
  })

  return newChartData
}
export const AllCharts = ({chartData}) => {
  const { state, dispatch } = useAppContext();
  const { people, weeks, days, departments, selectedPerson, selectedWeekId, selectedWeekNr, workHours, breakHours, meetings, additionalHours,
          selectedDepartment, selectedDay, selectedStatisticsButton } = state
  const [newChartData, setNewChartData] = useState([])
  const intervals = generateIntervals();

  // useEffect(() => {
  //   let newChartData = [];

  //   Object.values(departments).forEach(dept => {
  //     newChartData.push({title: dept.name, color: dept.color, chartData: generateChartData(state, intervals, selectedDay, dept).personalIBarngruppData})
  //   })

  //   log ("chart data is : ", newChartData)
  //   setNewChartData(newChartData);

  // }, [workHours, selectedWeekId, breakHours, meetings, additionalHours, selectedDay, departments]);

  return (
    <div style={{ padding: '10px', maxWidth: '85vw', maxHeight: '80vh', overflowX: 'auto' }}>
      <div style={{display:'flex'}}>
        <div style={{marginTop:'90px'}}>
          {/* <WeekSelector showAddRemove={false}></WeekSelector> */}

        </div>
        <div>

          <div style={{display:'flex', justifyContent:'center', flexDirection:'column', }}>
            <p style={{ fontSize: '1.5em', marginBottom: '10px', textAlign:'center' }}>Översikt personal i barngrupp</p>
            <div style={{display:'flex', width:'100%', justifyContent:'center', alignItems:'center', marginBottom:'10px', gap:'10px'
            }}> 
              <IncrementButton isDec={true} dispatch={dispatch}/><span style={{ fontSize:'1.3em', whiteSpace:'nowrap'}}> Vecka {selectedWeekNr}</span><IncrementButton isDec={false} dispatch={dispatch} />
              <DaySelector></DaySelector>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              gap: '20px',
            }}
          >
            {chartData.map((data, index) => (
              <div key={index} style={{  marginBottom: '20px' }}>
                {/* flexBasis: 'calc(50% - 20px)',  */}
                {/* <SynchronizedBarCharts width="100%" chartsData={[data]} /> */}
                <SynchronizedBarCharts width="600px" height='300px' chartsData={[data]} />
              </div>
            ))}
          </div>
        </div>
      </div>
      
    </div>
  );
  
  
}