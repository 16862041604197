import React, { useState, useRef, useEffect } from 'react';
import styles from './AHDropdown.module.css';
import { useAppContext } from '../../../../contexts/AppContext';
import { weekDays, log, odv } from '../../../../utils/Utils';
import ModalLight from '../../../../modal/ModalLight';
import { CategoryViewer } from '../../../../modal/CategoryViewer';


const AHDropdown = ({onChange, initialCategory}) => {
  const { state, dispatch } = useAppContext();
  const [selectedCategory, setSelectedDay] = useState(initialCategory || state.categories[0])
  log("initla cateogy: ", initialCategory, selectedCategory)
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // const [showEditCategories, setShowEditCategories] = useState(false)

  
  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelect = (day) => {
    setSelectedDay(day);
    log("selecting cat:", day, selectedCategory)
    onChange(day);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.daySelector} ref={dropdownRef}>

      {/* <ModalLight isOpen={showEditCategories} onClose={() => setShowEditCategories(false)}>
        <CategoryViewer setShowEditCategories={setShowEditCategories}></CategoryViewer>
      </ModalLight> */}

      <button className={styles.selectorButton} onClick={toggleDropdown}>
        <p className={styles.para}>{selectedCategory || 'Välj typ'}</p>
      </button>
      {isOpen && (
        <ul className={styles.dropdown}>
          {state.categories.map((category) => (
            <li key={category} onClick={() => handleSelect(category)}>
              <p>{category}</p>
            </li>
          ))}
          <li key='Hello' onClick={() => {dispatch({ type: 'TOGGLE_SHOW_EDIT_CATEGORIES', payload:{setToShow: true} });
            setIsOpen(false)}
          }>
            <p>Redigera</p>
          </li>
        </ul>
      )}
    </div>
  );
};

export default AHDropdown;