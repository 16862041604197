import React, { useState, useEffect } from 'react';
import { useWeekContext } from '../contexts/WeekContext';
import { useDayContext } from '../contexts/DayContext';
import inputStyles from '../styles/StyledInput.module.css';
import { validateAndFormatTime, getTextColor } from '../utils/Utils';
import hoursStyles from '../styles/Hours.module.css'
import { log, autofillThirty, odv } from '../utils/Utils';
import { useAppContext } from '../contexts/AppContext';
import styles from './WorkHoursMinimal.module.css'

const WorkHoursMinimal = ({dayId}) => {
  const { state, dispatch } = useAppContext(); 
  const {days, weeks, selectedPerson, selectedWeekId } = state
  const [startTime, setStartTime] = useState(Object.values(state.workHours).find(workHour => (
    workHour.dayId === dayId && workHour.type === 'start'  ))?.time);
  const [endTime, setEndTime] = useState(Object.values(state.workHours).find(workHour => (
    workHour.dayId === dayId && workHour.type === 'end'  ))?.time);
  const [finalStartTime, setFinalStartTime] = useState(Object.values(state.workHours).find(workHour => (workHour.dayId === dayId && workHour.type === 'start' && days[workHour.dayId].weekId === selectedWeekId   ))?.time || '00:00');
  const [finalEndTime, setFinalEndTime] = useState(Object.values(state.workHours).find(workHour => (workHour.dayId === dayId && workHour.type === 'end' && days[workHour.dayId].weekId === selectedWeekId ))?.time || '00:00');

  const weekDaySettings = state.weekDaySettings || {
      'Måndag': true,
      'Tisdag': true,
      'Onsdag': true,
      'Torsdag': true,
      'Fredag': true,
  }

  const daySetting = weekDaySettings[days[dayId].weekDay]

  useEffect(() => {
    const foundStartTime = Object.values(state.workHours).find(workHour => (workHour.dayId === dayId && workHour.type === 'start'))?.time || ''
    const foundEnd = Object.values(state.workHours).find(workHour => (workHour.dayId === dayId && workHour.type === 'end'))?.time || ''
    
    setStartTime(foundStartTime);
    setEndTime(foundEnd);

    autofillThirty(state, dispatch, dayId)
    // if (!foundStartTime) {setFinalStartTime('00:00'); setStartTime(null)} else setFinalStartTime(foundStartTime);
    // if (!foundEnd) {setFinalEndTime('00:00'); setEndTime(null)} else setFinalEndTime(foundEnd);
  }, [dayId, state.workHours, state.selectedPerson, state.selectedWeekId]);

  const handleKeyDown = (event, type) => {
    if (event.key === 'Enter' || event.type === 'blur') {
      const formattedTime = validateAndFormatTime(event.target.value);
      if (formattedTime) {
        // if (type === 'start') {
          // setFinalStartTime(formattedTime)
        if (daySetting) {
          const relevantDays = odv(days).filter(day => weeks[day.weekId].personId === weeks[days[dayId].weekId].personId && day.weekDay === days[dayId].weekDay)
          log("found relevant days: ", relevantDays)
          odv(relevantDays).forEach(day => {
            dispatch({ type: 'SET_WORK_HOUR_TIME', payload: {time: formattedTime, dayId: day.id, type: type} }); // Do for all days

          })
        } else {
          dispatch({ type: 'SET_WORK_HOUR_TIME', payload: {time: formattedTime, dayId: dayId, type: type} }); // Just do for this day
        }
        // } else {
        //   // setFinalEndTime(formattedTime)
        //   dispatch({ type: 'SET_WORK_HOUR_TIME', payload: {time: formattedTime, dayId: dayId, type: type} });
        // }
        // autofillThirty(state, dispatch, dayId) // moved to useeffect
      } else {
        let newTime = null

        if (daySetting) {
          const relevantDays = odv(days).filter(day => weeks[day.weekId].personId === weeks[days[dayId].weekId].personId && day.weekDay === days[dayId].weekDay)
          log("found relevant days: ", relevantDays)
          odv(relevantDays).forEach(day => {
           dispatch({ type: 'SET_WORK_HOUR_TIME', payload: {time: newTime, dayId: dayId, type: type} });


          })
        } else {
          dispatch({ type: 'SET_WORK_HOUR_TIME', payload: {time: newTime, dayId: dayId, type: type} });
        }

        // if (type === 'start') {
          // setFinalStartTime(newTime)
        // } else {
          // setFinalEndTime(newTime)
          // dispatch({ type: 'SET_WORK_HOUR_TIME', payload: {time: newTime, dayId: dayId, type: type} });
        // }
      }
    }
  };

  const handleWheel = (event, type) => {
    event.preventDefault(); // Prevent the page from scrolling
    const currentTime = type === 'start' ? startTime : endTime;
    if (!currentTime) return;

    const [hours, minutes] = currentTime.split(':').map(Number);
    let totalMinutes = hours * 60 + minutes;

    // Increment or decrement by 15 minutes
    totalMinutes += event.deltaY < 0 ? 15 : -15;

    // Ensure time is within 00:00 to 23:59 range
    if (totalMinutes < 0) totalMinutes += 1440;
    if (totalMinutes >= 1440) totalMinutes -= 1440;

    const newHours = Math.floor(totalMinutes / 60).toString().padStart(2, '0');
    const newMinutes = (totalMinutes % 60).toString().padStart(2, '0');
    const newTime = `${newHours}:${newMinutes}`;

    if (type === 'start') {
      setStartTime(newTime);
      dispatch({ type: 'SET_WORK_HOUR_TIME', payload: { time: newTime, dayId, type: 'start' } });
    } else {
      setEndTime(newTime);
      dispatch({ type: 'SET_WORK_HOUR_TIME', payload: { time: newTime, dayId, type: 'end' } });
    }

    autofillThirty(state, dispatch, dayId);
  };


  return (
    <div className={styles.flexContainer}>
      {/* <p style={{fontWeight:'500'}}>Arbetstid</p> */}

        <input
          title='Använd formatet hh.mm, hh:mm eller h'
          className={`${styles.input} ${styles.wHInput}`}
          value={startTime}
          onChange={e => setStartTime(e.target.value)}
          onKeyDown={e => handleKeyDown(e, 'start')}
          onBlur={(e) => handleKeyDown(e, 'start')}
          onWheel={e => handleWheel(e, 'start')}
          placeholder="Start"
        />
        <p style={{color: `${startTime && endTime ? '#222' : '#ccc'}`, fontWeight:'400'}}>-</p>
        {/* <p style={{ color: getTextColor(finalStartTime), width:'65px', marginRight:'00px' }}>{finalStartTime}</p> */}
        <input
          title='Använd formatet hh.mm, hh:mm eller h'
          className={`${styles.input} ${styles.wHInput}`}
          value={endTime}
          onChange={e => setEndTime(e.target.value)}
          onKeyDown={e => handleKeyDown(e, 'end')}
          onBlur={(e) => handleKeyDown(e, 'end')}
          onWheel={e => handleWheel(e, 'end')}
          // style={{marginLeft:'10px'}}
          placeholder="Slut"
        />

      {/* <p style={{ color: getTextColor(finalEndTime), width:'65px', marginRight:'00px'  }}>{finalEndTime}</p> */}
    </div>
  );
};

export default WorkHoursMinimal;
