import React, { useState, useEffect } from 'react';
import * as ExcelJS from 'exceljs';

const ExcelPreview = ({ workbook }) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const extractData = async () => {
      if (!workbook) return;

      const worksheet = workbook.worksheets[0];
      const data = [];

      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        const rowData = [];
        row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
          const cellStyle = {
            value: cell.text,
            style: {}
          };

          // Font
          if (cell.font) {
            cellStyle.style.fontFamily = cell.font.name;
            cellStyle.style.fontSize = `${cell.font.size || 11}px`;
            cellStyle.style.fontWeight = cell.font.bold ? 'bold' : 'normal';
            cellStyle.style.fontStyle = cell.font.italic ? 'italic' : 'normal';
            cellStyle.style.textDecoration = cell.font.underline ? 'underline' : 'none';
            cellStyle.style.color = cell.font.color ? `#${cell.font.color.argb.substring(2)}` : 'black';
          }

          // Fill
          if (cell.fill && cell.fill.type === 'pattern' && cell.fill.pattern === 'solid') {
            cellStyle.style.backgroundColor = `#${cell.fill.fgColor.argb.substring(2)}`;
          }

          // Alignment
          if (cell.alignment) {
            cellStyle.style.textAlign = cell.alignment.horizontal || 'left';
            cellStyle.style.verticalAlign = cell.alignment.vertical || 'top';
          }

          // Border
          const borderStyle = (border) => {
            if (!border) return '1px solid #d1d1d1';  // Default light gray border
            if (border.style === 'thin') return `1px solid #${border.color?.argb.substring(2) || '000000'}`;
            if (border.style === 'medium') return `2px solid #${border.color?.argb.substring(2) || '000000'}`;
            if (border.style === 'thick') return `3px solid #${border.color?.argb.substring(2) || '000000'}`;
            return '1px solid #d1d1d1';  // Default light gray border
          };
          cellStyle.style.borderTop = borderStyle(cell.border?.top);
          cellStyle.style.borderRight = borderStyle(cell.border?.right);
          cellStyle.style.borderBottom = borderStyle(cell.border?.bottom);
          cellStyle.style.borderLeft = borderStyle(cell.border?.left);

          // Wrap text
          cellStyle.style.whiteSpace = cell.alignment?.wrapText ? 'pre-wrap' : 'nowrap';

          rowData.push(cellStyle);
        });
        data.push(rowData);
      });

      setTableData(data);
    };

    extractData();
  }, [workbook]);

  return (
    <div style={{ overflowX: 'auto', maxWidth: '100%', maxHeight: '500px', overflowY: 'auto' }}>
      <table style={{ borderCollapse: 'separate', borderSpacing: 0, width: '100%' }}>
        <tbody>
          {tableData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td
                  key={cellIndex}
                  style={{
                    ...cell.style,
                    padding: '5px',
                    maxWidth: '300px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {cell.value}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ExcelPreview;