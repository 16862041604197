import React, { useRef, useEffect, useState } from 'react';
import styles from './ItemSelector.module.css';

const ItemSelector = ({ items, onItemSelect, selectedItem, areObjects = false, dispatch = null, showDropDown = true}) => {
  const [underlineStyle, setUnderlineStyle] = useState({});
  const [editedName, setEditedName] = useState('')
  const itemRefs = useRef([]);

  useEffect(() => {
    if (items.length === 0) {
      setUnderlineStyle({ display: 'none' });
      return;
    }

    let selectedIndex;
    if (areObjects) {
      selectedIndex = items.findIndex((item) => item.id === selectedItem);
    } else {
      selectedIndex = items.indexOf(selectedItem);
    }

    if (selectedIndex !== -1) {
      const selectedItemElement = itemRefs.current[selectedIndex];
      const { offsetLeft, offsetWidth } = selectedItemElement;
      
      if (selectedIndex === 0) {
        // Adjust for the leftmost item
        const textWidth = selectedItemElement.firstChild.offsetWidth;
        const leftPadding = parseInt(window.getComputedStyle(selectedItemElement).paddingLeft);
        setUnderlineStyle({
          width: `${textWidth * 0.8}px`,
          transform: `translateX(${offsetLeft + leftPadding + (textWidth * 0.1)}px)`,
        });
      } else {
        setUnderlineStyle({
          width: `${offsetWidth * 0.8}px`,
          transform: `translateX(${offsetLeft + offsetWidth * 0.1}px)`,
        });
      }
    }
  }, [selectedItem, items]);


  const handleItemClick = (item) => {
    if (areObjects) {
      onItemSelect(item.id);
    } else {
      onItemSelect(item);
      
    }
  };

  const removeDept = (deptId) => {
    dispatch({ type: 'TOGGLE_SHOW_REMOVE_DEPARTMENT', payload: {
      setToShow: true,
      departmentId: deptId,
    } })
  }

  const editDept = (deptId) => {
    dispatch({ type: 'TOGGLE_SHOW_EDIT_DEPARTMENT_NAME', payload: {
      setToShow: true,
      departmentId: deptId,
    } })
  }


  return (
    <div className={styles.itemSelector}>
      {items.map((item, index) => (
        <div
          key={index}
          className={`${styles.item} ${styles.container} ${selectedItem === item || selectedItem === item.id ? styles.selected : ''} ${index === 0 ? styles.leftMostItem : ''} ${index === items.length -1 ? (areObjects ? '' : styles.rightMostItem) : ''}`}
          onClick={() => handleItemClick(item)}
          ref={(el) => (itemRefs.current[index] = el)}
        >
          <span>{areObjects ? item.name : item}</span>
          {
            areObjects && showDropDown && <div className={styles.dropdown}>
            <button className={''} onClick={() => editDept(item.id)}>
              <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 21H21" stroke="#323232" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M20.0651 7.39423L7.09967 20.4114C6.72438 20.7882 6.21446 21 5.68265 21H4.00383C3.44943 21 3 20.5466 3 19.9922V18.2987C3 17.7696 3.20962 17.2621 3.58297 16.8873L16.5517 3.86681C19.5632 1.34721 22.5747 4.87462 20.0651 7.39423Z" stroke="#323232" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15.3097 5.30981L18.7274 8.72755" stroke="#323232" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
            <button className={''} onClick={() => removeDept(item.id)}>
              <svg height="20px" width="20px" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlnsXlink="http://www.w3.org/1999/xlink" enableBackground="new 0 0 512 512">
                <g>
                  <g fill="#f11" stroke="#f11" strokeWidth="3">
                    <path d="m450.9,73.5h-118.4v-52.1c0-5.8-4.7-10.4-10.4-10.4h-132.2c-5.8,0-10.4,4.7-10.4,10.4v52.1h-118.4c-5.8,0-10.4,4.7-10.4,10.4v64.9c0,5.8 4.7,10.4 10.4,10.4h24.6v264.7c0,42.5 34.6,77.1 77.1,77.1h186.4c42.5,0 77.1-34.6 77.1-77.1v-264.6h24.6c5.8,0 10.4-4.7 10.4-10.4v-65c-5.68434e-14-5.7-4.7-10.4-10.4-10.4zm-250.5-41.6h111.3v41.7h-111.3v-41.7zm205,392c0,31-25.2,56.2-56.2,56.2h-186.4c-31,0-56.2-25.2-56.2-56.2v-264.6h298.8v264.6zm35-285.5h-368.8v-44h368.9v44z"/>
                    <path d="m164.1,427c5.8,0 10.4-4.7 10.4-10.4v-193.7c0-5.8-4.7-10.4-10.4-10.4-5.8,0-10.4,4.7-10.4,10.4v193.7c0,5.7 4.7,10.4 10.4,10.4z"/>
                    <path d="M256,427c5.8,0,10.4-4.7,10.4-10.4V222.9c0-5.8-4.7-10.4-10.4-10.4s-10.4,4.7-10.4,10.4v193.7    C245.6,422.3,250.2,427,256,427z"/>
                    <path d="m347.9,427c5.8,0 10.4-4.7 10.4-10.4v-193.7c0-5.8-4.7-10.4-10.4-10.4-5.8,0-10.4,4.7-10.4,10.4v193.7c-0.1,5.7 4.6,10.4 10.4,10.4z"/>
                  </g>
                </g>
              </svg>
            </button>
          </div>
          }
        </div>
      ))}
      {items.length > 0 && (
        <div
          className={styles.underline}
          style={underlineStyle}
        />
      )}
    </div>
  );
  return (
    <div className={styles.itemSelector}>
      {items.map((item, index) => (
        <div
          key={index}
          className={`${styles.item} ${styles.container} ${selectedItem === item || selectedItem === item.id ? styles.selected : ''} ${index === 0 ? styles.leftMostItem : ''} ${index === items.length -1 ? (areObjects ? '' : styles.rightMostItem) : ''}`}
          onClick={() => handleItemClick(item)}
          ref={(el) => (itemRefs.current[index] = el)}
        >
          {areObjects ? item.name : item}
          {
            areObjects && showDropDown && <div className={styles.dropdown}>
            <button className={''} onClick={() => editDept(item.id)}>
              <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 21H21" stroke="#323232" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M20.0651 7.39423L7.09967 20.4114C6.72438 20.7882 6.21446 21 5.68265 21H4.00383C3.44943 21 3 20.5466 3 19.9922V18.2987C3 17.7696 3.20962 17.2621 3.58297 16.8873L16.5517 3.86681C19.5632 1.34721 22.5747 4.87462 20.0651 7.39423Z" stroke="#323232" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15.3097 5.30981L18.7274 8.72755" stroke="#323232" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
            <button className={''} onClick={() => removeDept(item.id)}>
              <svg height="20px" width="20px" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlnsXlink="http://www.w3.org/1999/xlink" enableBackground="new 0 0 512 512">
                <g>
                  <g fill="#f11" stroke="#f11" strokeWidth="3">
                    <path d="m450.9,73.5h-118.4v-52.1c0-5.8-4.7-10.4-10.4-10.4h-132.2c-5.8,0-10.4,4.7-10.4,10.4v52.1h-118.4c-5.8,0-10.4,4.7-10.4,10.4v64.9c0,5.8 4.7,10.4 10.4,10.4h24.6v264.7c0,42.5 34.6,77.1 77.1,77.1h186.4c42.5,0 77.1-34.6 77.1-77.1v-264.6h24.6c5.8,0 10.4-4.7 10.4-10.4v-65c-5.68434e-14-5.7-4.7-10.4-10.4-10.4zm-250.5-41.6h111.3v41.7h-111.3v-41.7zm205,392c0,31-25.2,56.2-56.2,56.2h-186.4c-31,0-56.2-25.2-56.2-56.2v-264.6h298.8v264.6zm35-285.5h-368.8v-44h368.9v44z"/>
                    <path d="m164.1,427c5.8,0 10.4-4.7 10.4-10.4v-193.7c0-5.8-4.7-10.4-10.4-10.4-5.8,0-10.4,4.7-10.4,10.4v193.7c0,5.7 4.7,10.4 10.4,10.4z"/>
                    <path d="M256,427c5.8,0,10.4-4.7,10.4-10.4V222.9c0-5.8-4.7-10.4-10.4-10.4s-10.4,4.7-10.4,10.4v193.7    C245.6,422.3,250.2,427,256,427z"/>
                    <path d="m347.9,427c5.8,0 10.4-4.7 10.4-10.4v-193.7c0-5.8-4.7-10.4-10.4-10.4-5.8,0-10.4,4.7-10.4,10.4v193.7c-0.1,5.7 4.6,10.4 10.4,10.4z"/>
                  </g>
                </g>
              </svg>
            </button>
          </div>
          }
          
        </div>
      ))}
      <div
        className={styles.underline}
        style={underlineStyle}
      />
    </div>
  );
};

export default ItemSelector;
