import React, { useState, useRef, useEffect } from 'react';
import styles from './DaySelectorDropdown.module.css';
import { useAppContext } from '../../../../contexts/AppContext';
import { weekDays, log, odv } from '../../../../utils/Utils';


const DaySelectorDropdown = ({onChange}) => {
  const { state, dispatch } = useAppContext();
  // const [selectedDay, setSelectedDay] = useState(state.selectedDay)
  const {selectedDay} = state
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const workdays = ['Måndag', 'Tisdag', 'Wednesday', 'Thursday', 'Friday'];

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleDaySelect = (day) => {
    // setSelectedDay(day);
    // onChange(day);
    dispatch({
      type: 'SELECT_DAY',
      payload: { day: day},
      meta: { localOnly: true }
    })
    // setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.daySelector} ref={dropdownRef}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}>
        {/* <button>hsadflkadjfasdf</button> */}
      <button className={styles.selectorButton} onClick={() => setIsOpen(true)}
      >
        <div style={{display:'flex', alignItems:'center', gap:'5px'}}>
          <p className={styles.para}>{selectedDay || 'Välj dag'}</p>
          <svg width="40px" height="40px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g id="_24x24_On_Light_Arrow-Bottom" data-name="24x24/On Light/Arrow-Bottom" transform="translate(0 24) rotate(-90)">
              <rect id="view-box" width="24" height="24" fill="none"/>
              <path id="Shape" d="M.22,10.22A.75.75,0,0,0,1.28,11.28l5-5a.75.75,0,0,0,0-1.061l-5-5A.75.75,0,0,0,.22,1.28l4.47,4.47Z" transform="translate(14.75 17.75) rotate(180)" fill="#141124"/>
            </g>
          </svg>
        </div>

      </button>
      {isOpen && (
        <ul className={styles.dropdown}>
          {odv(weekDays).map((day) => (
            <li key={day} onClick={() => handleDaySelect(day)}>
              <div style={{display:'flex', alignItems:'center', gap:'0px'}}>
                {day === selectedDay ? <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8Z" fill="rgb(83, 149, 255)"/>
                </svg> : <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8Z" fill="rgba(83, 149, 255, 0)"/>
                </svg> }
                <p>{day}</p>
              </div>

            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DaySelectorDropdown;