import React, { useState, useRef, useEffect } from 'react';
import { useAppContext } from '../contexts/AppContext';
import inputStyles from '../styles/StyledInput.module.css';
import buttonStyles from '../styles/StyledButton.module.css';
import WeekPicker from '../week/WeekPicker';
import daySelectorStyles from '../styles/Dayselector.module.css';

import { WeekProvider } from '../contexts/WeekContext';
import { odv, log, weekDays, calculateDayTotalHours, calculateDayTotalHoursActual, timeToFloat } from '../utils/Utils';
import { getDayAdditionalHourLength, getEffectiveWorkHour } from '../utils/DataCalculator';
import RoundedDiv from '../assets/assets/rounded div/RoundedDiv';
import SchoolStatistics from './SchoolStatistics';
import PersonStatistics from './PersonStatistics';
import DepartmentInfo from '../modal/DepartmentInfo';

const Statistics = ({meetingAndAHAndWHMetaInfo, setMeetingAndAHAndWHMetaInfo}) => {
  const { state, dispatch } = useAppContext();
  const { people, weeks, days, departments, selectedPerson, selectedWeekId, selectedWeekNr, workHours, meetings, additionalHours,
          selectedDepartment, selectedDay,  } = state
  const selectedStatisticsButton = state.selectedStatisticsButton || 'Förskola'
  // const selectedStatisticsButton = 'Förskola'

  const relevantDays = odv(days).filter(day => day.weekId === selectedWeekId)
  const [hoveredPersonId, setHoveredPersonId] = useState(null);


  let weekWorkSum = 0

  let allRelevantDays = []
  let allRelevantAH = []
  let relevantAH = []

  relevantDays.forEach(day => {
    weekWorkSum += calculateDayTotalHours(state, day.id)
    relevantAH.push(...odv(additionalHours).filter(aH => aH.dayId === day.id))
  })

  const relevantWeeks = odv(weeks).filter(week => week.personId === selectedPerson) // Take all the weeks that belong to this person

  relevantWeeks.forEach(week => {
    allRelevantDays.push(...odv(days).filter(day => day.weekId === week.id)) // Add all the days in these weeks

  })

  const dayIds = odv(allRelevantDays).map(day => day.id)

  odv(additionalHours).forEach(aH => {
    // if (Object.keys(allRelevantDays).map(Number).includes(aH.dayId)) {
    if (dayIds.includes(aH.dayId)) {
      allRelevantAH.push(aH)
    }
  })

  let totalCalendarWorkSum = 0
  let totalCalendarAHSum = 0
  let weekAHSum = 0

  relevantAH.forEach(aH => {
    weekAHSum += timeToFloat(aH.length)
  })

  allRelevantDays.forEach(day => {
    totalCalendarWorkSum += calculateDayTotalHours(state, day.id)
  })

  allRelevantAH.forEach(aH => {
    totalCalendarAHSum += timeToFloat(aH.length)
  })

  const allRelevantMeetings = Object.values(meetings).filter(meeting => 
      meeting.peopleIds.includes(selectedPerson)
    )
    
  let weekMeetingSum = 0
  let calendarMeetingSum = 0

  allRelevantMeetings.forEach(meeting => {
    if (meeting.weekNr === selectedWeekNr) {
      weekMeetingSum += timeToFloat(meeting.length)
      calendarMeetingSum += timeToFloat(meeting.length)
    } else {
      calendarMeetingSum += timeToFloat(meeting.length)
    }
  })

  const handleMouseEnter = (personId) => {
    setHoveredPersonId(personId);
  };

  const handleMouseLeave = () => {
    setHoveredPersonId(null);
  };

  const selectDepartment = (departmentId) => {
    dispatch({
      type: 'SELECT_DEPARTMENT',
      payload: { departmentId: departmentId},
      meta: { localOnly: true }
    });
  };

  const selectPerson = (personId) => {
    dispatch({
      type: 'SELECT_PERSON',
      payload: { personId: personId},
      meta: { localOnly: true }
    });
  }

  function selectButton(button) {
    // const buttonId = event.target.id;
    // const buttonId = event.target.value;
    dispatch({
        type: 'SELECT_STATISTICS_BUTTON',
        payload: { button: button}
      });
  }
  // minWidth:'50vw', 
  return (
    <div style={{ maxWidth:'80vw', height:'80vh'}}> 
      <div style={{display:'flex', flexDirection:'column'}}>

        <div style={{display:'flex', justifyContent:'center'}}>
          <div style={{}}>
            <p style={{fontWeight:'400', fontSize:'30px'}}>Överblick förskola</p>
          </div>
          {/* <div className={daySelectorStyles.container} style={{marginLeft:'10px'}}>
            {['Förskola', 'Avdelning', 'Person' ].map((button) => {
              const isSelected = selectedStatisticsButton === button;
              const dayClass = `${daySelectorStyles.dayButton} ${isSelected ? daySelectorStyles.selected : ''} ${
                button === 'Förskola' ? daySelectorStyles.monday : button === 'Person' ? daySelectorStyles.friday : ''
              }`;

              return (
                <div
                  key={button}
                  className={dayClass}
                  onClick={() => selectButton(button)}
                  style={{
                    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.24)',
                  }}
                >
                  <span className={daySelectorStyles.dayText}>{button}</span>
                </div>
              );
            })}
          </div> */}
        </div>

        <div style={{padding:'5px'}}>
              {
                (selectedStatisticsButton === 'Person') && <PersonStatistics />
              }
              {
                selectedStatisticsButton === 'Avdelning' && <DepartmentInfo />
              }
              {
                selectedStatisticsButton === 'Förskola' && <SchoolStatistics meetingAndAHAndWHMetaInfo={meetingAndAHAndWHMetaInfo} setMeetingAndAHAndWHMetaInfo={setMeetingAndAHAndWHMetaInfo} />
              }
        </div>
      </div>
    </div>
  )
}

export default Statistics