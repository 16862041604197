import React, { useState, useRef, useEffect } from 'react';
import { useAppContext } from '../contexts/AppContext';
import styles from '../styles/StaffChart.module.css'
import WeekPicker from '../week/WeekPicker';
import { WeekProvider } from '../contexts/WeekContext';
import { odv, log, weekDays, calculateDayTotalHours, calculateDayTotalHoursActual, timeToFloat, floatToTime, getRandomGoodLookingColor } from '../utils/Utils';
import { getDayAdditionalHourLength, getEffectiveWorkHour } from '../utils/DataCalculator';
import RoundedDiv from '../assets/assets/rounded div/RoundedDiv';
import { timestampExtension } from '@msgpack/msgpack/dist/timestamp';
import roundedDivStyles from '../assets/assets/rounded div/RoundedDiv.module.css'

const DepartmentInfo = () => {
  const { state, dispatch } = useAppContext();
  const { people, weeks, days, departments, selectedPerson, selectedWeekId, selectedWeekNr, workHours, meetings, additionalHours,
          selectedDepartment, selectedDay, breakHours
   } = state
  const relevantDays = odv(days).filter(day => day.weekId === selectedWeekId)
  const [hoveredPersonId, setHoveredPersonId] = useState(null);


  let dayWorkSum = 0

  let allRelevantDays = []
  let allRelevantAH = []
  let relevantAH = []

  relevantDays.forEach(day => {
    dayWorkSum += calculateDayTotalHours(state, day.id)
    relevantAH.push(...odv(additionalHours).filter(aH => aH.dayId === day.id))
  })

  const relevantWeeks = odv(weeks).filter(week => week.personId === selectedPerson) // Take all the weeks that belong to this person

  relevantWeeks.forEach(week => {
    allRelevantDays.push(...odv(days).filter(day => day.weekId === week.id)) // Add all the days in these weeks

  })

  const dayIds = odv(allRelevantDays).map(day => day.id)

  odv(additionalHours).forEach(aH => {
    // if (Object.keys(allRelevantDays).map(Number).includes(aH.dayId)) {
    if (dayIds.includes(aH.dayId)) {
      allRelevantAH.push(aH)
    }
  })

  let totalCalendarWorkSum = 0
  let totalCalendarAHSum = 0
  let weekAHSum = 0

  relevantAH.forEach(aH => {
    weekAHSum += timeToFloat(aH.length)
  })

  allRelevantDays.forEach(day => {
    totalCalendarWorkSum += calculateDayTotalHours(state, day.id)
  })

  allRelevantAH.forEach(aH => {
    totalCalendarAHSum += timeToFloat(aH.length)
  })

  const allRelevantMeetings = Object.values(meetings).filter(meeting => 
      meeting.peopleIds.includes(selectedPerson)
    )
    
  let weekMeetingSum = 0
  let calendarMeetingSum = 0

  allRelevantMeetings.forEach(meeting => {
    if (meeting.weekNr === selectedWeekNr) {
      weekMeetingSum += timeToFloat(meeting.length)
      calendarMeetingSum += timeToFloat(meeting.length)
    } else {
      calendarMeetingSum += timeToFloat(meeting.length)
    }
  })

  const handleMouseEnter = (personId) => {
    setHoveredPersonId(personId);
  };

  const handleMouseLeave = () => {
    setHoveredPersonId(null);
  };

  const handleDayChange = (day) => {
    dispatch({
      type: 'SELECT_DAY',
      payload: { day: day},
      meta: { localOnly: true }
    });
    // setSelectedDay(day);
  };

  const selectDepartment = (departmentId) => {
    dispatch({
      type: 'SELECT_DEPARTMENT',
      payload: { departmentId: departmentId},
      meta: { localOnly: true }
    });
  };

  const selectPerson = (personId) => {
    dispatch({
      type: 'SELECT_PERSON',
      payload: { personId: personId},
      meta: { localOnly: true }
    });
  }

  const width = 700
  const timeStart = 6
  const timeEnd = 18
  const timeLength = timeEnd - timeStart
  const pixelPerHour = width / timeLength

  // const day = odv(days).find(day => day.weekId === selectedWeekId && day.weekDay === selectedDay)
  
  const PersonBar = ({start, end, name}) => { // Start, end is for instance 10.5 and 15.45
    
    let offset = (start - timeStart) * pixelPerHour
    const width = (end - start) * pixelPerHour

    if (offset < 0) {
      offset = 0
    }

    const divStyle = {
      backgroundColor: '#e4e4e4',
      height:  '30px',
      width: `${width}px`,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderRadius: '6px',
      marginLeft: `${offset}px`,
      marginTop: '6px',
      position:'absolute',
      color:'black',
      // backgroundImage: 'linear-gradient(to right top, #d7a1da, #d5aedf, #d4bbe2, #d5c7e3, #d8d2e3, #dfd5e4, #e6d9e6, #ecdde7, #fcd9df, #ffd8cd, #ffddb8, #eee6ac)'
      // backgroundImage: 'linear-gradient(to right, #ebd1ec, #f6cce0, #fdc9d2, #fec9c2, #f9cab3, #f7cdad, #f4d1a8, #eed5a5, #f1dba6, #f3e1a6, #f5e7a8, #f6eda9)'
      backgroundImage: 'linear-gradient(to right, #ebd1ec, #f6cce0, #fdc9d2, #fec9c2, #f9cab3, #f7cdae, #f3d0a9, #edd3a6, #f0d7a7, #f2daa7, #f4dea8, #f6e2a9)'
      // backgroundColor: getRandomGoodLookingColor(seed),
    };
  
    return <div style={divStyle} >
      <span style={{marginLeft:'4px'}}>{floatToTime(start)}</span>
      <span style={{fontWeight:'500'}}>{name}</span>
      <span style={{marginRight:'4px'}}>{floatToTime(end)}</span>
      {/* {name} */}
      </div>;
  };

  const BreakBar = ({start, end, name}) => { // Start, end is for instance 10.5 and 15.45
    const [isOpen, setIsOpen] = useState(false);
    const showMenu = () => setIsOpen(true);
    const hideMenu = () => setIsOpen(false);


    let offset = (start - timeStart) * pixelPerHour
    const width = (end - start) * pixelPerHour

    if (offset < 0) {
      offset = 0
    }
    const divStyle = {
      backgroundColor: '#EECFE8',
      height:  '30px',
      width: `${width}px`,
      // display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '6px',
      marginLeft: `${offset}px`,
      marginTop: '3px',
      position:'absolute',
      color:'black',
      zIndex:'100',
      // backgroundImage: 'linear-gradient(to right top, #d7a1da, #d5aedf, #d4bbe2, #d5c7e3, #d8d2e3, #dfd5e4, #e6d9e6, #ecdde7, #fcd9df, #ffd8cd, #ffddb8, #eee6ac)'
      // backgroundImage: 'linear-gradient(to right, #ebd1ec, #f6cce0, #fdc9d2, #fec9c2, #f9cab3, #f7cdad, #f4d1a8, #eed5a5, #f1dba6, #f3e1a6, #f5e7a8, #f6eda9)'
      // backgroundImage: 'linear-gradient(to right, #ebd1ec, #f6cce0, #fdc9d2, #fec9c2, #f9cab3, #f7cdae, #f3d0a9, #edd3a6, #f0d7a7, #f2daa7, #f4dea8, #f6e2a9)'
      // backgroundColor: getRandomGoodLookingColor(seed),
    };
  
    return <div style={divStyle} onMouseEnter={showMenu} onMouseLeave={hideMenu}>
      {/* <span style={{marginLeft:'4px'}}>{floatToTime(start)}</span> */}

        <div style={{height:'28px', display:'flex', alignItems:'center'}}>
          <span onMouseEnter={showMenu} style={{fontSize:'13px', fontWeight:'500'}}>Rast</span>
        </div>
        {/* <span style={{marginRight:'4px'}}>{floatToTime(end)}</span> */}
        {/* {name} */}
        <div>
        {
          isOpen && <div onMouseEnter={showMenu} onMouseLeave={hideMenu}
              style={{backgroundColor: '#EECFE8', width:'100px', height:'50px', borderRadius:'6px', padding:'5px', zIndex:'1000'}}>
            <p>{name}</p>
            <p>{floatToTime(start)} - {floatToTime(end)}</p>
            </div>
        }
        </div>
        
      </div>

  };

  const MeetingBar = ({start, end, name}) => { // Start, end is for instance 10.5 and 15.45
    const [isOpen, setIsOpen] = useState(false);
    const showMenu = () => setIsOpen(true);
    const hideMenu = () => setIsOpen(false);

    let offset = (start - timeStart) * pixelPerHour
    const width = (end - start) * pixelPerHour

    if (offset < 0) {
      offset = 0
    }
    const divStyle = {
      backgroundColor: '#F8CBB0',
      height:  '30px',
      width: `${width}px`,
      // display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '6px',
      marginLeft: `${offset}px`,
      marginTop: '3px',
      position:'absolute',
      color:'black',
      zIndex:'100',
      // backgroundImage: 'linear-gradient(to right top, #d7a1da, #d5aedf, #d4bbe2, #d5c7e3, #d8d2e3, #dfd5e4, #e6d9e6, #ecdde7, #fcd9df, #ffd8cd, #ffddb8, #eee6ac)'
      // backgroundImage: 'linear-gradient(to right, #ebd1ec, #f6cce0, #fdc9d2, #fec9c2, #f9cab3, #f7cdad, #f4d1a8, #eed5a5, #f1dba6, #f3e1a6, #f5e7a8, #f6eda9)'
      // backgroundImage: 'linear-gradient(to right, #ebd1ec, #f6cce0, #fdc9d2, #fec9c2, #f9cab3, #f7cdae, #f3d0a9, #edd3a6, #f0d7a7, #f2daa7, #f4dea8, #f6e2a9)'
      // backgroundColor: getRandomGoodLookingColor(seed),
    };
  
    return <div style={divStyle} onMouseEnter={showMenu} onMouseLeave={hideMenu}>
      {/* <span style={{marginLeft:'4px'}}>{floatToTime(start)}</span> */}
      <div style={{height:'28px', display:'flex', alignItems:'center', justifyContent:'center'}}>
          <span onMouseEnter={showMenu} style={{fontSize:'13px', fontWeight:'500'}}>Möte</span>
      </div>
      {/* <span style={{marginRight:'4px'}}>{floatToTime(end)}</span> */}
      {/* {name} */}
      <div style={{zIndex:'200'}}>
        {
          isOpen && <div onMouseEnter={showMenu} onMouseLeave={hideMenu}
              style={{backgroundColor: '#F8CBB0', width:'100px', height:'50px', borderRadius:'6px', padding:'5px', zIndex:'1000'}}>
            <p>{name}</p>
            <p>{floatToTime(start)} - {floatToTime(end)}</p>
            </div>
        }
      </div>
      </div>;
  };

  const AHBar = ({start, end, category, name}) => { // Start, end is for instance 10.5 and 15.45
    const [isOpen, setIsOpen] = useState(false);
    const showMenu = () => setIsOpen(true);
    const hideMenu = () => setIsOpen(false);

    let offset = (start - timeStart) * pixelPerHour
    const width = (end - start) * pixelPerHour

    if (offset < 0) {
      offset = 0
    }
    const divStyle = {
      backgroundColor: '#F3DDA7',
      height:  '30px',
      width: `${width}px`,
      // display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '6px',
      marginLeft: `${offset}px`,
      marginTop: '3px',
      position:'absolute',
      color:'black',
      // backgroundImage: 'linear-gradient(to right top, #d7a1da, #d5aedf, #d4bbe2, #d5c7e3, #d8d2e3, #dfd5e4, #e6d9e6, #ecdde7, #fcd9df, #ffd8cd, #ffddb8, #eee6ac)'
      // backgroundImage: 'linear-gradient(to right, #ebd1ec, #f6cce0, #fdc9d2, #fec9c2, #f9cab3, #f7cdad, #f4d1a8, #eed5a5, #f1dba6, #f3e1a6, #f5e7a8, #f6eda9)'
      // backgroundImage: 'linear-gradient(to right, #ebd1ec, #f6cce0, #fdc9d2, #fec9c2, #f9cab3, #f7cdae, #f3d0a9, #edd3a6, #f0d7a7, #f2daa7, #f4dea8, #f6e2a9)'
      // backgroundColor: getRandomGoodLookingColor(seed),
    };
  
    return <div style={divStyle} onMouseEnter={showMenu} onMouseLeave={hideMenu}>
      {/* <span style={{marginLeft:'4px'}}>{floatToTime(start)}</span> */}
      <div style={{height:'28px', display:'flex', alignItems:'center', justifyContent:'center'}}>
          <span onMouseEnter={showMenu} style={{fontSize:'13px', fontWeight:'500'}}>{category}</span>
      </div>
      <div style={{zIndex:'200'}}>
        {
          isOpen && <div onMouseEnter={showMenu} onMouseLeave={hideMenu}
              style={{backgroundColor: '#F3DDA7', width:'100px', height:'50px', borderRadius:'6px', padding:'5px', zIndex:'1000'}}>
            <p>{name}</p>
            <p>{floatToTime(start)} - {floatToTime(end)}</p>
            </div>
        }
      </div>
      {/* <span style={{marginRight:'4px'}}>{floatToTime(end)}</span> */}
      {/* {name} */}
      </div>;
  };
  
  return (
    <div style={{ maxWidth:'1250px'}}>
      <div style={{display:'flex'}}>
        <div style={{marginLeft:'10px', marginRight:'10px', paddingRight:'30px', borderRight:'2px solid #c1c1c1'}}>
          <p style={{fontWeight:'400', fontSize:'30px'}}>{departments[selectedDepartment].name}</p>
          <div style={{marginTop:'10px'}}>
            {
              odv(departments).map(dept => {
                const selected = selectedDepartment === dept.id
                const color = selected ? '#fff' : ''
                const isHovered = hoveredPersonId === dept.id
                return (
                  <div 
                    onMouseEnter={() => handleMouseEnter(dept.id)}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => selectDepartment(dept.id)} 
                    style={{marginBottom:'10px', cursor: 'pointer'}}
                    >
                    <RoundedDiv padding='10px' layer={'three'} backgroundColor={selected ? (isHovered ? '#1c1c1c' : '#414141') : (isHovered ? '#f2f2f2' : '#fff')} style={{marginBottom:'0px', padding:'10px'}}>
                      <div style={{display:'flex'}}> {/* */}
                        <div style={{display: 'flex', alignItems: 'center', textAlign:'center', width:'200px', height:'50px', overflow:'auto'}}>
                          <p style={{color:color, fontSize:'19px', fontWeight:'500', margin:'0', padding:'0'}}>{dept.name}</p>
                        </div>
                        
                      </div>
                    </RoundedDiv>
                  </div>
                  )
                }
              )
            }
          </div>
        </div>
        <div style={{marginLeft:'20px',}}>
          {/* <p ><span style={{color:'rgb(53, 109, 255)', fontSize:'30px', fontWeight:'500'}}>{people[selectedPerson]?.name}</span>
            <span style={{fontSize:'30px', fontWeight:'500'}}>&nbsp;&nbsp;v.{state.selectedWeekNr}</span>
          </p> */}
          <WeekProvider>
            <div style={{height:'40px', display:'flex', justifyContent:'center'}}>
                <WeekPicker showAddRemove={false}></WeekPicker>
            </div>
          </WeekProvider>
          <div style={{display:'flex', justifyContent:'center', marginBottom:'15px'}}>
            {['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag'].map(day => (
              <button
                className={styles.styledButton}
                key={day}
                onClick={() => handleDayChange(day)}
                style={{ backgroundColor: selectedDay === day ? '#30c01a' : 'rgb(53, 109, 255)', color: 'fff'}}
              >
                {day}
              </button>
            ))}
          </div>
          
          <div style={{width:'800px'}}>
            <div style={{width:`${width}px`, borderBottom:'2px solid black', display:'flex', justifyContent:'space-between'}}>
              <span>6</span>
              <span>8</span>
              <span>10</span>
              <span>12</span>
              <span>14</span>
              <span>16</span>
              <span>18</span>
            </div>
            {
              odv(people).filter(person => person.departmentId === selectedDepartment).map(person => {
                const day = odv(days).find(day => weeks[day.weekId].personId === person.id && day.weekDay === selectedDay)
                const workHour = odv(workHours).find(wH => weeks[days[wH.dayId].weekId]?.personId === person.id && wH.type === 'start' && days[wH.dayId].weekDay === day.weekDay && weeks[days[wH.dayId].weekId].weekNr === state.selectedWeekNr)
                const workHourStart = workHour?.time 
                const workHourEnd = odv(workHours).find(wH => weeks[days[wH.dayId].weekId]?.personId === person.id && wH.type === 'end' && days[wH.dayId].weekDay === day.weekDay && weeks[days[wH.dayId].weekId].weekNr === state.selectedWeekNr)?.time
                
                const breakHourLength = odv(breakHours).find(bH => bH.dayId === day.id && bH.type === 'length')?.time || "00:00"

                const relevantMeetings = Object.values(meetings).filter(meeting => 
                  meeting.weekNr === weeks[day.weekId].weekNr &&
                  meeting.weekDay === day.weekDay
                   && meeting.peopleIds.includes(person.id))                

                if (workHourStart && workHourEnd) {
                  return (
                    <div style={{display:'flex'}}>
                      <div style={{width:'700px', height:'36px'}}>
                        <div style={{width:'700px', position:'absolute'}}>
                          <PersonBar start={timeToFloat(workHourStart)} end={timeToFloat(workHourEnd)} name={person.name}></PersonBar>
                          {/* {
                            relevantMeetings.map(meeting => (
                              <MeetingBar start={timeToFloat(meeting.time)} end={timeToFloat(meeting.time) + timeToFloat(meeting.length)}></MeetingBar>
                            ))
                          } */}
                        </div>
                      </div>
                      <div style={{display:'flex', alignItems:'center', marginLeft:'10px'}}>
                        <p>= {calculateDayTotalHoursActual(state, day.id)}h</p>
                      </div>
                    </div>
                  )
                }
              })
            }
            <div style={{width:`${width}px`, marginTop:'15px', marginBottom:'10px', display:'flex', justifyContent:'space-between'}}>
              <span>6</span>
              <span>8</span>
              <span>10</span>
              <span>12</span>
              <span>14</span>
              <span>16</span>
              <span>18</span>
            </div>
            {
              odv(people).filter(person => person.departmentId === selectedDepartment).map(person => {
                const day = odv(days).find(day => weeks[day.weekId].personId === person.id && day.weekDay === selectedDay)

                const workHour = odv(workHours).find(wH => weeks[days[wH.dayId].weekId]?.personId === person.id && wH.type === 'start' && days[wH.dayId].weekDay === day.weekDay && weeks[days[wH.dayId].weekId].weekNr === state.selectedWeekNr)
                const workHourStart = workHour?.time 
                const workHourEnd = odv(workHours).find(wH => weeks[days[wH.dayId].weekId]?.personId === person.id && wH.type === 'end' && days[wH.dayId].weekDay === day.weekDay && weeks[days[wH.dayId].weekId].weekNr === state.selectedWeekNr)?.time
                
                const breakHourStart = odv(breakHours).find(bH => bH.dayId === day.id && bH.type === 'start')?.time || "00:00"
                const breakHourLength = odv(breakHours).find(bH => bH.dayId === day.id && bH.type === 'length')?.time || "00:00"

                const relevantMeetings = Object.values(meetings).filter(meeting => 
                  meeting.weekNr === weeks[day.weekId].weekNr &&
                  meeting.weekDay === day.weekDay
                   && meeting.peopleIds.includes(person.id))

                const relevantAdditionalHours = Object.values(additionalHours).filter(aH => aH.dayId === day.id)


                if (workHourStart && workHourEnd) {
                  return (
                    <div style={{display:'flex', height:'36px'}}>
                      <div style={{width:'700px'}}>
                        <div style={{width:'700px',  position:'absolute'}}>
                          {
                            (timeToFloat(breakHourLength) !== 0) && <BreakBar name={person.name} start={timeToFloat(breakHourStart)} end={timeToFloat(breakHourStart) + timeToFloat(breakHourLength)}></BreakBar>
                          }
                          {
                            relevantMeetings.map(meeting => (
                              <MeetingBar start={timeToFloat(meeting.time)} end={timeToFloat(meeting.time) + timeToFloat(meeting.length)} name={person.name}></MeetingBar>
                            ))
                          }
                          {
                            relevantAdditionalHours.map(aH => (
                              <AHBar start={timeToFloat(aH.start)} end={timeToFloat(aH.start) + timeToFloat(aH.length)} category={aH.category} name={person.name}></AHBar>
                            ))
                          }
                        </div>
                      </div>
                      <div style={{display:'flex', alignItems:'center', marginLeft:'10px'}}>
                        <p>{person.name}</p>
                      </div>
                    </div>
                  )
                }
              })
            }
            <div style={{width:`${width}px`, marginTop:'6px', borderTop:'2px solid black', display:'flex', justifyContent:'space-between'}}>
              <span>6</span>
              <span>8</span>
              <span>10</span>
              <span>12</span>
              <span>14</span>
              <span>16</span>
              <span>18</span>
            </div>
            <p style={{marginTop:'40px'}}>OBS: endast den valda avdelningens tider visas</p>
            

          </div>
          </div>
          
        </div>

    </div>
  )
}

export default DepartmentInfo