import React, { useState, useRef, useEffect } from 'react';
import { useAppContext } from '../contexts/AppContext';
import inputStyles from '../styles/StyledInput.module.css';
import buttonStyles from '../styles/StyledButton.module.css';


const RemoveDepartmentForm = ({id: meetingId}) => {
  const { state, dispatch } = useAppContext();


  return (
    <div>
      <p style={{fontSize:'24px'}}>Ta bort {state.departments[state.selectedDepartment].name}?</p>
      <br></br>
      <p style={{maxWidth:'500px'}}>Detta tar bort alla avdelningens tillhörande personal och alla deras timmar etc.</p>
      <p style={{marginTop:'10px', marginBottom:'10px'}}>Detta går inte att ångra.</p>
      <div style={{display:'flex', justifyContent:'right'}}>

        <button className={buttonStyles['secondaryButton']} style={{marginLeft:'0px'}}onClick={() => dispatch({
                                  type: 'TOGGLE_SHOW_REMOVE_DEPARTMENT', payload: {
                                  setToShow: false,
                                  }
                                })}>
          Avbryt
        </button>
        <button 
          className={buttonStyles['button-30']}
          onClick={() => {dispatch({ type: 'REMOVE_DEPARTMENT', payload: {
                                  departmentId: state.selectedDepartment,
                                } });
                                dispatch({
                                  type: 'TOGGLE_SHOW_REMOVE_DEPARTMENT', payload: {
                                  setToShow: false,
                                  }
                                });
                                }}>
          Ta bort
        </button>
      </div>
      
    </div>
  )
}

export default RemoveDepartmentForm